import React, { useState } from 'react';

const DropzoneComponent = ({myFile=null,setMyFile=()=>{},handleSubmitReq=()=>{}}) => {
  const [fileInfo, setFileInfo] = useState('Subir archivo');
  const [isDragOver, setIsDragOver] = useState(false);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        setMyFile(file)
      setFileInfo(`${file.name}, ${file.size} bytes`);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
        setMyFile(file)

      setFileInfo(`${file.name}, ${file.size} bytes`);
    }
  };

  const handleReset = () => {
    setMyFile(null)
    setFileInfo('Subir archivo');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    console.log(myFile);
    handleSubmitReq(myFile)
  };

  return (
    <form className="dropzone-box" onSubmit={handleSubmit} onReset={handleReset} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} >
      <h2>Subir archivo</h2>
      <p>¡Haga clic o arrastre un archivo csv para cargarlo!</p>
      <div className={`dropzone-area ${isDragOver ? 'dropzone--over' : ''}`} >
        <div className="file-upload-icon">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-cloud-upload" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
            <path d="M9 15l3 -3l3 3" />
            <path d="M12 12l0 9" />
          </svg>
        </div>
        <input type="file" required={myFile===null} id="upload-file" name="uploaded-file" onChange={handleFileChange} accept=".csv" />
        <p className="file-info">{fileInfo}</p>
      </div>
      <div className="dropzone-actions">
{/*         <button type="reset">Cancelar</button>
 */}        <button type="submit">Subir</button>
      </div>
    </form>
  );
};

export default DropzoneComponent;