import React, { useState, useContext } from "react";
import { FunctionContext } from "../managers/FuntionContext";
import {
    Badge,
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Input,
    Table,
} from "reactstrap";
import { ModalIdentidad, ModalIdentidadLoading } from "./ModalIdentidad.js";
import ConsultaHuella_digital from "../consultas/huella_digital";
import {
    QuerySarlaftAsync,
    GetTypeQuery,
    GetLogsQuery,
    GetFilterQuery,
    DownloadPDF,
    QueryFraude,
    getIP,
    QueryIP,
    QueryBIN,
    QueryScore,
    QueryImage,
    QueryHuellaDigital2,
} from "../managers/ApiManager";
import ConsultaBin from "../consultas/bin";

import {
    AlertSwal,
    ResponsePDF,
    FormatDocument,
    TitleCase,
    FormatPhone,
} from "../managers/HelperManager";

import { useGlobal } from "../managers/store/Context";

import moment from "moment";
import { data } from "jquery";
import ReactFlagsSelect from "react-flags-select";
import swal from "sweetalert";
import { useHistory, Link, Route, Switch, Redirect } from "react-router-dom";
import ModalFraud from "./ModalFraud";
import ModalFraudAll from "./ModalFraudAll";


export default function Fraud(props) {
    const [getLogsQuery, setLogsQuery] = useState([]);
    const [getBtnText, setBtnText] = useState("Consultar");
    const [, dispatch] = useGlobal();
    const [idTypeQuery, setIdTypeQuery] = useState(props.idTypeQuery);
    var history = useHistory();
    const { funGetBalance } = useContext(FunctionContext);

    const [identidadItem, setIdentidadItem] = useState(null);
    const [dataPhone, setDataPhone] = useState(null);

    const [getModalIdentidadLoading, setModalIdentidadLoading] = useState(false);
    const [getModalIdentidad, setModalIdentidad] = useState(false);
    const [getModalIdentidadAll, setModalIdentidadAll] = useState(false);

    const Consultaidentidad = async (e) => {
        try {
            e.preventDefault();
            setModalIdentidadLoading(true);
            setBtnText("Consultando...");
            let { email, phone, ip } = e.target;


            const response = await QueryFraude(
                email.value,
                ip.value,
                phone.value,
            );

            if (email.value !== "" && phone.value === "" && ip.value == "") {
                setIdentidadItem(response.data.response_log)
                setModalIdentidad(true)
                setModalIdentidadLoading(false);
                setBtnText("Consultar");

            } else if (email.value == "" && phone.value !== "" && ip.value == "") {
                setIdentidadItem(response.data.response_log)
                setModalIdentidad(true)
                setModalIdentidadLoading(false);
                setBtnText("Consultar");
            } else if (email.value == "" && phone.value == "" && ip.value !== "") {
                setIdentidadItem(response.data.response_log)
                setModalIdentidad(true)
                setModalIdentidadLoading(false);
                setBtnText("Consultar");

            } else {
                // Tienes más de una variable, envía todas a destino y
                setIdentidadItem(response.data.response_log)
                setModalIdentidadAll(true)
                setModalIdentidadLoading(false);
                setBtnText("Consultar");

            }
            email.value = "";
            phone.value = "";
            ip.value = "";
            props.funLogsQuery()
        } catch (error) {
            setModalIdentidadLoading(false);
            //AlertSwal('ERROR_SERVER')
            setBtnText("Consultar");
        }
    };

    const tipoConsulta = async (x) => {
        if (x == 1) {
            history.push("/consultar/la_ft");
        } else if (x == "3") {
            history.push("/consultar/huella_digital");
        } else if (x == "4") {
            history.push("/consultar/identidad");
        } else if (x == "5") {
            history.push("/consultar/validacion_IP");
        } else if (x == "6") {
            history.push("/consultar/validacion_BIN");
        } else if (x == "7") {
            history.push("/consultar/score_de_riesgo");
        } else if (x == "8") {
            history.push("/consultar/imagen_perfil");
        } else if (x == "9") {
            history.push("/consultar/informacion_laboral");
        } else if (x == "10") {
            history.push("/consultar/fraude");
        }
    };
    const CloseModal = () => {
        setIdentidadItem(null)
        setModalIdentidadAll(false)
        setModalIdentidad(false)
    }

    return (
        <div className="content-jumbotron  basket animated fadeIn mt-2 mb-2">
            <Form onSubmit={(e) => Consultaidentidad(e)}>
                <Row
                    form
                    style={{
                        alignitems: "center",
                    }}
                >
                    <Col md={2}>
                        <FormGroup>
                            <Input
                                type="select"
                                name="type_query_id"
                                defaultValue={`${idTypeQuery}`}
                                onChange={(e) => tipoConsulta(e.target.value)}
                                required
                            >
                                <option hidden disabled={true} value="0">
                                    Tipo de consulta
                                </option>
                                <option value="1"> LA / FT </option>
                                {/*    <option value="3"> Huella digital </option> */}
                                <option value="4"> Identidad </option>
                                <option value="5"> Validación IP </option>
                                <option value="6"> Validación BIN </option>
                                <option value="7"> Score de riesgo </option>
                                <option value="8"> Imagen perfil </option>
                                <option value="9"> Informacion laboral </option>
                                <option value="10"> Fraude </option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="email" name="email" placeholder="Correo" />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Input
                                type="phone"
                                id="phone"
                                name="phone"
                                placeholder="Celular"

                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Input
                                type="ip"
                                id="ip"
                                name="ip"
                                placeholder="IP"

                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={2}
                        style={{
                            marginleft: 10,
                        }}
                    >
                        <FormGroup>
                            <Button
                                className="btn-green"
                                disabled={getBtnText === "Consultando..."}
                                block
                            >
                                {getBtnText}
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>

            {getModalIdentidad === true && (
                <div>
                    <ModalFraud
                        dataPhone={dataPhone}
                        item={identidadItem}
                        modal={getModalIdentidad}
                        setModal={CloseModal}
                    />
                </div>
            )}
            {getModalIdentidadAll !== false && (
                <div>
                    <ModalFraudAll
                        dataPhone={dataPhone}
                        item={identidadItem}
                        modal={getModalIdentidadAll}
                        setModal={CloseModal}
                        hideModal={() => setModalIdentidad(false)}
                    />
                </div>
            )}


        </div>
    );
}
