import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { GetFilterQuery } from "../managers/ApiManager";
import moment, { relativeTimeRounding } from 'moment';
import { } from "../managers/HelperManager";


const styles = StyleSheet.create({
    page: {
        padding: "25px"

    },
    section: {
        backgroundColor: "#d5d5d7",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        margin: "10px 20px 10px 20px",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",



    },
    header: {
        paddingLeft: "15px",
        display: "flex",
        width: "100%",
        marginBottom: "10px"
    },
    data: {
        paddingLeft: "15px",
        flexDirection: 'row',
        width: "100%"
    },
    textTitule: {
        fontSize: "12px",
        fontWeight: "medium"
    },
    BodyView: {
        margin: "0px 20px 00px 20px",
        paddingLeft: "15px",


    },
    linia: {
        borderBottom: "2px solid gray"
    },
    liniaDivisoria: {
        borderBottom: "1px solid gray",
        marginBottom: "15px",

    },
    tituloPrincipal: {
        marginTop: "5px",
        fontSize: "18px",

    },
    tituloSecundario: {
        fontSize: "16px",
        marginBottom: "5px",
        marginTop: "10px",
        color: "black"


    },
    titulo1: {
        color: "#777",
        fontSize: "11px",

        marginTop: "5px",


    },
    Text1: {
        fontSize: "14px",
        marginBottom: "5px",


    },
    CentrarText: {

        marginTop: "30%",
        marginLeft: "-25px",
        display: "flex",
        alignContent: "center",
        alignItems: "center"
    },
    link1: {
        color: "#007bff",
        textDecoration: "underline",
        textTransform: "lowercase",
        fontSize: "14px",
        marginBottom: "5px",
    }


});


const Score = (props) => {
    console.log("aqui esta pros:  ", props)
    const [data, setData] = useState(null);
    useEffect(() => {
        if (props.show) {
            render();
        }



    }, [props.show]);


    const render = async () => {
        let res = await GetFilterQuery(props.id)
        setData(res)
        console.log(res)

    }




    return props.show && data !== null ? (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.header}>
                        <Text >Score de riesgo</Text>

                    </View>
                    <View style={styles.data}>
                        <Text style={styles.textTitule}>ID: </Text>
                        <Text style={styles.textTitule}>{data.data[0].id} </Text>
                        <Text style={styles.textTitule}> | </Text>
                        <Text style={styles.textTitule}>{data.data[0].employee} </Text>

                        <Text style={styles.textTitule}> | </Text>
                        <Text style={styles.textTitule}>{moment(data.data[0].date_created).format(('L h:mm:ss a'))}</Text>
                    </View>

                </View>
                {data.data[0].response !== undefined &&
                    <View style={styles.BodyView}>
                        <Text style={styles.tituloPrincipal}>Datos</Text>

                        {data.data[0].response.email !== "" && <View>
                            <Text style={styles.titulo1}>Email</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.email)}</Text>
                        </View>
                        }
                        {data.data[0].response.reputation !== "" && <View>
                            <Text style={styles.titulo1}>Reputacion</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.reputation)}</Text>
                        </View>
                        }
                        {data.data[0].response.suspicious !== "" && <View>
                            <Text style={styles.titulo1}>Sosprechoso</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.suspicious ? "Si" : "No")}</Text>
                        </View>
                        }
                        {data.data[0].response.details?.blacklisted !== undefined && <View>
                            {data.data[0].response.details.type !== "" && <View>
                                <Text style={styles.titulo1}>Lista Negra</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.blacklisted ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.malicious_activity !== "" && <View>
                                <Text style={styles.titulo1}>Actividad Maliciosa</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.malicious_activity ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.malicious_activity_recent !== "" && <View>
                                <Text style={styles.titulo1}>Actividad Maliciosa Reciente</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.malicious_activity_recent ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.credentials_leaked !== "" && <View>
                                <Text style={styles.titulo1}>Credenciales Filtradas</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.credentials_leaked ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.credentials_leaked_recent !== "" && <View>
                                <Text style={styles.titulo1}>Credenciales Filtradas Reciente</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.credentials_leaked_recent ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.data_breach !== "" && <View>
                                <Text style={styles.titulo1}>Filtración De Datos</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.data_breach ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.first_seen !== "" && <View>
                                <Text style={styles.titulo1}>Visto Por Primera Vez</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.first_seen)}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.last_seen !== "" && <View>
                                <Text style={styles.titulo1}>Ultima Vez Visto</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.last_seen)}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.domain_exists !== "" && <View>
                                <Text style={styles.titulo1}>El Dominio Existe</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.domain_exists ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.domain_reputation !== "" && <View>
                                <Text style={styles.titulo1}>Reputación De Dominio</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.domain_reputation)}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.new_domain !== "" && <View>
                                <Text style={styles.titulo1}>Nuevo Dominio</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.new_domain ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.days_since_domain_creation !== "" && <View>
                                <Text style={styles.titulo1}>Días Desde La Creación Del Dominio</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.days_since_domain_creation)}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.suspicious_tld !== "" && <View>
                                <Text style={styles.titulo1}>Sospechoso Tld</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.suspicious_tld ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.spam !== "" && <View>
                                <Text style={styles.titulo1}>Spam</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.spam ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.free_provider !== "" && <View>
                                <Text style={styles.titulo1}>Provedor Gratuito</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.free_provider ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.deliverable !== "" && <View>
                                <Text style={styles.titulo1}>Entregable</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.deliverable ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.accept_all !== "" && <View>
                                <Text style={styles.titulo1}>Aceptar Todo</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.accept_all ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.valid_mx !== "" && <View>
                                <Text style={styles.titulo1}>Válido Mx</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.valid_mx ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.primary_mx !== "" && <View>
                                <Text style={styles.titulo1}>Mx Primaria</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.primary_mx)}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.spoofable !== "" && <View>
                                <Text style={styles.titulo1}>Falsificable</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.spoofable ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.spf_strict !== "" && <View>
                                <Text style={styles.titulo1}>SPF Estricto</Text>
                                <Text style={styles.Text1}>{(data.data[0].response.details?.spf_strict ? "Si" : "No")}</Text>
                            </View>
                            }
                            {data.data[0].response.details?.dmarc_enforced !== "" && <View>
                                <Text style={styles.titulo1}>Dmarc Aplicado</Text>
                                <Text style={styles.Text1}>{data.data[0].response.details?.dmarc_enforced ? "Si" : "No"}</Text>
                            </View>
                            }
                        </View>}



                    </View>}
                {data.data[0].response.length == 0 &&
                    <View style={styles.CentrarText}><Text>No se encontraron datos</Text>
                    </View>}
            </Page>
        </Document >
    ) : ("")
}

export { Score }


