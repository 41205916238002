import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Badge, Col, Modal, ModalHeader, ModalBody, Row, Input } from 'reactstrap';
import { FirstCapitalLetter, ResponsePDF } from '../managers/HelperManager';
import { useGlobal } from '../managers/store/Context';
import { DownloadPDF } from '../managers/ApiManager';

const ModalQuery = props => {

    const [getDataQuery, setDataQuery] = useState([]);
    const [getDataQueryCopy, setDataQueryCopy] = useState([]);
    const [getFilter, setFilter] = useState([]);
    const [getName, setName] = useState("");
    const [getDocumentNumber, setDocumentNumber] = useState("");
    const [getLastName, setLastName] = useState("");
    const [getCompany, setCompany] = useState("");
    const [getId, setId] = useState(0);
    const [getDate, setDate] = useState("");
    const [state] = useGlobal();



    useEffect(() => {
        setName(state.name);
        setLastName(state.last_name);
        setDocumentNumber(state.document_number);
        setCompany(state.company);
        setId(state.id);
        setDate(state.date);
        setDataQuery(state.dataQuery);
        return () => setDataQueryCopy([]);
    }, [state])

    useEffect(() => {
        funGetListFont();
    }, [])


    const funGetListFont = () => {
        let newFont = [];
        let found = false;
        getDataQuery.forEach((item, i) => {
            found = false;
            if (item.fuente !== "") {
                newFont.forEach((font, i) => {
                    if (font.name === item.fuente) {
                        font.count++;
                        found = true;
                    }
                });
                if (!found) {
                    newFont.push({
                        name: item.fuente,
                        count: 1
                    });
                }
            }
        });
        setFilter(newFont);
    }


    // return the badge color and paddingleft content 
    const funGetBadgeColorAndPaddingLeft = percent => {
        let badgeColor = "", paddingLeft = "0%"
        if (percent <= 0 && percent < 30) {
            paddingLeft = "3.4%"
        } else if (percent < 70 && percent > 30) {
            paddingLeft = "2.4%"
        }
        else if (percent > 70 && percent < 99) {
            paddingLeft = "2.4%"
        }
        else if (percent > 99) {
            paddingLeft = "1.4%"
        }

        // chnage color bagdecolor
        if (percent > 0) {
            badgeColor = "#ff5a52"
        } else {
            badgeColor = "#333646"
        }
        return {
            badgeColor,
            paddingLeft
        }
    }


    // download query button
    const funDownloadQuery = async (e, id) => {
        e.preventDefault()
        ResponsePDF(await DownloadPDF(id))
    }


    const funFilterFont = e => {
        let newDataQuery = [];
        getDataQuery.forEach((item, i) => {
            if (item.fuente !== "") {
                if (item.fuente === e.target.value) {
                    newDataQuery.push(item)
                }
            }
        });
        setDataQueryCopy(newDataQuery)
    }



    let dataQuery = getDataQueryCopy.length > 0 ? getDataQueryCopy : getDataQuery;
    return (
        <>
            {dataQuery &&
                <Modal isOpen={props.modal} backdrop={'static'}>
                    <ModalHeader toggle={props.hideModal} style={{ backgroundColor: "rgb(213,213,215)" }}>
                        {`${FirstCapitalLetter(getName)} ${FirstCapitalLetter(getLastName)}`}
                        <span className="ml-4" style={{ color: 'rgb(61,64,82)', fontSize: 15 }}>
                            {getDocumentNumber}
                        </span>
                        <br />
                        <span style={{ color: 'rgb(61,64,82)', fontSize: 13 }}>
                            ID:{getId}&nbsp;|&nbsp;{getCompany}&nbsp;|&nbsp;{moment(getDate).format('L h:mm:ss a')}
                            <Input type="select" name="type_document" onChange={e => funFilterFont(e)} style={{ width: '40%' }} placeholder="(12)">
                                <option value="">Filtrar por</option>
                                {getFilter.map((item, i) => <option key={i} value={item.name}>{item.name}   ({item.count}) </option>)}
                            </Input>
                        </span>
                        <a href="/download" onClick={e => funDownloadQuery(e, getId)}><i className="fas fa-file-download download-button"></i></a>
                    </ModalHeader>
                    <ModalBody className={dataQuery.length !== 0 ? "content-modalbody-query" : ""}>
                        {dataQuery.length > 0 ?
                            dataQuery.map((value, i) => {
                                return (
                                    <div key={i}>
                                        <Row className="content-row-modalquery">
                                            <Col md={5}>
                                                <Row>
                                                    <Col md={12}>
                                                        <p className="title-text-modal">{value.nombreencontrado && "Nombre encontrado"}</p>
                                                        <p className="text-content-modal"><b>{value.nombreencontrado}</b></p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p className="title-text-modal">{value.informacion && "Información"}</p>
                                                        <p className="text-content-modal"><b>{value.informacion}</b></p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p className="title-text-modal">{value.id_encontrado && "Identificación"}</p>
                                                        <p className="text-content-modal"><b>{value.id_encontrado}</b></p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={3}>
                                                <Row>
                                                    <Col md={12}>
                                                        <p className="title-text-modal">{value.hecho && "Hecho"}</p>
                                                        <p className="text-content-modal"><b>{value.hecho}</b></p>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className="title-text-modal">{value.fecha_noticia && "Fecha Noticia"}</p>
                                                        <p className="text-content-modal"><b>{value.fecha_noticia}</b></p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={3}>
                                                <Row>
                                                    <Col md={12}>
                                                        <p className="title-text-modal">{value.fuente && "Fuente"}</p>
                                                        <p className="text-content-modal"><b>{value.fuente}</b></p>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className="title-text-modal">{value.coincidencia && "Coincidencia"}</p>
                                                        <p className="text-content-modal"><b>{value.coincidencia}</b></p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={1} style={{ paddingLeft: funGetBadgeColorAndPaddingLeft(value.porcentaje).paddingLeft }} title="Coincidencia">
                                                <Badge className="badge-modal-query" style={{ backgroundColor: funGetBadgeColorAndPaddingLeft(value.porcentaje).badgeColor }} >{value.porcentaje}%</Badge>
                                            </Col>
                                        </Row>
                                        <hr className="hr-modal-query" />
                                    </div>
                                );
                            }) : <h4 className="text-center">No se encontraron coincidencias para esta búsqueda</h4>
                        }
                    </ModalBody>
                </Modal>
            }
        </>
    );
}


const ModalLoading = props => {
    return (
        <>
            <Modal isOpen={props.modal} backdrop={'static'} className="modal-loading"  >
                <img style={{ margin: '0 auto' }} src="/assets/loadingIcon.gif" alt="img loading" width="50" height="50" />
            </Modal>
        </>
    )
}

export {
    ModalLoading,
    ModalQuery
}
