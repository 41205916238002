import React, { useState, useEffect, useContext } from "react";
import { FunctionContext } from "../managers/FuntionContext";
import { Container } from "reactstrap";
import { GetBalance, GetProfile, GetLogsQuery } from "../managers/ApiManager";
import { ModalQuery, ModalLoading } from "../components/Modal";
import { ModalHuellaLoading, ModalHuella } from "../consultas/ModalHuella";
import {
  ModalIdentidad,
  ModalIdentidadLoading,
} from "../consultas/ModalIdentidad";
import { ModalClient, ModalClientLoading } from "../components/ModalClient";
import { ModalIP, ModalIPLoading } from "../consultas/ModalIP";
import QueryComponent from "../components/Query";
import SideBarComponent from "../components/SideBar";
import ReloadComponent from "../components/Reload";
import SummaryComponent from "../components/Summary";
import UsersComponent from "../components/Users";
import ClientComponent from "../components/Client";
import NotFound from "../components/notFound";
import NavComponent from "../components/Nav";
import { useGlobal } from "../managers/store/Context";
import { urlVerificator } from "../managers/HelperManager";
import swal from "sweetalert";
import { get } from "jquery";
import { ModalImage } from "../consultas/modalImage";
import { ModalHuellaDigital } from "../consultas/modalHuellaDigital2";
import { ModalScore, ModalScoreLoading } from "../consultas/modalScore";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ModalBIN, ModalBINLoading } from "../consultas/modalBIN";
import MassiveSms from "../components/MassiveSms";
let isSubscribed = true;

export default function Home(props) {
  const [getProfile, setProfile] = useState([]);
  const [getBalance, setBalance] = useState([]);
  const [getModalLoading, setModalLoading] = useState(false);

  const [getModalHuellaLoading, setModalHuellaLoading] = useState(false);
  const [getModalHuella, setModalHuella] = useState(false);
  const [HuellaItem, setHuellaItem] = useState([]);

  const [identidadItem, setIdentidadItem] = useState(null);
  const [getModalIdentidadLoading, setModalIdentidadLoading] = useState(false);
  const [getModalIdentidad, setModalIdentidad] = useState(false);

  const [getModalQuery, setModalQuery] = useState(false);
  const [getModalClientLoading, setModalClientLoading] = useState(false);
  const [getModalClient, setModalclient] = useState(false);
  const [clientItem, setClientItem] = useState({});
  const [clientDocuments, setclientDocuments] = useState([]);
  const [Clientlogs, setClienteLogs] = useState();
  const [state] = useGlobal();

  const [IPItem, setIPItem] = useState(null);
  const [getModalIPLoading, setModalIPLoading] = useState(false);
  const [getModalIP, setModalIP] = useState(false);

  const [BINItem, setBINItem] = useState(null);
  const [getModalBINLoading, setModalBINLoading] = useState(false);
  const [getModalBIN, setModalBIN] = useState(false);

  const [ScoreItem, setScoreItem] = useState(null);
  const [getModalScoreLoading, setModalScoreLoading] = useState(false);
  const [getModalScore, setModalScore] = useState(false);

  const [imageItem, setImageItem] = useState(null);
  const [getModalImage, setModalImage] = useState(false);

  const [Huella2Item, setHuella2Item] = useState(null);
  const [getModalhuella2, setModalhuella2] = useState(false);

  const [getLogsQuery, setLogsQuery] = useState([]);

  // initialize get data
  useEffect(() => {
    funGetProfile();

    return () => (isSubscribed = false);
  }, []);

  useEffect(() => {
    funVerifyUserStatus();
    urlVerificator();
    funLogsQuery();
  }, []);

  const funLogsQuery = async () => {
    let resLogsQuery = await GetLogsQuery();
    const newData = resLogsQuery.results;
    let vuelta = resLogsQuery.results.map((item, index) => {


      if (item.response_log === "" || item.response_log === "User not found") {
        var response = item.request_log === "" ? "" : "User not found"

      }
      else {


        var response = (item.response_log)

      }
      if (item.request_log === "" || item.request_log === "User not found") {

        var request = item.request_log === "" ? "" : "User not found"
      }
      else {

        var request = (item.request_log)
      }
      return { response: response, id: index, request: request }

    })

    var final = vuelta.map((item, index) => {
      const nuevoObjeto = {
        ...newData[index], // Copiar las propiedades existentes del objeto original
        response: item.response,
        request: item.request // Agregar la propiedad "response" con el valor de la respuesta
      };


      return nuevoObjeto
    })





    if (resLogsQuery) setLogsQuery(final);
  };
  // get list balance
  /*   const funGetBalance = async () => {
    let resBalance = await GetBalance();
  
    if (resBalance && isSubscribed) setBalance(resBalance);
  }; */

  // get list data user
  const funGetProfile = async () => {
    let resProfile = await GetProfile();

    if (resProfile && isSubscribed) setProfile(resProfile.data[0]);
  };
  //identidad modal
  const funTogglemodalIdentidadLoading = (modalIdentidadLoading) =>
    setModalIdentidadLoading(modalIdentidadLoading);
  const funTogglemodalIdentidad = (modalIdentidad) =>
    setModalIdentidad(modalIdentidad);
  const modalIdentidadItem = (modalIdentidadItem) =>
    setIdentidadItem(modalIdentidadItem);

  // control the modal loading
  const funToggleModalLoading = (modalLoading) => setModalLoading(modalLoading);

  // control the modal
  const funToggleModalQuery = (modalQuery) => setModalQuery(modalQuery);

  //huella modal
  const funTogglemodalHuellaLoading = (modalHuellaLoading) =>
    setModalHuellaLoading(modalHuellaLoading);
  const funTogglemodalHuella = (modalHuella) => setModalHuella(modalHuella);
  const modalHuellaItem = (modalHuellaItem) => setHuellaItem(modalHuellaItem);
  // client modal
  const modalClientLoading = (modalClientLoading) =>
    setModalClientLoading(modalClientLoading);
  const modalClientLogs = (modalClientLogs) => setClienteLogs(modalClientLogs);

  const modalClient = (modalClient) => setModalclient(modalClient);

  const modalClientItem = (modalClientItem) => setClientItem(modalClientItem);

  const modalClientDocuments = (modalClientDocuments) =>
    setclientDocuments(modalClientDocuments);

  // IP modal
  const modalIP = (modalIP) => setModalIP(modalIP);
  const itemIP = (itemIP) => setIPItem(itemIP);
  const loadingIP = (loadingIP) => setModalIPLoading(loadingIP);
  //BIN modal
  const modalBIN = (modalBIN) => setModalBIN(modalBIN);
  const itemBIN = (itemBIN) => setBINItem(itemBIN);
  const loadingBIN = (loadingBIN) => setModalBINLoading(loadingBIN);
  //score modal
  const modalScore = (modalScore) => setModalScore(modalScore);
  const itemScore = (itemScore) => setScoreItem(itemScore);
  const loadingScore = (loadingScore) => setModalScoreLoading(loadingScore);
  //image modal
  const modalimage = (modalimage) => setModalImage(modalimage);
  const itemimage = (itemimage) => setImageItem(itemimage);
  //modal huella digital 2
  const huella2 = (huella2) => setModalhuella2(huella2);
  const huella2Item = (huella2Item) => setHuella2Item(huella2Item);

  // verify user inactive
  const funVerifyUserStatus = () => {
    if (getProfile.status === 2) {
      swal({
        title: "Ups!",
        text: "El usuario se encuentra inactivo",
        icon: "error",
        button: "Ok",
      }).then(() => {
        props.logout();
      });
    }
  };

  return (
    <>
      <div className="d-flex" id="wrapper">
        {/* Sidebar */}
        <SideBarComponent {...props} resProfile={getProfile} />
        {/* Page Content */}
        <div id="page-content-wrapper" className="bg-dark-content">
          <Container>
            {getProfile != "" && (
              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => <Redirect to="/resumen" />}
                />
                {/* <Route exact path="/" component={() => <Resumen cobrus={cobrus} totalBalance={totalBalance} canViewDashboard={isCanViewDashboard} />} /> */}
                <Route
                  path="/resumen"
                  component={() => (
                    <SummaryComponent
                      funToggleModalLoading={funToggleModalLoading}
                      resProfile={getProfile}
                    />
                  )}
                />

                <Route
                  path="/consultar"
                  component={() => (
                    <QueryComponent
                      funToggleModalLoading={funToggleModalLoading}
                      funToggleModalQuery={funToggleModalQuery}
                      resProfile={getProfile}
                      funTogglemodalHuella={funTogglemodalHuella}
                      funTogglemodalHuellaLoading={funTogglemodalHuellaLoading}
                      modalHuellaItem={modalHuellaItem}
                      funTogglemodalIdentidadLoading={
                        funTogglemodalIdentidadLoading
                      }
                      funTogglemodalIdentidad={funTogglemodalIdentidad}
                      modalIdentidadItem={modalIdentidadItem}
                      modalIP={modalIP}
                      itemIP={itemIP}
                      loadingIP={loadingIP}
                      modalBIN={modalBIN}
                      itemBIN={itemBIN}
                      loadingBIN={loadingBIN}
                      loadingScore={loadingScore}
                      itemScore={itemScore}
                      modalScore={modalScore}
                      modalimage={modalimage}
                      itemimage={itemimage}
                      huella2={huella2}
                      huella2Item={huella2Item}
                      getLogsQuery={getLogsQuery}
                    />
                  )}
                />

                <Route
                  path="/clientes"
                  component={() => (
                    <ClientComponent
                      modalClientLoading={modalClientLoading}
                      modalClient={modalClient}
                      modalClientItem={modalClientItem}
                      modalClientDocuments={modalClientDocuments}
                      resProfile={getProfile}
                      modalClientLogs={modalClientLogs}
                    />
                  )}
                />

                  <Route
                  path="/send_massive_sms"
                  component={() => (

                    <MassiveSms
                      modalClientLoading={modalClientLoading}
                      modalClient={modalClient}
                      modalClientItem={modalClientItem}
                      modalClientDocuments={modalClientDocuments}
                      resProfile={getProfile}
                      modalClientLogs={modalClientLogs}
                    />
                  )}
                />
                {getProfile.permissions &&
                  getProfile.permissions[0].by_admin && (
                    <Route
                      path="/empleados"
                      component={() => (
                        <UsersComponent
                          funToggleModalLoading={funToggleModalLoading}
                          funToggleModalQuery={funToggleModalQuery}
                          resProfile={getProfile}
                        />
                      )}
                    />
                  )}
                <Route
                  path="/recargar"
                  component={() => (
                    <ReloadComponent
                      funToggleModalLoading={funToggleModalLoading}
                      resProfile={getProfile}
                    />
                  )}
                />
                <Route path="*" component={() => <NotFound />} />
              </Switch>
            )}
          </Container>
        </div>

        <ModalQuery
          modal={getModalQuery}
          hideModal={() => funToggleModalQuery(false)}
        />
        <ModalLoading modal={getModalLoading} />

        {getModalClient !== false && (
          <div>
            <ModalClient
              item={clientItem}
              modalClientLogs={Clientlogs}
              modalClientDocuments={modalClientDocuments}
              clientDocuments={clientDocuments}
              modal={getModalClient}
              hideModal={() => modalClient(false)}
            />
            <ModalClientLoading modal={getModalClientLoading} />
          </div>
        )}
        <ModalHuella
          item={HuellaItem}
          modal={getModalHuella}
          hideModal={() => funTogglemodalHuella(false)}
        />
        <ModalHuellaLoading modal={getModalHuellaLoading} />

        {getModalIdentidad !== false && (
          <div>
            <ModalIdentidad
              item={identidadItem}
              modal={getModalIdentidad}
              hideModal={() => funTogglemodalIdentidad(false)}
            />
            <ModalIdentidadLoading modal={getModalIdentidadLoading} />
          </div>
        )}
        {getModalIP !== false && (
          <div>
            <ModalIP
              item={IPItem}
              modal={getModalIP}
              hideModal={() => modalIP(false)}
            />
            <ModalIPLoading modal={getModalIPLoading} />
          </div>
        )}
        {getModalBIN !== false && (
          <div>
            <ModalBIN
              item={BINItem}
              modal={getModalBIN}
              hideModal={() => modalBIN(false)}
            />
            <ModalBINLoading modal={getModalBINLoading} />
          </div>
        )}
        {getModalScore !== false && (
          <div>
            <ModalScore
              item={ScoreItem}
              modal={getModalScore}
              hideModal={() => modalScore(false)}
            />
            <ModalScoreLoading modal={getModalScoreLoading} />
          </div>
        )}
        {getModalImage !== false && (
          <ModalImage
            item={imageItem}
            modal={getModalImage}
            hideModal={() => modalimage(false)}
          />
        )}
        {getModalhuella2 !== false && (
          <ModalHuellaDigital
            item={Huella2Item}
            modal={getModalhuella2}
            hideModal={() => huella2(false)}
          />
        )}
      </div>
    </>
  );
}
