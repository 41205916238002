import React, { useState, useContext } from "react";
import { FunctionContext } from "../managers/FuntionContext";
import { Col, Row, Button, Form, FormGroup, Input } from "reactstrap";

import { QueryHuellaDigital2 } from "../managers/ApiManager";

import { AlertSwal, validateEmail } from "../managers/HelperManager";
import {
  ModalHuellaDigital,
  ModalHuellaDigital2Loading,
} from "./modalHuellaDigital2";
import { useGlobal } from "../managers/store/Context";

import ReactFlagsSelect from "react-flags-select";
import swal from "sweetalert";
import { useHistory, Link, Route, Switch, Redirect } from "react-router-dom";
import { countries } from "../components/country";

export default function ConsultaInformacion(props) {
  const [getBtnText, setBtnText] = useState("Consultar");
  const [, dispatch] = useGlobal();
  const [idTypeQuery, setIdTypeQuery] = useState("0");
  var history = useHistory();

  const { funGetBalance } = useContext(FunctionContext);

  const [iCorreo, setCorreo] = useState("");

  const [selected, setSelected] = useState("CO");
  const [selectedHuella2, setSelectedHuella2] = useState("CO");
  const [pais, setCountry] = useState("Colombia");
  const [codigoCountry, setcodigoCountry] = useState("57");
  const searchable = ("Searchable", false);
  const [modalLoading, setModalLoading] = useState(false);
  const [Huella2Item, setHuella2Item] = useState(null);
  const [getModalhuella2, setModalhuella2] = useState(false);

  const customLabels =
    ("Custom Labels",
    {
      GB: "GB",
      US: "US",
    });

  const queryHuellaDigital2 = async (e) => {
    e.preventDefault();
    setModalLoading(true);
    setBtnText("Consultando...");
    let { full_name, phone, correo, type_query_id } = e.target;
    const DataName = await full_name.value.toLowerCase();
    const dataPhone = await phone.value.toLowerCase();
    const dataCorreo = await correo.value.toLowerCase();
    countries.map((item, i) => {
      if (selectedHuella2 === item.country_code) {
        setCountry(item.country_name);
        setcodigoCountry(`+${item.phone_code} `);
      }
    });

    verificarDatos2(DataName, dataCorreo, dataPhone);
  };
  const verificarDatos2 = (DataName, dataCorreo, dataPhone) => {
    if (dataCorreo != "") {
      if (validateEmail(dataCorreo) === true) {
        setCorreo(dataCorreo);
      }
      if (validateEmail(dataCorreo) === false) {
        AlertSwal("CORREO_INVALIDO");
        setBtnText("Consultar");
      }
    }

    if (
      DataName == "" &&
      dataPhone == "" &&
      dataCorreo == "" &&
      selected == ""
    ) {
      AlertSwal("EMPTY_FIELD");
      setBtnText("Consultar");
    } else if (DataName !== "" || dataPhone !== "" || dataCorreo !== "") {
      if (dataCorreo != "") {
        if (validateEmail(dataCorreo) === true) {
          setCorreo(dataCorreo);
          fetchpipl2(DataName, dataCorreo, dataPhone);
          setBtnText("Consultar");
        }
        if (validateEmail(dataCorreo) === false) {
          AlertSwal("CORREO_INVALIDO");
          setBtnText("Consultar");
        }
      } else {
        fetchpipl2(DataName, dataCorreo, dataPhone);
        setBtnText("Consultar");
      }
    }
  };
  const fetchpipl2 = async (DataName, dataCorreo, dataPhone) => {
    try {
      if (DataName != "") {
        var paramsKeyName = "name=";
        var paramsname = `${DataName}&`;
      } else {
        var paramsKeyName = "";
        var paramsname = "";
      }
      if (dataCorreo != "") {
        var paramsKeyCorreo = "email=";
        var paramsCorreo = `${dataCorreo}&`;
      } else {
        var paramsKeyCorreo = "";
        var paramsCorreo = "";
      }
      if (dataPhone != "") {
        var paramsKeyPhone = "phone=";
        var paramsPhone = `+${codigoCountry}${dataPhone}&`;
      } else {
        var paramsKeyPhone = "";
        var paramsPhone = "";
      }
      var paramsKeyCountry = "country=";
      var parmascountry = `${pais}`;

      const response = await QueryHuellaDigital2(
        paramsKeyName,
        paramsname,
        paramsKeyCorreo,
        paramsCorreo,
        paramsKeyPhone,
        paramsPhone,
        paramsKeyCountry,
        parmascountry
      );

      if (response.error === false && response.data.length > 0) {
        const data = response.data;
        setHuella2Item(data[0]);

        setModalhuella2(true);

        setBtnText("Consultar");
        funGetBalance();
        props.funLogsQuery();
      }
      if (response.error === false && response.data.length == 0) {
        const data = response.data;

        setHuella2Item(data[0]);

        setModalhuella2(true);
        funGetBalance();
        props.funLogsQuery();

        setBtnText("Consultar");
      } else if (response.message == "NOT_ENOUGH_BALANCE") {
        swal({
          title: "No tienes saldo disponible para realizar esta consulta",
          text: "¿Deseas Recargar? ",
          icon: "info",
          buttons: {
            cancel: "No",
            confirm: "Si",
          },
        }).then((willDelete) => {
          if (willDelete) {
            var itemMenu = "reload";
            dispatch({
              type: "SELECTED_ITEM_MENU",
              itemMenu,
            });
          } else {
          }
        });

        setBtnText("Consultar");
      } else {
        //AlertSwal('Verificar campos')
        setBtnText("Consultar");
      }
    } catch (error) {
      //AlertSwal('ERROR_SERVER')

      setBtnText("Consultar");
    }
    setModalLoading(false);

    setBtnText("Consultar");
  };
  const tipoConsulta = async (x) => {
    if (x == 1) {
      history.push("/consultar/la_ft");
    } else if (x == "3") {
      history.push("/consultar/huella_digital");
    } else if (x == "4") {
      history.push("/consultar/identidad");
    } else if (x == "5") {
      history.push("/consultar/validacion_IP");
    } else if (x == "6") {
      history.push("/consultar/validacion_BIN");
    } else if (x == "7") {
      history.push("/consultar/score_de_riesgo");
    } else if (x == "8") {
      history.push("/consultar/imagen_perfil");
    } else if (x == "9") {
      history.push("/consultar/informacion_laboral");
    } else if (x == "10") {
      history.push("/consultar/fraude");
    }

    setIdTypeQuery(x);
  };

  return (
    <div className="content-jumbotron  basket animated fadeIn mt-2 mb-2">
      <Form onSubmit={(e) => queryHuellaDigital2(e)}>
        <Row
          form
          style={{
            alignitems: "center",
          }}
        >
          <Col md={2}>
            <FormGroup>
              <Input
                type="select"
                name="type_query_id"
                defaultValue="9"
                onChange={(e) => tipoConsulta(e.target.value)}
                required
              >
                <option hidden disabled={true} value="">
                  Tipo de consulta
                </option>
                <option value="1"> LA / FT </option>
                {/*                 <option value="3"> Huella digital </option>
 */}                <option value="4"> Identidad </option>
                <option value="5"> Validación IP </option>
                <option value="6"> Validación BIN </option>
                <option value="7"> Score de riesgo </option>
                <option value="8"> Imagen perfil </option>
                <option value="9"> Informacion laboral </option>
                <option value="10"> Fraude </option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input
                type="text"
                name="full_name"
                placeholder="Nombre Completo"
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input
                type="text"
                name="phone"
                placeholder="Número De Celular "
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input
                type="email"
                name="correo"
                placeholder="Correo Electrónico"
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup className="imput-huella-pais">
              <ReactFlagsSelect
                placeholder="Seleccionar Pais"
                className="imput-pais"
                selected={selectedHuella2}
                onSelect={(e) => setSelectedHuella2(e)}
                customLabels={customLabels}
                searchable={searchable}
              />
            </FormGroup>
          </Col>
          <Col
            md={2}
            style={{
              marginleft: 10,
            }}
          >
            <FormGroup>
              <Button
                className="btn-green"
                disabled={getBtnText === "Consultando..."}
                block
              >
                {getBtnText}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>

      {getModalhuella2 !== false && (
        <ModalHuellaDigital
          item={Huella2Item}
          modal={getModalhuella2}
          hideModal={() => setModalhuella2(false)}
        />
      )}
      <ModalHuellaDigital2Loading modal={modalLoading} />
    </div>
  );
}
