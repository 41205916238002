
import React, { useState, useEffect } from 'react';
import { Badge, Col, Modal, ModalHeader, ModalBody, Row, Input } from 'reactstrap';
import { SummaryAsync, GetLogsQuery, pdf } from '../managers/ApiManager';

const ModalHuella = props => {


    const [OpenModal, setOpenModal] = useState();
    const [itemPerson, setItemPerson] = useState([]);


    //data para filtros
    const [names, setnames] = useState(true)
    const [eduacion, setEduacion] = useState(true)
    const [addresses, setAdresses] = useState(true)
    const [jobs, setJobs] = useState(true)
    const [username, setUsername] = useState(true)
    const [phone, setPhone] = useState(true)
    const [genero, setGenero] = useState(true)
    const [email, setEmail] = useState(true)
    const [idioma, setIdioma] = useState(true)
    const [relationships, setRelationships] = useState(true)
    const [urls, setUrls] = useState(true)
    //estas variables se usan para las condicionales de los input select de los filtros
    const [revisarData, setRevisarData] = useState("")
    const [datax, setData] = useState()
    const [revisarPersonas, setRevisarPersonas] = useState("")


    useEffect(() => {
        HuellaItem()
    })

    const HuellaItem = () => {
        if (props.modal == false) {
            setItemPerson("")

        }

        setOpenModal(props.modal)
        if (itemPerson == "") {
            setItemPerson(props.item)
        }


    }



    //estas funcion se encarga de filtrar las personas 
    const filterPerson = (i) => {
        setRevisarPersonas(i)//
        if (i != "") {

            setItemPerson([props.item[i]])
            setOpenModal(false)

        } else {
            setItemPerson(props.item)
            setOpenModal(false)

        }




    }

    const puebapdf = async () => {
        var x = <ModalBody className={"content-modalbody-query"} style={{ padding: 28 }}>


            {

                (itemPerson.length > 0) &&
                itemPerson.map((item, i) => {
                    return (



                        <div   >


                            <Row className="content-row-modalquery" >



                                {itemPerson.length == 1 &&
                                    <p className="titulo-modal" style={{ width: 420 }}><b>Huella digital </b></p>
                                }
                                {itemPerson.length > 1 &&
                                    <p className="titulo-modal" style={{ width: 420 }}><b>Huella digital {i + 1}</b></p>
                                }


                                {

                                    (item.persons.length > 0) &&

                                    item.persons.map((item, i) => {
                                        return (

                                            <div style={{ width: 740 }} >
                                                <Row className="content-row-modalquery" >


                                                    <Col md={12} >
                                                        {item.names != "" && names == true &&
                                                            item.usernames != "" && username == true &&
                                                            item.usernames != "" && username == true &&
                                                            item.phones != "" && phone == true &&
                                                            item.languages != "" && idioma == true &&
                                                            item.gender != "" && genero == true &&
                                                            <p className="titulo-modal"><b>Datos </b></p>
                                                        }
                                                        {
                                                            item.names != "" && names == true &&
                                                            <Row >
                                                                <Col md={12} style={{ paddingLeft: 15, paddingRight: 15 }}>

                                                                    <p className="text-titulo-modal-huella">Nombre Completo</p>
                                                                    <p className="text-content-modal-huella"> <b>{item.names[i].display}</b></p>
                                                                    <p className="text-titulo-modal-huella">Nombre</p>
                                                                    <p className="text-content-modal-huella"><b> {item.names[i].first}</b></p>

                                                                </Col>
                                                            </Row>
                                                        }

                                                        {item.usernames != "" && username == true &&

                                                            <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                <p className="text-titulo-modal-huella">Username</p>
                                                                {
                                                                    item.usernames[0].content != "" &&

                                                                    <p className="text-content-modal-huella"><b>{item.usernames[0].content}</b></p>
                                                                }

                                                            </Col>

                                                        }


                                                        {
                                                            item.phones != "" && phone == true &&

                                                            <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                <p className="text-titulo-modal-huella">Número De Celular</p>
                                                                <p className="text-content-modal-huella"><b>{FormatPhoneNumber(item.phones[0].number)}</b></p>
                                                            </Col>


                                                        }

                                                        {
                                                            item.languages != "" && idioma == true &&
                                                            <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                <p className="text-titulo-modal-huella">Idioma</p>
                                                                <p className="text-content-modal-huella"><b>{item.languages[0].language}</b></p>
                                                            </Col>
                                                        }
                                                        {
                                                            item.gender != "" && genero == true &&
                                                            <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                <p className="text-titulo-modal-huella">Género</p>
                                                                <p className="text-content-modal-huella"><b>{item.gender[0].content}</b></p>
                                                            </Col>
                                                        }





                                                    </Col>



                                                </Row>
                                                <hr className="hr-modal-query" />
                                                {item.addresses != "" && addresses == true &&
                                                    <Row>
                                                        <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                            <p className="titulo-modal" style={{ paddingLeft: 15, paddingRight: 15 }}><b>Ubicacion</b></p>
                                                            {
                                                                item.addresses[0].country != "" && <Col md={12}>
                                                                    <p className="text-titulo-modal-huella">Pais</p>
                                                                    <p className="text-content-modal"><b>   {item.addresses[0].country}</b>  </p>
                                                                </Col>
                                                            }
                                                            {
                                                                item.addresses[0].city != "" && <Col md={12}>
                                                                    <p className="text-titulo-modal-huella">Ciudad</p>
                                                                    <p className="text-content-modal">   <b>{item.addresses[0].city}</b> </p>
                                                                </Col>
                                                            }
                                                            {
                                                                item.addresses[0].display != "" && <Col md={12}>
                                                                    <p className="text-titulo-modal-huella">Dirección</p>
                                                                    <p className="text-content-modal">  <b> {item.addresses[0].display} </b> </p>

                                                                </Col>
                                                            }
                                                            <hr className="hr-modal-query" />





                                                        </Col>
                                                    </Row>

                                                }



                                                {
                                                    item.educations != "" && eduacion == true &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <p className="titulo-modal"><b>Educacion</b></p>
                                                            {
                                                                item.educations[0].school != "" &&
                                                                <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                    <p className="text-titulo-modal-huella"> Universidad  </p>
                                                                    <p className="text-content-modal-huella"><b>{item.educations[0].school}</b></p>
                                                                </Col>
                                                            }
                                                            {
                                                                item.educations[0].degree != "" &&
                                                                <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                    <p className="text-titulo-modal-huella"> Carrera  </p>
                                                                    <p className="text-content-modal-huella"><b>{item.educations[0].degree}</b></p>
                                                                </Col>
                                                            }
                                                            {
                                                                item.educations[0].date_range != "" &&
                                                                <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                    <p className="text-titulo-modal-huella"> Inicio La Carrera  </p>
                                                                    <p className="text-content-modal-huella"><b>{item.educations[0].date_range.start}</b>  </p>
                                                                    <p className="text-titulo-modal-huella"> Termino La Carrera  </p>
                                                                    {
                                                                        item.educations[0].date_range.end == "" &&
                                                                        <p className="text-content-modal-huella"><b>Indefinido </b> </p>
                                                                    }
                                                                    <p className="text-content-modal-huella"><b>{item.educations[0].date_range.end} </b> </p>
                                                                </Col>
                                                            }






                                                            <hr className="hr-modal-query" />

                                                        </Col>
                                                    </Row>

                                                }


                                                <Row>
                                                    <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                        {
                                                            item.jobs.length > 0 && jobs == true &&
                                                            <p className="titulo-modal" style={{
                                                                paddingLeft: 15,
                                                                paddingRight: 15
                                                            }}><b>Trabajos</b></p>
                                                        }

                                                        {
                                                            (item.jobs.map != "") && jobs == true &&

                                                            item.jobs.map((item, i) => {
                                                                return (
                                                                    <Col md={12}  >

                                                                        <p className="text-titulo-modal-huella "> posicion  </p>
                                                                        <p className="text-content-modal-huella"><b>{item.display}</b></p>
                                                                        {item.organization !== "" &&
                                                                            <Col md={12} style={{
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0
                                                                            }} >
                                                                                <p className="text-titulo-modal-huella"> organizacion  </p>
                                                                                <p className="text-content-modal-huella"><b>{item.organization}</b></p>

                                                                            </Col >

                                                                        }{item.title !== "" &&
                                                                            <Col md={12} style={{
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0
                                                                            }} >
                                                                                <p className="text-titull-huellao-moda"> titulo  </p>
                                                                                <p className="text-content-modal-huella"><b>{item.title}</b></p>

                                                                            </Col >

                                                                        }{item.date_range != "" &&
                                                                            <Col md={12} style={{
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0
                                                                            }}  >
                                                                                <p className="text-titulo-modal-huella" > Empezo   </p>
                                                                                <p className="text-content-modal-huella"><b>{item.date_range.start}</b></p>

                                                                                <p className="text-titulo-modal-huella" > Termino   </p>
                                                                                {
                                                                                    item.date_range.end == "" &&
                                                                                    <p className="text-content-modal-huella"><b>Indefinido </b> </p>
                                                                                }
                                                                                <p className="text-content-modal-huella"><b> {item.date_range.end}</b></p>
                                                                            </Col >

                                                                        }




                                                                        <hr className="hr-modal-query" />
                                                                    </Col>
                                                                )
                                                            })

                                                        }




                                                        {
                                                            item.urls.length > 0 && urls == true &&
                                                            <p className="titulo-modal" style={{
                                                                paddingLeft: 15,
                                                                paddingRight: 15
                                                            }}><b>Redes</b></p>
                                                        }

                                                        {
                                                            (item.urls.map != "") && urls == true &&

                                                            item.urls.map((item, i) => {
                                                                return (
                                                                    <Col md={12}  >
                                                                        {item.name[i] !== "" &&
                                                                            <Col md={12} style={{
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0
                                                                            }} >
                                                                                <p className="text-titulo-modal-huella "> Red Social </p>
                                                                                <p className="text-content-modal-huella"><b>{item.name}</b></p>
                                                                            </Col>
                                                                        }{item.domain != "" &&
                                                                            <Col md={12} style={{
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0
                                                                            }}  >
                                                                                <p className="text-titulo-modal-huella" > Dominio   </p>
                                                                                <p className="text-content-modal-huella"><b>{item.domain}</b></p>




                                                                            </Col >

                                                                        }

                                                                        {item.url !== "" &&
                                                                            <Col md={12} style={{
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0
                                                                            }} >
                                                                                <p className="text-titulo-modal-huella"> Link  </p>
                                                                                <a href={item.url} target="_blank" className="text-content-modal-huella links" ><b>{item.url}</b></a>

                                                                            </Col >

                                                                        }



                                                                        <hr className="hr-modal-query" />
                                                                    </Col>
                                                                )
                                                            })

                                                        }
                                                        {
                                                            item.relationships.length > 0 && relationships == true &&
                                                            <p className="titulo-modal" style={{
                                                                paddingLeft: 15,
                                                                paddingRight: 15
                                                            }}><b>Relaciones</b></p>
                                                        }
                                                        {
                                                            (item.relationships.map != "") && relationships == true &&

                                                            item.relationships.map((item, i) => {
                                                                return (
                                                                    <Col md={12}  >
                                                                        {item.name !== "" &&
                                                                            <Col md={12} style={{
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0
                                                                            }} >
                                                                                <p className="text-titulo-modal-huella "> Nombre Completo  </p>

                                                                                <p className="text-content-modal-huella"><b>{item.names[0].display}</b></p>
                                                                            </Col>
                                                                        }

                                                                        {item.phones != "" &&
                                                                            <Col md={12} style={{
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0
                                                                            }} >
                                                                                <p className="text-titulo-modal-huella"> Número De Celular  </p>
                                                                                {console.log(item.phones)}
                                                                                <p className="text-content-modal-huella"><b>{FormatPhoneNumber(item.number)}</b></p>

                                                                            </Col >

                                                                        }{item.emails != "" &&
                                                                            <Col md={12} style={{
                                                                                paddingLeft: 0,
                                                                                paddingRight: 0
                                                                            }} >
                                                                                <p className="text-titull-huellao-moda"> Correo  </p>
                                                                                {console.log(item.emails)}
                                                                                <p className="text-content-modal-huella"><b>{item.emails.address}</b></p>

                                                                            </Col >

                                                                        }





                                                                        <hr className="hr-modal-query" />
                                                                    </Col>
                                                                )
                                                            })

                                                        }
                                                    </Col>
                                                </Row>



                                            </div>

                                        )
                                    })
                                }


                            </Row>


                        </div>

                    );
                }
                )}{(itemPerson.length == 0) && <h4 className="text-center " style={{ marginTop: 200 }}>No se encontraron datos</h4>

            }
        </ModalBody>
        var res = await pdf(datax)
        console.log(res)
    }
    //filterData es una se encarga de cambiar los valores de las variables que se encuentran
    // en las condicionales del modal para que estas no se vean 
    const filterData = (i) => {
        setRevisarData(i)
        if (i != "") {
            setRelationships(true)
            setUrls(true)
            setIdioma(true)
            setGenero(true)
            setPhone(true)
            setUsername(true)
            setnames(true)
            setJobs(true)
            setAdresses(true)
            setEduacion(true)
            setOpenModal(false)
        }
        if (i == 1) {
            setRelationships(false)
            setUrls(false)
            setJobs(false)
            setAdresses(false)
            setEduacion(false)
            setOpenModal(false)

        }
        else if (i == 2) {
            setRelationships(false)
            setUrls(false)
            setIdioma(false)
            setGenero(false)
            setPhone(false)
            setUsername(false)
            setnames(false)
            setJobs(false)
            setEduacion(false)
            setOpenModal(false)
        } else if (i == 3) {
            setRelationships(false)
            setUrls(false)
            setIdioma(false)
            setGenero(false)
            setPhone(false)
            setUsername(false)
            setnames(false)
            setJobs(false)
            setAdresses(false)
            setOpenModal(false)

        } else if (i == 4) {
            setRelationships(false)
            setUrls(false)
            setIdioma(false)
            setGenero(false)
            setPhone(false)
            setUsername(false)
            setnames(false)
            setAdresses(false)
            setEduacion(false)
            setOpenModal(false)

        } else if (i == 5) {
            setRelationships(false)
            setIdioma(false)
            setGenero(false)
            setPhone(false)
            setUsername(false)
            setnames(false)
            setJobs(false)
            setAdresses(false)
            setEduacion(false)
            setOpenModal(false)

        } else if (i == 6) {

            setUrls(false)
            setIdioma(false)
            setGenero(false)
            setPhone(false)
            setUsername(false)
            setnames(false)
            setJobs(false)
            setAdresses(false)
            setEduacion(false)
            setOpenModal(false)
        }
        else if (i == "") {
            setRelationships(true)
            setUrls(true)
            setIdioma(true)
            setGenero(true)
            setPhone(true)
            setUsername(true)
            setnames(true)
            setJobs(true)
            setAdresses(true)
            setEduacion(true)
            setOpenModal(false)
        }

    }
    const FormatPhoneNumber = (number) => {

        if (number != undefined) {
            //console.log(number,'number');

            let string = number.toString();
            let phoneFormat = string.replace(/^(\d{3})(\d{3})(\d{}).*/, '($1) $2-$3'); //quitamos los caracteres que no sean digitos

            phoneFormat = phoneFormat.substring(0, 16);// el maximo de digitos para el numero de telefono es 12
            phoneFormat = phoneFormat.replace(/^(\d{3})/g, "(" + phoneFormat.substring(0, 3) + ")"); //agregamos los parentesis. 
            phoneFormat = phoneFormat.replace(/\)(\d{3})/g, ") " + phoneFormat.substring(5, 8) + "-"); //agregamos un espacio y el primer guion

            return phoneFormat;
        }


    }





    console.log(props.item)

    return (
        <>

            <Modal isOpen={OpenModal} backdrop={'static'}>
                <ModalHeader toggle={props.hideModal} style={{ backgroundColor: "rgb(213,213,215)" }}>
                    {/* {`${props.item.name}`}julio alfredo bargas */}Huella digital
                    <button onClick={(e) => puebapdf(e)}>download</button>

                    <span style={{ color: 'rgb(61,64,82)', fontSize: 13 }}>

                        <Input type="select" onChange={e => filterPerson(e.target.value)} style={{ width: '100%' }} placeholder="">


                            <option hidden value="">Filtrar por Personas</option>

                            {revisarPersonas != "" &&
                                <option value="">Mostrar Todas las Personas</option>
                            }

                            {props.item.map((item, i) => <option value={i}>huella persona {i + 1}</option>)}



                        </Input>
                        <Input type="select" onChange={e => filterData(e.target.value)} style={{ width: '100%' }} placeholder="">


                            <option hidden value="">Filtrar Datos</option>

                            {revisarData != "" &&
                                <option value="">Mostrar Todo</option>
                            }
                            <option value="1">Datos</option>
                            <option value="2">Ubicacion</option>
                            <option value="3">Educacion</option>
                            <option value="4">Trabajos</option>
                            <option value="5">Redes sociales</option>
                            <option value="6">Relaciones</option>
                        </Input>
                    </span>
                    <a href="/download" ><i className="fas fa-file-download download-button"></i></a>
                </ModalHeader>
                <ModalBody className={"content-modalbody-query"} style={{ padding: 28 }}>


                    {

                        (itemPerson.length > 0) &&
                        itemPerson.map((item, i) => {
                            return (



                                <div   >


                                    <Row className="content-row-modalquery" >



                                        {itemPerson.length == 1 &&
                                            <p className="titulo-modal" style={{ width: 420 }}><b>Huella digital </b></p>
                                        }
                                        {itemPerson.length > 1 &&
                                            <p className="titulo-modal" style={{ width: 420 }}><b>Huella digital {i + 1}</b></p>
                                        }


                                        {

                                            (item.persons.length > 0) &&

                                            item.persons.map((item, i) => {
                                                return (

                                                    <div style={{ width: 740 }} >
                                                        <Row className="content-row-modalquery" >


                                                            <Col md={12} >
                                                                {item.names != "" && names == true &&
                                                                    item.usernames != "" && username == true &&
                                                                    item.usernames != "" && username == true &&
                                                                    item.phones != "" && phone == true &&
                                                                    item.languages != "" && idioma == true &&
                                                                    item.gender != "" && genero == true &&
                                                                    <p className="titulo-modal"><b>Datos </b></p>
                                                                }
                                                                {
                                                                    item.names != "" && names == true &&
                                                                    <Row >
                                                                        <Col md={12} style={{ paddingLeft: 15, paddingRight: 15 }}>

                                                                            <p className="text-titulo-modal-huella">Nombre Completo</p>
                                                                            <p className="text-content-modal-huella"> <b>{item.names[i].display}</b></p>
                                                                            <p className="text-titulo-modal-huella">Nombre</p>
                                                                            <p className="text-content-modal-huella"><b> {item.names[i].first}</b></p>

                                                                        </Col>
                                                                    </Row>
                                                                }

                                                                {item.usernames != "" && username == true &&

                                                                    <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                        <p className="text-titulo-modal-huella">Username</p>
                                                                        {
                                                                            item.usernames[0].content != "" &&

                                                                            <p className="text-content-modal-huella"><b>{item.usernames[0].content}</b></p>
                                                                        }

                                                                    </Col>

                                                                }


                                                                {
                                                                    item.phones != "" && phone == true &&

                                                                    <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                        <p className="text-titulo-modal-huella">Número De Celular</p>
                                                                        <p className="text-content-modal-huella"><b>{FormatPhoneNumber(item.phones[0].number)}</b></p>
                                                                    </Col>


                                                                }

                                                                {
                                                                    item.languages != "" && idioma == true &&
                                                                    <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                        <p className="text-titulo-modal-huella">Idioma</p>
                                                                        <p className="text-content-modal-huella"><b>{item.languages[0].language}</b></p>
                                                                    </Col>
                                                                }
                                                                {
                                                                    item.gender != "" && genero == true &&
                                                                    <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                        <p className="text-titulo-modal-huella">Género</p>
                                                                        <p className="text-content-modal-huella"><b>{item.gender[0].content}</b></p>
                                                                    </Col>
                                                                }





                                                            </Col>



                                                        </Row>
                                                        <hr className="hr-modal-query" />
                                                        {item.addresses != "" && addresses == true &&
                                                            <Row>
                                                                <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                    <p className="titulo-modal" style={{ paddingLeft: 15, paddingRight: 15 }}><b>Ubicacion</b></p>
                                                                    {
                                                                        item.addresses[0].country != "" && <Col md={12}>
                                                                            <p className="text-titulo-modal-huella">Pais</p>
                                                                            <p className="text-content-modal"><b>   {item.addresses[0].country}</b>  </p>
                                                                        </Col>
                                                                    }
                                                                    {
                                                                        item.addresses[0].city != "" && <Col md={12}>
                                                                            <p className="text-titulo-modal-huella">Ciudad</p>
                                                                            <p className="text-content-modal">   <b>{item.addresses[0].city}</b> </p>
                                                                        </Col>
                                                                    }
                                                                    {
                                                                        item.addresses[0].display != "" && <Col md={12}>
                                                                            <p className="text-titulo-modal-huella">Dirección</p>
                                                                            <p className="text-content-modal">  <b> {item.addresses[0].display} </b> </p>

                                                                        </Col>
                                                                    }
                                                                    <hr className="hr-modal-query" />





                                                                </Col>
                                                            </Row>

                                                        }



                                                        {
                                                            item.educations != "" && eduacion == true &&
                                                            <Row>
                                                                <Col md={12}>
                                                                    <p className="titulo-modal"><b>Educacion</b></p>
                                                                    {
                                                                        item.educations[0].school != "" &&
                                                                        <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                            <p className="text-titulo-modal-huella"> Universidad  </p>
                                                                            <p className="text-content-modal-huella"><b>{item.educations[0].school}</b></p>
                                                                        </Col>
                                                                    }
                                                                    {
                                                                        item.educations[0].degree != "" &&
                                                                        <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                            <p className="text-titulo-modal-huella"> Carrera  </p>
                                                                            <p className="text-content-modal-huella"><b>{item.educations[0].degree}</b></p>
                                                                        </Col>
                                                                    }
                                                                    {
                                                                        item.educations[0].date_range != "" &&
                                                                        <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                            <p className="text-titulo-modal-huella"> Inicio La Carrera  </p>
                                                                            <p className="text-content-modal-huella"><b>{item.educations[0].date_range.start}</b>  </p>
                                                                            <p className="text-titulo-modal-huella"> Termino La Carrera  </p>
                                                                            {
                                                                                item.educations[0].date_range.end == "" &&
                                                                                <p className="text-content-modal-huella"><b>Indefinido </b> </p>
                                                                            }
                                                                            <p className="text-content-modal-huella"><b>{item.educations[0].date_range.end} </b> </p>
                                                                        </Col>
                                                                    }






                                                                    <hr className="hr-modal-query" />

                                                                </Col>
                                                            </Row>

                                                        }


                                                        <Row>
                                                            <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                {
                                                                    item.jobs.length > 0 && jobs == true &&
                                                                    <p className="titulo-modal" style={{
                                                                        paddingLeft: 15,
                                                                        paddingRight: 15
                                                                    }}><b>Trabajos</b></p>
                                                                }

                                                                {
                                                                    (item.jobs.map != "") && jobs == true &&

                                                                    item.jobs.map((item, i) => {
                                                                        return (
                                                                            <Col md={12}  >

                                                                                <p className="text-titulo-modal-huella "> posicion  </p>
                                                                                <p className="text-content-modal-huella"><b>{item.display}</b></p>
                                                                                {item.organization !== "" &&
                                                                                    <Col md={12} style={{
                                                                                        paddingLeft: 0,
                                                                                        paddingRight: 0
                                                                                    }} >
                                                                                        <p className="text-titulo-modal-huella"> organizacion  </p>
                                                                                        <p className="text-content-modal-huella"><b>{item.organization}</b></p>

                                                                                    </Col >

                                                                                }{item.title !== "" &&
                                                                                    <Col md={12} style={{
                                                                                        paddingLeft: 0,
                                                                                        paddingRight: 0
                                                                                    }} >
                                                                                        <p className="text-titull-huellao-moda"> titulo  </p>
                                                                                        <p className="text-content-modal-huella"><b>{item.title}</b></p>

                                                                                    </Col >

                                                                                }{item.date_range != "" &&
                                                                                    <Col md={12} style={{
                                                                                        paddingLeft: 0,
                                                                                        paddingRight: 0
                                                                                    }}  >
                                                                                        <p className="text-titulo-modal-huella" > Empezo   </p>
                                                                                        <p className="text-content-modal-huella"><b>{item.date_range.start}</b></p>

                                                                                        <p className="text-titulo-modal-huella" > Termino   </p>
                                                                                        {
                                                                                            item.date_range.end == "" &&
                                                                                            <p className="text-content-modal-huella"><b>Indefinido </b> </p>
                                                                                        }
                                                                                        <p className="text-content-modal-huella"><b> {item.date_range.end}</b></p>
                                                                                    </Col >

                                                                                }




                                                                                <hr className="hr-modal-query" />
                                                                            </Col>
                                                                        )
                                                                    })

                                                                }




                                                                {
                                                                    item.urls.length > 0 && urls == true &&
                                                                    <p className="titulo-modal" style={{
                                                                        paddingLeft: 15,
                                                                        paddingRight: 15
                                                                    }}><b>Redes</b></p>
                                                                }

                                                                {
                                                                    (item.urls.map != "") && urls == true &&

                                                                    item.urls.map((item, i) => {
                                                                        return (
                                                                            <Col md={12}  >
                                                                                {item.name[i] !== "" &&
                                                                                    <Col md={12} style={{
                                                                                        paddingLeft: 0,
                                                                                        paddingRight: 0
                                                                                    }} >
                                                                                        <p className="text-titulo-modal-huella "> Red Social </p>
                                                                                        <p className="text-content-modal-huella"><b>{item.name}</b></p>
                                                                                    </Col>
                                                                                }{item.domain != "" &&
                                                                                    <Col md={12} style={{
                                                                                        paddingLeft: 0,
                                                                                        paddingRight: 0
                                                                                    }}  >
                                                                                        <p className="text-titulo-modal-huella" > Dominio   </p>
                                                                                        <p className="text-content-modal-huella"><b>{item.domain}</b></p>




                                                                                    </Col >

                                                                                }

                                                                                {item.url !== "" &&
                                                                                    <Col md={12} style={{
                                                                                        paddingLeft: 0,
                                                                                        paddingRight: 0
                                                                                    }} >
                                                                                        <p className="text-titulo-modal-huella"> Link  </p>
                                                                                        <a href={item.url} target="_blank" className="text-content-modal-huella links" ><b>{item.url}</b></a>

                                                                                    </Col >

                                                                                }



                                                                                <hr className="hr-modal-query" />
                                                                            </Col>
                                                                        )
                                                                    })

                                                                }
                                                                {
                                                                    item.relationships.length > 0 && relationships == true &&
                                                                    <p className="titulo-modal" style={{
                                                                        paddingLeft: 15,
                                                                        paddingRight: 15
                                                                    }}><b>Relaciones</b></p>
                                                                }
                                                                {
                                                                    (item.relationships.map != "") && relationships == true &&

                                                                    item.relationships.map((item, i) => {
                                                                        return (
                                                                            <Col md={12}  >
                                                                                {item.name !== "" &&
                                                                                    <Col md={12} style={{
                                                                                        paddingLeft: 0,
                                                                                        paddingRight: 0
                                                                                    }} >
                                                                                        <p className="text-titulo-modal-huella "> Nombre Completo  </p>

                                                                                        <p className="text-content-modal-huella"><b>{item.names[0].display}</b></p>
                                                                                    </Col>
                                                                                }

                                                                                {item.phones != "" &&
                                                                                    <Col md={12} style={{
                                                                                        paddingLeft: 0,
                                                                                        paddingRight: 0
                                                                                    }} >
                                                                                        <p className="text-titulo-modal-huella"> Número De Celular  </p>
                                                                                        {console.log(item.phones)}
                                                                                        <p className="text-content-modal-huella"><b>{FormatPhoneNumber(item.number)}</b></p>

                                                                                    </Col >

                                                                                }{item.emails != "" &&
                                                                                    <Col md={12} style={{
                                                                                        paddingLeft: 0,
                                                                                        paddingRight: 0
                                                                                    }} >
                                                                                        <p className="text-titull-huellao-moda"> Correo  </p>
                                                                                        {console.log(item.emails)}
                                                                                        <p className="text-content-modal-huella"><b>{item.emails.address}</b></p>

                                                                                    </Col >

                                                                                }





                                                                                <hr className="hr-modal-query" />
                                                                            </Col>
                                                                        )
                                                                    })

                                                                }
                                                            </Col>
                                                        </Row>



                                                    </div>

                                                )
                                            })
                                        }


                                    </Row>


                                </div>

                            );
                        }
                        )}{(itemPerson.length == 0) && <h4 className="text-center " style={{ marginTop: 200 }}>No se encontraron datos</h4>

                    }
                </ModalBody>

            </Modal>

        </>
    );
}


const ModalHuellaLoading = props => {
    return (
        <>
            <Modal isOpen={props.modal} backdrop={'static'} className="modal-loading"  >
                <img style={{ margin: '0 auto' }} src="/assets/loadingIcon.gif" alt="img loading" width="50" height="50" />
            </Modal>
        </>
    )
}

export {
    ModalHuellaLoading,
    ModalHuella
}
