import React, { useState, useEffect } from 'react';
import { Badge, Table } from 'reactstrap';
import { GetClients, DownloadPDF, LogsClients } from '../managers/ApiManager';
import NavComponent from './Nav';
import moment from 'moment';
import _ from 'lodash';
import { ResponsePDF } from '../managers/HelperManager';





export default function ClientComponent(props) {

    const [clientsList, setClientList] = useState([]);
    const [filteredClientList, setFilteredClientList] = useState([]);
    const [itemMenu, setItemMenu] = useState("Todos");
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    //let permission = props.resProfile.permissions[0];

    useEffect(() => {
        getClients();
    }, [])

    const getClients = async () => {
        let response = await GetClients('');
        //console.log(response.data,'GetClients');

        if (response.data !== undefined) {

            if (response.data.length > 0) {
                setClientList(response.data);
            }

            setIsError(false);
            setIsLoading(false);
        } else {
            setIsError(true);
            setIsLoading(false);
        }


    }


    const onFilter = (type) => {
        let filter;

        setItemMenu(type);

        if (type === 'Validados') {

            filter = _.filter(clientsList, client => client.id > 30);
            //console.log(filter,'Validados');

        } else if (type === 'Fallidos') {

            filter = _.filter(clientsList, client => client.id < 30);
            //console.log(filter,'Fallidos');

        } else if (type === 'Todos') {
            filter = [];
        }

        setFilteredClientList(filter);

    }


    const funItemActive = (itemName) => {
        return itemName === itemMenu ? "filter-active-item" : "";
    }


    // return the badge color and message 
    const funBadgeColor = percent => {
        let badgeColor = "";

        if (percent > 50) {
            badgeColor = '#1CCE28';
        } else if (percent > 30) {
            badgeColor = '#E6C029';
        } else if (percent < 30) {
            badgeColor = '#FF5A52';
        }

        return badgeColor;
    }


    const percentTxt = percent => {
        let percentTxt = "";

        if (percent > 50) {
            percentTxt = `${percent}% Bueno`;
        } else if (percent > 30) {
            percentTxt = `${percent}% Medio`;
        } else if (percent < 30) {
            percentTxt = `${percent}% Malo`;
        }

        return percentTxt;
    }


    const clientDetails = async (item) => {

        //console.log(item,'clientDetails');
        props.modalClientItem(item);

        let response = await GetClients(item.id);
        let responseLogsCliente = await LogsClients(item.id);

        if (response.data !== undefined) {
            //console.log(response.data.documents,'clientDocuments');
            await props.modalClientDocuments(response.data.documents);
            await props.modalClientLogs(responseLogsCliente)
            props.modalClient(true);
        }

    }



    //,'height=400,width=600' 
    //ventana.document.write(``)<br/>
    const generateReport = async (item) => {
        let response = await GetClients(item.id);
        let ventana = window.open('', 'PRINT', 'height=400,width=600');

        ventana.document.write(`<html>
        <head >
           <title class="headerPdf">${item.name} ${item.last_name} Id: ${item.document_number}  | ${moment(Date.now()).format('MM/DD/YYYY - h:mm:ss a')} | validu.co </title>`);
        ventana.document.write('<link rel="stylesheet" href="PdfStyles.css">'); //Aquí agregué la hoja de estilos
        ventana.document.write(`</head> <body class="bodyPdf">`);
        ventana.document.write(`<div class="Page">`);

        ventana.document.write(`<h1>${item.name} ${item.last_name} Id: ${item.document_number}</h1>`);
        ventana.document.write('<p class="clientInfo"> Email: ' + `${item.email}</p>`);
        ventana.document.write('<p class="clientInfo"> Teléfono: ' + `${item.phone} </p>`);
        ventana.document.write('<p class="clientInfo"> Fecha de nacimiento: ' + `${moment(item.date_birth).format('L')} </p>`);
        ventana.document.write('<p class="clientInfo"> Tipo de cliente: ' + `${item.get_client_type_display} </p>`);
        ventana.document.write('<p class="clientInfo"> Tipo: ' + `${item.get_type_document_display} </p>`);
        ventana.document.write('<p class="clientInfo"> Fecha de expedición: ' + `${moment(item.date_expidition).format('L')} </p>`);

        ventana.document.write(`</div>`);

        if (response.data !== undefined) {

            response.data.documents.map((item, i) => {
                //console.log(item,'item');
                //debugger
                if (item.type_document.name === 'Cedula-Frente') {
                    ventana.document.write(`<p >${item.type_document.name.replace(/-/g, ' ')}  Status: ${item.get_document_status_display}</p>`);
                    ventana.document.write(`<div class="documentImgContainer">`);
                    ventana.document.write(`<img class="documentImg" src=${item.url} alt="Cedula Frente"></img>`);
                    ventana.document.write(`</div>`)

                }

                if (item.type_document.name === 'Cedula-Reverso') {
                    ventana.document.write(`<p>${item.type_document.name.replace(/-/g, ' ')}  Status: ${item.get_document_status_display}</p>`);
                    ventana.document.write(`<div class="documentImgContainer">`)
                    ventana.document.write(`<img class="documentImg" src=${item.url} alt="Cedula Reverso"></img>`);
                    ventana.document.write(`</div>`)
                }



                if (item.type_document.name === 'RUT') {
                    ventana.document.write(`<p>${item.type_document.name.replace(/-/g, ' ')}  Status: ${item.get_document_status_display}</p>`);
                    ventana.document.write(`<div class="documentImgContainer">`)
                    ventana.document.write(`<img class="documentImg" src=${item.url} alt="RUT"></img>`);
                    ventana.document.write(`</div>`)
                }

                if (item.type_document.name === 'Camara-de-Comercio') {
                    ventana.document.write(`<p>${item.type_document.name.replace(/-/g, ' ')}  Status: ${item.get_document_status_display}</p>`);
                    ventana.document.write(`<div class="documentImgContainer">`)
                    ventana.document.write(`<img class="documentImg" src=${item.url} alt="Camara de Comercio"></img>`);
                    ventana.document.write(`</div>`)
                }

            });

        }


        ventana.document.write('</body></html>');
        ventana.document.close(); //comentar para ver el html en el modal.
        ventana.focus();
        ventana.onload = function () {
            ventana.print();
            ventana.close();
        };

    }


    // download query button
    const funDownloadQuery = async (e, id) => {
        e.preventDefault();
        ResponsePDF(await DownloadPDF(id))
    }


    // function for render the form send data query
    const funRenderSendQuery = () => {
        // if ((permission.by_admin || permission.by_query)) {
        return (
            <>
                <div className="filter-item-container" >

                    <a href="#"
                        className={`filter-item ${funItemActive("Todos")}`}
                        name='Todos'
                        onClick={e => onFilter(e.target.name)}
                    >
                        Todos
                    </a>

                    <a href="#"
                        className={`filter-item ${funItemActive("Validados")}`}
                        name='Validados'
                        onClick={e => onFilter(e.target.name)}
                    >
                        Validados
                    </a>

                    <a href="#"
                        className={`filter-item ${funItemActive("Fallidos")}`}
                        name='Fallidos'
                        onClick={e => onFilter(e.target.name)}
                    >
                        Fallidos
                    </a>

                </div>
            </>
        );
        // }
    }

    // function for render the list data query
    const funRenderListQuery = () => {
        //if ((permission.by_admin || permission.by_read)) {
        return (
            <>
                <div className="scrolltable " >
                    <Table borderless hover className="text-white custom-table" >
                        <thead >
                            <tr>
                                <th>PK</th>
                                <th>ID</th>
                                <th>VER</th>
                                <th>DESCARGAR</th>
                                <th>TIPO</th>
                                <th>NOMBRE</th>
                                <th>FECHA DE CONSULTA</th>
                                <th>SCORE</th>
                                <th>ALERTAS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (filteredClientList.length === 0 && clientsList.length > 0 && !isLoading) &&
                                clientsList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item.id}</td>
                                            <td>{item.document_number}</td>
                                            <td><a href="#" onClick={() => clientDetails(item)}><i className="fas fa-eye"></i></a></td>
                                            <td>
                                                <a href="#" onClick={() => generateReport(item)}><i className="fas fa-file-download"></i></a>
                                            </td>
                                            <td>{item.get_type_document_display}</td>
                                            <td>{`${item.name} ${item.last_name}`}</td>
                                            <td>{moment(item.date_created).fromNow()}</td>
                                            <td>
                                                <Badge className="badge-percent" style={{ backgroundColor: funBadgeColor(90) }}> </Badge>
                                                &nbsp; {percentTxt(90)}
                                            </td>
                                            <td className='alertFlexContainer'><p className='alertContainer'>ALERTAS</p><p className='alertContainer'>ALERTAS</p><p className='alertContainer'>ALERTAS</p></td>
                                        </tr>
                                    );
                                })
                            }

                            {
                                (filteredClientList.length > 0) &&
                                filteredClientList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item.id}</td>
                                            <td>{item.document_number}</td>
                                            <td><a href="#" onClick={() => clientDetails(item)}><i className="fas fa-eye"></i></a></td>
                                            <td>
                                                <a href="#" onClick={() => generateReport(item)}><i className="fas fa-file-download"></i></a>
                                            </td>
                                            <td>{item.get_type_document_display}</td>
                                            <td>{`${item.name} ${item.last_name}`}</td>
                                            <td>{moment(item.date_created).fromNow()}</td>
                                            <td>
                                                <Badge className="badge-percent" style={{ backgroundColor: funBadgeColor(90) }}> </Badge>
                                                &nbsp; {percentTxt(90)}
                                            </td>
                                            <td className='alertFlexContainer'><p className='alertContainer'>ALERTAS</p><p className='alertContainer'>ALERTAS</p><p className='alertContainer'>ALERTAS</p></td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                    {
                        (isLoading)
                            ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="/assets/loadingIcon.gif" alt="img loading" width="40" height="40" />
                            </div >
                            :
                            (filteredClientList.length === 0 && clientsList.length === 0 && !isError) ?
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 200 }}>
                                    <h3 className='text-white navbar-title mt-2 mb-2'>No hay registros de clientes asociados a esta empresa</h3>
                                </div >
                                :
                                (filteredClientList.length === 0 && clientsList.length === 0 && isError) &&
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 200 }}>
                                    <h3 className='text-white navbar-title mt-2 mb-2'>No se pudo cargar la información debido a un error intente de nuevo</h3>
                                </div >
                    }


                </div>
            </>
        );
        //}
    }


    return (
        <>
            <NavComponent title={`Clientes ( ${props.resProfile.code_company} )`} name={props.resProfile.name} photo={props.resProfile.photo} />
            {funRenderSendQuery()}
            {/* <br /> */}
            {/* <Nav className="nav-content-items">
                <NavItem className="active-menu-scroll ">
                    <NavLink href="#" >Todos</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#">Validados</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#">Fallidos</NavLink>
                </NavItem>
            </Nav> */}
            <br />
            {funRenderListQuery()}
        </>
    );

}
