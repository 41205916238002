import React, { useState, createContext } from "react";
import { GetBalance } from "./ApiManager";
export const FunctionContext = createContext();

const Provider = ({ children }) => {
  const [Balance, setBalance] = useState([]);
  const funGetBalance = async () => {
    let resBalance = await GetBalance();

    setBalance(resBalance.balance);
  };
  const value = {
    Balance,
    funGetBalance: () => funGetBalance(),
  };
  return (
    <FunctionContext.Provider value={value}>
      {children}
    </FunctionContext.Provider>
  );
};
export default {
  Provider,
  Consumer: FunctionContext.Consumer,
};
