import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { GetFilterQuery } from "../managers/ApiManager";
import moment, { relativeTimeRounding } from 'moment';
import { } from "../managers/HelperManager";


const styles = StyleSheet.create({
    page: {
        padding: "25px"

    },
    section: {
        backgroundColor: "#d5d5d7",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        margin: "10px 20px 10px 20px",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",



    },
    header: {
        paddingLeft: "15px",
        display: "flex",
        width: "100%",
        marginBottom: "10px"
    },
    data: {
        paddingLeft: "15px",
        flexDirection: 'row',
        width: "100%"
    },
    textTitule: {
        fontSize: "12px",
        fontWeight: "medium"
    },
    BodyView: {
        margin: "0px 20px 00px 20px",
        paddingLeft: "15px",


    },
    linia: {
        borderBottom: "2px solid gray"
    },
    liniaDivisoria: {
        borderBottom: "1px solid gray",
        marginBottom: "15px",

    },
    tituloPrincipal: {
        marginTop: "5px",
        fontSize: "18px",

    },
    tituloSecundario: {
        fontSize: "16px",
        marginBottom: "5px",
        marginTop: "10px",
        color: "black"


    },
    titulo1: {
        color: "#777",
        fontSize: "11px",

        marginTop: "5px",


    },
    Text1: {
        fontSize: "14px",
        marginBottom: "5px",


    },
    CentrarText: {

        marginTop: "30%",
        marginLeft: "-25px",
        display: "flex",
        alignContent: "center",
        alignItems: "center"
    },
    link1: {
        color: "#007bff",
        textDecoration: "underline",
        textTransform: "lowercase",
        fontSize: "14px",
        marginBottom: "5px",
    }


});


const Huella2PDF = (props) => {
    console.log("aqui esta pros:  ", props)
    const [data, setData] = useState(null);
    useEffect(() => {
        if (props.show) {
            render();
        }



    }, [props.show]);


    const render = async () => {
        let res = await GetFilterQuery(props.id)
        setData(res)
        console.log(res)

    }




    return props.show && data !== null ? (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.header}>
                        <Text >Informacion laboral</Text>
                    </View>
                    <View style={styles.data}>
                        <Text style={styles.textTitule}>ID: </Text>
                        <Text style={styles.textTitule}>{data.data[0].id} </Text>
                        <Text style={styles.textTitule}> | </Text>
                        <Text style={styles.textTitule}>{data.data[0].employee} </Text>

                        <Text style={styles.textTitule}> | </Text>
                        <Text style={styles.textTitule}>{moment(data.data[0].date_created).format(('L h:mm:ss a'))}</Text>
                    </View>
                </View>
                {data.data[0].response.status != 404 &&
                    <View style={styles.BodyView}>
                        <Text style={styles.tituloPrincipal}>Datos</Text>

                        {data.data[0].response.data.full_name !== null && <View>
                            <Text style={styles.titulo1}>Nombre Completo</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.full_name)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.gender !== null && <View>
                            <Text style={styles.titulo1}>Género</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.gender)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.birth_year !== null && <View>
                            <Text style={styles.titulo1}>año de nacimiento</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.birth_year)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.birth_date !== null && <View>
                            <Text style={styles.titulo1}>cumpleaños</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.birth_date)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.linkedin_url !== null && <View>
                            <Text style={styles.titulo1}>link de usuario de linkedin</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.linkedin_url)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.linkedin_username !== null && <View>
                            <Text style={styles.titulo1}>nombre de usuario en linkedin</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.linkedin_username)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.facebook_url !== null && <View>
                            <Text style={styles.titulo1}>link de usuario de facebook</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.facebook_url)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.facebook_username !== null && <View>
                            <Text style={styles.titulo1}>nombre de usuario de facebook</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.facebook_username)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.twitter_url !== null && <View>
                            <Text style={styles.titulo1}>link de usuario de twitter</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.twitter_url)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.twitter_username !== null && <View>
                            <Text style={styles.titulo1}>nombre de usuario en twitter</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.twitter_username)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.github_url !== null && <View>
                            <Text style={styles.titulo1}>link de usuario en github</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.github_url)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.github_username !== null && <View>
                            <Text style={styles.titulo1}>nombre de usuario en github</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.github_username)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.work_email !== null && <View>
                            <Text style={styles.titulo1}>correo laboral</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.work_email)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.mobile_phone !== null && <View>
                            <Text style={styles.titulo1}>número de telefono</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.mobile_phone)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.industry !== null && <View>
                            <Text style={styles.titulo1}>industria</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.industry)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.job_title !== null && <View>
                            <Text style={styles.titulo1}>titulos laborales</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.job_title)}</Text>
                        </View>
                        }









                        {data.data[0].response.data.job_title_levels &&
                            data.data[0].response.data.job_title_levels.map((item, i) => {
                                return (
                                    <View style={styles.liniaDivisoria}>
                                        <Text style={styles.titulo1}>
                                            titulos laborales {i + 1}
                                        </Text>
                                        <Text style={styles.Text1}>
                                            {item}
                                        </Text>
                                    </View>
                                );
                            })}


                        {data.data[0].response.data.job_company_name !== null && <View>
                            <Text style={styles.titulo1}>nombre de empresa</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.job_company_name)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.job_company_website !== null && <View>
                            <Text style={styles.titulo1}>webside laboral</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.job_company_website)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.job_company_industry !== null && <View>
                            <Text style={styles.titulo1}>industria</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.job_company_industry)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.job_company_linkedin_url !== null && <View>
                            <Text style={styles.titulo1}>linkedin de la empresa</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.job_company_linkedin_url)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.job_company_facebook_url !== null && <View>
                            <Text style={styles.titulo1}>facebook de la empresa</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.job_company_facebook_url)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.job_company_twitter_url !== null && <View>
                            <Text style={styles.titulo1}>twitter de la empresa</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.job_company_twitter_url)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.job_company_location_name !== null && <View>
                            <Text style={styles.titulo1}>localización de la empresa</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.data.job_company_location_name)}</Text>
                        </View>
                        }




                        {data.data[0].response.data.phone_numbers &&
                            data.data[0].response.data.phone_numbers.map((item, i) => {
                                return (
                                    <View style={styles.liniaDivisoria}>
                                        <Text style={styles.titulo1}>
                                            numero de celular {i + 1}
                                        </Text>
                                        <Text style={styles.Text1}>
                                            {item}
                                        </Text>
                                    </View>
                                );
                            })}

                        {data.data[0].response.data.emails &&
                            data.data[0].response.data.emails.map((item, i) => {
                                return (
                                    <View style={styles.liniaDivisoria}>
                                        <Text style={styles.titulo1}>
                                            Correo {i + 1}
                                        </Text>

                                        <Text style={styles.Text1}>
                                            {item.address}
                                        </Text>
                                    </View>
                                );
                            })}
                        {data.data[0].response.data.job_company_location_name != "" && <View>
                            <Text style={styles.textTitule}>Experiencia</Text>
                        </View>
                        }



                        {data.data[0].response.data.experience &&
                            data.data[0].response.data.experience.map((item, i) => {
                                return (
                                    <View style={styles.liniaDivisoria}>
                                        {item.company["name"] && (
                                            <View>
                                                <Text style={styles.titulo1}>
                                                    nombre de la compañia
                                                </Text>
                                                <Text style={styles.Text1}>
                                                    {item.company["name"]}
                                                </Text>
                                            </View>
                                        )}
                                        {item.company["industry"] && (
                                            <View >
                                                <Text style={styles.titulo1}>
                                                    industria
                                                </Text>
                                                <Text style={styles.Text1}>
                                                    {item.company["industry"]}
                                                </Text>
                                            </View>
                                        )}
                                    </View>
                                );
                            })}



                        {data.data[0].response.data.experience &&
                            data.data[0].response.data.experience.map((item, i) => {
                                return (
                                    <View style={styles.liniaDivisoria}>
                                        {item.company["name"] && (
                                            <View >
                                                <Text style={styles.titulo1}>
                                                    nombre de la compañia
                                                </Text>
                                                <Text style={styles.Text1}>
                                                    {item.company["name"]}
                                                </Text>
                                            </View>
                                        )}
                                        {item.company["industry"] && (
                                            <View >
                                                <Text style={styles.titulo1}>
                                                    industria
                                                </Text>
                                                <Text style={styles.Text1}>
                                                    {item.company["industry"]}
                                                </Text>
                                            </View>
                                        )}
                                    </View>
                                );
                            })}


                        {data.data[0].response.data.interests != "" && <View style={styles.liniaDivisoria}>
                            <Text style={styles.titulo1}>itereses</Text>
                            <Text style={styles.Text1}>{data.data[0].response.data.interests.map((item) => (
                                `-${item}\n`
                            ))}</Text>
                        </View>
                        }
                        {data.data[0].response.data.skills != "" && <View style={styles.liniaDivisoria}>
                            <Text style={styles.titulo1}>habilidades</Text>
                            <Text style={styles.Text1}>{data.data[0].response.data.skills.map((item) => `-${item}\n`)}</Text>
                        </View>
                        }
                        {data.data[0].response.data.experience &&
                            data.data[0].response.data.experience.map((item, i) => {
                                return (
                                    <View style={styles.liniaDivisoria}>
                                        {item.company["name"] && (
                                            <View >
                                                <Text style={styles.titulo1}>
                                                    nombre de la compañia
                                                </Text>
                                                <Text style={styles.Text1}
                                                >
                                                    {item.company["name"]}
                                                </Text>
                                            </View>
                                        )}
                                        {item.company["industry"] && (
                                            <View >
                                                <Text style={styles.titulo1}>
                                                    industria
                                                </Text>
                                                <Text
                                                    style={styles.Text1}
                                                >
                                                    {item.company["industry"]}
                                                </Text>
                                            </View>
                                        )}
                                    </View>
                                );
                            })}



                        {data.data[0].response.data.interests != "" &&
                            <View >
                                <Text style={styles.textTitule}>Educacion</Text>

                            </View>
                        }
                        {data.data[0].response.data.education != "" &&
                            data.data[0].response.data.education.map((item, i) => {
                                return (
                                    <View style={styles.liniaDivisoria}>
                                        {item.school["name"] && (
                                            <View >
                                                <Text style={styles.titulo1}

                                                >
                                                    nombre de la escuela{" "}
                                                </Text>
                                                <Text
                                                    style={styles.Text1}
                                                >
                                                    {item.school["name"]}
                                                </Text>
                                            </View>
                                        )}
                                        {item.school["website"] && (
                                            <View >
                                                <Text style={styles.titulo1}

                                                >
                                                    webside de la escuela
                                                </Text>
                                                <Text
                                                    style={styles.Text1}
                                                >
                                                    {item.school["website"]}
                                                </Text>
                                            </View>
                                        )}
                                        {item.start_date && (
                                            <View >
                                                <Text
                                                    style={styles.titulo1}
                                                >
                                                    inicio
                                                </Text>
                                                <Text
                                                    style={styles.Text1}
                                                >
                                                    {item.start_date}
                                                </Text>
                                            </View>
                                        )}
                                        {item.end_date && (
                                            <View >
                                                <Text style={styles.titulo1}>
                                                    termino
                                                </Text>
                                                <Text style={styles.Text1}
                                                >
                                                    {item.end_date}
                                                </Text>
                                            </View>
                                        )}
                                    </View>
                                );
                            })}
                        {data.data[0].response.data.profiles != "" && <View>
                            <Text style={styles.textTitule}>Perfiles</Text>
                        </View>
                        }
                        {data.data[0].response.data.profiles &&
                            data.data[0].response.data.profiles.map((item, i) => {
                                return (
                                    <View style={styles.liniaDivisoria}>
                                        {item.network && (
                                            <View >
                                                <Text style={styles.titulo1}

                                                >
                                                    red
                                                </Text>
                                                <Text

                                                    style={styles.Text1}
                                                >
                                                    {item.network}
                                                </Text>
                                            </View>
                                        )}
                                        {item.username && (
                                            <View  >
                                                <Text style={styles.titulo1}>
                                                    nombre de usuario
                                                </Text>
                                                <Text
                                                    style={styles.Text1}

                                                >
                                                    {item.username}
                                                </Text>
                                            </View>
                                        )}
                                        {item.url && (
                                            <View >
                                                <Text style={styles.titulo1}>
                                                    links del perfil
                                                </Text>
                                                <Text style={styles.Text1}

                                                >
                                                    {item.url}
                                                </Text>
                                            </View>
                                        )}
                                    </View>
                                );
                            })}

                    </View>}
                {data.data[0].response.status == 404 &&
                    <View style={styles.CentrarText}><Text>No se encontraron datos</Text>
                    </View>}
            </Page>
        </Document >
    ) : (null)
}

export { Huella2PDF }


