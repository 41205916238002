import React, { useState, useEffect } from 'react';

export default function NavComponent(props) {

    const [close, setClose] = useState(false);

    const logOut = () => {
        if (close == false) {
            setClose(true)
        } else {
            setClose(false)
        }

    }
    const Logout = async (e) => {
        e.preventDefault();

        localStorage.clear();
        window.location.href = "/";
    }




    let countToogleButton = 0;
    // control the menubar
    const funToggleMenuBar = () => {
        let width = "";
        if (countToogleButton === 0) {
            width = "12rem";
            countToogleButton = 1;
        } else {
            width = "15rem";
            countToogleButton = 0;
        }
        document.getElementById("list-group-sidewrapper").style.width = width;
        document.getElementById("wrapper").classList.toggle("toggled");
    }



    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark-content ">
                <h3 className="text-white navbar-title mt-2 mb-2">{props.title}</h3>

                <button onClick={() => funToggleMenuBar()} className="navbar-toggler" type="button" data-toggle="collapse" data-target="/navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" style={{ marginBottom: 35 }} id="navbarSupportedContent">

                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">

                        <li onClick={logOut} className="nav-item active mt-2 mb-2  text-center">


                            <a className="nav-link capi" style={{ cursor: "pointer" }} >¡Hola! <br /> {props.name ? props.name.split(" ")[0] : ""}<span className="sr-only">(current)</span></a>


                        </li>
                        <li onClick={logOut} className="nav-item active mt-2 mb-2  text-center">

                            {<img className="rounded-circle " style={{ cursor: "pointer" }} src={`${props.photo == `https://validu-media.s3.amazonaws.com/dev/employees/placeholder.png` ? " https://validu-media.s3.amazonaws.com/dev/employees/placeholder.png " : `https://validu-media.s3.amazonaws.com/dev/` + props.photo}`} alt="user profile" width="50" height="50" />}

                        </li>
                    </ul>


                </div>
                <div>



                </div>
                {
                    close == true && <div className="boton_personalizado">

                        <a href="/logout" className="logout" onClick={e => Logout(e)}>
                            &nbsp; Cerrar Sesión&nbsp;
                        </a>

                    </div>
                }

            </nav>

        </>

    )

}

