import React, { useState, useEffect } from "react";
import {
  Badge,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Input,
} from "reactstrap";
import { TitleCase } from "../managers/HelperManager";
import moment from "moment";

const ModalHuellaDigital = (props) => {
  console.log(props.item);
  const [hola, setHola] = useState(
    props.item.document_number == "" ? props.item.response : props.item.data
  );
  const [perfiles, setPerfiles] = useState(true);
  const [educacion, setEducacion] = useState(true);
  const [experience, setExperience] = useState(true);
  const [datos, setDatos] = useState(true);
  const [cambio, setCambio] = useState(true);

  useEffect(() => {
    setHola(
      props.item.document_number == "" ? props.item.response : props.item.data
    );
  }, [cambio]);

  const filterPerson = (e) => {
    setPerfiles(true);
    setEducacion(true);
    setExperience(true);
    setDatos(true);

    if (e == 1) {
      setPerfiles(false);
      setEducacion(false);
      setExperience(false);
      setCambio(false);
    } else if (e == 2) {
      setPerfiles(false);
      setEducacion(false);
      setDatos(false);
      setCambio(false);
    } else if (e == 3) {
      setPerfiles(false);
      setExperience(false);
      setDatos(false);
      setCambio(false);
    } else if (e == 4) {
      setEducacion(false);
      setExperience(false);
      setDatos(false);
      setCambio(false);
    } else if (e == 5) {
      setPerfiles(true);
      setEducacion(true);
      setExperience(true);
      setDatos(true);
      setCambio(false);
    }
  };

  return (
    <>
      {props.item.response === undefined && (
        <Modal isOpen={props.modal} backdrop={"static"}>
          <ModalHeader
            toggle={props.hideModal}
            style={{ backgroundColor: "rgb(213,213,215)" }}
          >
            {/* {`${props.item.name}`}julio alfredo bargas */}Infotmacion laboral
            <br />
            <span
              style={{ color: "rgb(61,64,82)", fontSize: 15, marginLeft: 0 }}
            >
              ID:{props.item.id}&nbsp;|{props.item.employee}&nbsp;|&nbsp;
              {moment(props.item.date_created).format("L h:mm:ss a")}
              <br />
            </span>
            <span
              className="ml-4"
              style={{ color: "rgb(61,64,82)", fontSize: 15 }}
            >
              {props.item.data.status != 404 && (
                <Input
                  type="select"
                  onChange={(e) => filterPerson(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder=""
                >
                  <option hidden value="">
                    Filtrar informacion
                  </option>
                  {cambio == false && <option value="5">Mostrar Todo</option>}
                  <option value="1">datos</option>
                  {hola.data.experience != "" && (
                    <option value="2">experiencia</option>
                  )}
                  {hola.data.education != "" && (
                    <option value="3">educacion</option>
                  )}
                  {hola.data.profiles != "" && (
                    <option value="4">perfiles</option>
                  )}
                </Input>
              )}
            </span>
            <a href="/download">
              <i className="fas fa-file-download download-button"></i>
            </a>
          </ModalHeader>
          <ModalBody
            className={"content-modalbody-query"}
            style={{ padding: 28 }}
          >
            {props.item.data.status == 200 && (
              <Row className="content-row-modalquery">
                {datos && (
                  <div>
                    <Col md={12}>
                      <h4 className="modalDocumentTitle">{"Datos"}</h4>
                    </Col>

                    <Col md={12} style={{ width: 420 }}>
                      <p className="text-titulo-modal-huella">
                        Nombre Completo
                      </p>
                      <p
                        className="text-content-modal"
                        style={{ fontSize: 18 }}
                      >
                        <b>{hola.data.full_name}</b>
                      </p>
                    </Col>
                    <Col md={12} style={{ width: 420 }}>
                      <p className="text-titulo-modal-huella ">Género</p>
                      <p
                        className="text-content-modal"
                        style={{ fontSize: 18 }}
                      >
                        <b>{hola.data.gender}</b>
                      </p>
                    </Col>
                    {hola.data.birth_year && (
                      <Col md={12} style={{ width: 420 }}>
                        <p className="text-titulo-modal-huella">
                          año de nacimiento
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.birth_year}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.birth_date && (
                      <Col md={12} style={{ width: 420 }}>
                        <p className="text-titulo-modal-huella">cumpleaños</p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.birth_date}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.linkedin_url && (
                      <Col md={12} style={{ width: 420 }}>
                        <p className="text-titulo-modal-huella">
                          link de usuario de linkedin
                        </p>
                        <a
                          href={`https://${hola.data.linkedin_url}`}
                          className="text-content-modal links"
                          target="_blank"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.linkedin_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.linkedin_username && (
                      <Col md={12} style={{ width: 420 }}>
                        <p className="text-titulo-modal-huella">
                          nombre de usuario en linkedin
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.linkedin_username}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.facebook_url && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          link de usuario de facebook
                        </p>
                        <a
                          href={`https://${hola.data.facebook_url}`}
                          className="text-content-modal links"
                          target="_blank"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.facebook_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.facebook_username && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          nombre de usuario de facebook
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.facebook_username}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.twitter_url && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          link de usuario de twitter
                        </p>
                        <a
                          href={`https://${hola.data.twitter_url}`}
                          target="_blank"
                          className="text-content-modal links"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.twitter_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.twitter_username && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          nombre de usuario en twitter
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.twitter_username}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.github_url && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          link de usuario en github
                        </p>
                        <a
                          href={`https://${hola.data.github_url}`}
                          className="text-content-modal links"
                          target="_blank"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.github_url}
                        </a>
                      </Col>
                    )}

                    {hola.data.github_username && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          nombre de usuario en github
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.github_username}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.work_email && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          correo laboral
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.work_email}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.mobile_phone && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          número de telefono
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.mobile_phone}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.industry && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">industria </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.industry}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.job_title && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          titulos laborales
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.job_title}</b>
                        </p>
                      </Col>
                    )}

                    {hola.data.job_title_levels &&
                      hola.data.job_title_levels.map((item, i) => {
                        return (
                          <Col md={12}>
                            <p className="text-titulo-modal-huella">
                              titulos laborales {i + 1}
                            </p>
                            <p
                              className="text-content-modal"
                              style={{ fontSize: 18 }}
                            >
                              {item}
                            </p>
                          </Col>
                        );
                      })}
                    {hola.data.job_company_name && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          nombre de empresa
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.job_company_name}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.job_company_website && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          webside laboral
                        </p>
                        <a
                          href={`https://${hola.data.job_company_website}`}
                          className="text-content-modal links"
                          target="_blank"
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.job_company_website}</b>
                        </a>
                      </Col>
                    )}
                    {hola.data.job_company_industry && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">industria</p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.job_company_industry}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.job_company_linkedin_url && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          linkedin de la empresa
                        </p>
                        <a
                          href={`https://${hola.data.job_company_linkedin_url}`}
                          target="_blank"
                          className="text-content-modal links"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.job_company_linkedin_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.job_company_facebook_url && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          facebook de la empresa
                        </p>
                        <a
                          href={`https://${hola.data.job_company_facebook_url}`}
                          target="_blank"
                          className="text-content-modal links"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.job_company_facebook_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.job_company_twitter_url && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          twitter de la empresa
                        </p>
                        <a
                          href={`https://${hola.data.job_company_twitter_url}`}
                          target="_blank"
                          className="text-content-modal links"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.job_company_twitter_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.job_company_location_name && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          localización de la empresa
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.job_company_location_name}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.phone_numbers &&
                      hola.data.phone_numbers.map((item, i) => {
                        return (
                          <Col md={12}>
                            <p className="text-titulo-modal-huella">
                              número de celular {i + 1}
                            </p>
                            <p
                              className="text-content-modal"
                              style={{ fontSize: 18 }}
                            >
                              {item}
                            </p>
                          </Col>
                        );
                      })}

                    {hola.data.emails &&
                      hola.data.emails.map((item, i) => {
                        return (
                          <Col md={12}>
                            <p className="text-titulo-modal-huella">
                              Correo {i + 1}
                            </p>
                            <p
                              className="text-content-modal"
                              style={{ fontSize: 18 }}
                            >
                              {item.address}
                            </p>
                          </Col>
                        );
                      })}
                    {hola.data.interests != "" && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">itereses</p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.interests.map((item) => (
                            <p>{`-${item}\n`}</p>
                          ))}
                        </p>
                      </Col>
                    )}
                    {hola.data.skills != "" && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">habilidades</p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.skills.map((item) => (
                            <p>{`-${item}\n`}</p>
                          ))}
                        </p>
                      </Col>
                    )}
                  </div>
                )}
                {experience && (
                  <div style={{ width: "627px" }}>
                    {hola.data.experience != "" && (
                      <Col md={12}>
                        <p className="titulo-modal">
                          <b>experiencia</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.experience &&
                      hola.data.experience.map((item, i) => {
                        return (
                          <div style={{ width: "627px" }}>
                            {item.company["name"] && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  nombre de la compañia
                                </p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.company["name"]}
                                </p>
                              </Col>
                            )}
                            {item.company["industry"] && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  industria
                                </p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.company["industry"]}
                                </p>
                              </Col>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
                {educacion && (
                  <div>
                    {hola.data.education != "" && (
                      <Col md={12}>
                        <p className="titulo-modal">
                          <b>educacion</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.education &&
                      hola.data.education.map((item, i) => {
                        return (
                          <div style={{ width: "627px" }}>
                            {item.school["name"] && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  nombre de la escuela
                                </p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.school["name"]}
                                </p>
                              </Col>
                            )}
                            {item.school["website"] && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella ">
                                  webside de la escuela
                                </p>
                                <a
                                  href={`https://${item.school["website"]}`}
                                  target="_blank"
                                  className="text-content-modal links"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.school["website"]}
                                </a>
                              </Col>
                            )}
                            {item.start_date && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  inicio
                                </p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.start_date}
                                </p>
                              </Col>
                            )}
                            {item.end_date && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  termino
                                </p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.end_date}
                                </p>
                              </Col>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
                {perfiles && (
                  <div>
                    {hola.data.profiles != "" && (
                      <Col md={12}>
                        <p className="titulo-modal">
                          <b>perfiles</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.profiles &&
                      hola.data.profiles.map((item, i) => {
                        return (
                          <div style={{ width: "672px" }}>
                            {item.network && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">red</p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.network}
                                </p>
                              </Col>
                            )}
                            {item.username && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  nombre de usuario
                                </p>
                                <p
                                  className="text-content-modal "
                                  style={{ fontSize: 18 }}
                                >
                                  {item.username}
                                </p>
                              </Col>
                            )}
                            {item.url && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella margin-5">
                                  links del perfil
                                </p>
                                <a
                                  href={`https://${item.url}`}
                                  target="_blank"
                                  className="text-content-modal links"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.url}
                                </a>
                              </Col>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
              </Row>
            )}
            {props.item.data.status == 404 && (
              <div>
                <h4 className="text-center">
                  No se encontraron coincidencias para esta búsqueda
                </h4>
              </div>
            )}
          </ModalBody>
        </Modal>
      )}

      {props.item.response !== undefined && (
        <Modal isOpen={props.modal} backdrop={"static"}>
          <ModalHeader
            toggle={props.hideModal}
            style={{ backgroundColor: "rgb(213,213,215)" }}
          >
            {/* {`${props.item.name}`}julio alfredo bargas */}Infotmacion laboral
            <br />
            <span
              style={{ color: "rgb(61,64,82)", fontSize: 15, marginLeft: 0 }}
            >
              ID:{props.item.id}&nbsp;|{props.item.employee}&nbsp;|&nbsp;
              {moment(props.item.date_created).format("L h:mm:ss a")}
              <br />
            </span>
            <span
              className="ml-4"
              style={{ color: "rgb(61,64,82)", fontSize: 15 }}
            >
              {props.item.response.status != 404 && (
                <Input
                  type="select"
                  onChange={(e) => filterPerson(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder=""
                >
                  <option hidden value="">
                    Filtrar informacion
                  </option>
                  {cambio == false && <option value="5">Mostrar Todo</option>}
                  <option value="1">datos</option>
                  {hola.data.experience != "" && (
                    <option value="2">experiencia</option>
                  )}
                  {hola.data.education != "" && (
                    <option value="3">educacion</option>
                  )}
                  {hola.data.profiles != "" && (
                    <option value="4">perfiles</option>
                  )}
                </Input>
              )}
            </span>
            <a href="/download">
              <i className="fas fa-file-download download-button"></i>
            </a>
          </ModalHeader>
          <ModalBody
            className={"content-modalbody-query"}
            style={{ padding: 28 }}
          >
            {props.item.response.status == 200 && (
              <Row className="content-row-modalquery">
                {datos && (
                  <div>
                    <Col md={12}>
                      <h4 className="modalDocumentTitle">{"Datos"}</h4>
                    </Col>

                    <Col md={12} style={{ width: 420 }}>
                      <p className="text-titulo-modal-huella">
                        Nombre Completo
                      </p>
                      <p
                        className="text-content-modal"
                        style={{ fontSize: 18 }}
                      >
                        <b>{hola.data.full_name}</b>
                      </p>
                    </Col>
                    <Col md={12} style={{ width: 420 }}>
                      <p className="text-titulo-modal-huella">Género</p>
                      <p
                        className="text-content-modal"
                        style={{ fontSize: 18 }}
                      >
                        <b>{hola.data.gender}</b>
                      </p>
                    </Col>
                    {hola.data.birth_year && (
                      <Col md={12} style={{ width: 420 }}>
                        <p className="text-titulo-modal-huella">
                          año de nacimiento
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.birth_year}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.birth_date && (
                      <Col md={12} style={{ width: 420 }}>
                        <p className="text-titulo-modal-huella">cumpleaños</p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.birth_date}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.linkedin_url && (
                      <Col md={12} style={{ width: 420 }} style={{ marginBottom: "1rem" }}>
                        <p className="text-titulo-modal-huella margin-5">
                          link de usuario de linkedin
                        </p>
                        <a
                          href={`https://${hola.data.linkedin_url}`}
                          className="text-content-modal links"
                          target="_blank"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.linkedin_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.linkedin_username && (
                      <Col md={12} style={{ width: 420 }}>
                        <p className="text-titulo-modal-huella">
                          nombre de usuario en linkedin
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.linkedin_username}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.facebook_url && (
                      <Col md={12} style={{ marginBottom: "1rem" }}>
                        <p className="text-titulo-modal-huella margin-5">
                          link de usuario de facebook
                        </p>
                        <a
                          href={`https://${hola.data.facebook_url}`}
                          className="text-content-modal links"
                          target="_blank"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.facebook_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.facebook_username && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          nombre de usuario de facebook
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.facebook_username}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.twitter_url && (
                      <Col md={12} style={{ marginBottom: "1rem" }}>
                        <p className="text-titulo-modal-huella margin-5">
                          link de usuario de twitter
                        </p>
                        <a
                          href={`https://${hola.data.twitter_url}`}
                          target="_blank"
                          className="text-content-modal links"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.twitter_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.twitter_username && (
                      <Col md={12} >
                        <p className="text-titulo-modal-huella">
                          nombre de usuario en twitter
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.twitter_username}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.github_url && (
                      <Col md={12} style={{ marginBottom: "1rem" }}>
                        <p className="text-titulo-modal-huella margin-5">
                          link de usuario en github
                        </p>
                        <a
                          href={`https://${hola.data.github_url}`}
                          className="text-content-modal links"
                          target="_blank"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.github_url}
                        </a>
                      </Col>
                    )}

                    {hola.data.github_username && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          nombre de usuario en github
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.github_username}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.work_email && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          correo laboral
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.work_email}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.mobile_phone && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          número de telefono
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.mobile_phone}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.industry && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">industria </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.industry}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.job_title && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          titulos laborales
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.job_title}</b>
                        </p>
                      </Col>
                    )}

                    {hola.data.job_title_levels &&
                      hola.data.job_title_levels.map((item, i) => {
                        return (
                          <Col md={12}>
                            <p className="text-titulo-modal-huella">
                              titulos laborales {i + 1}
                            </p>
                            <p
                              className="text-content-modal"
                              style={{ fontSize: 18 }}
                            >
                              {item}
                            </p>
                          </Col>
                        );
                      })}
                    {hola.data.job_company_name && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          nombre de empresa
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.job_company_name}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.job_company_website && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          webside laboral{" "}
                        </p>
                        <a
                          href={`https://${hola.data.job_company_website}`}
                          className="text-content-modal links"
                          target="_blank"
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.job_company_website}</b>
                        </a>
                      </Col>
                    )}
                    {hola.data.job_company_industry && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">industria</p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.job_company_industry}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.job_company_linkedin_url && (
                      <Col md={12} style={{ marginBottom: "1rem" }}>
                        <p className="text-titulo-modal-huella margin-5">
                          linkedin de la empresa
                        </p>
                        <a
                          href={`https://${hola.data.job_company_linkedin_url}`}
                          target="_blank"
                          className="text-content-modal links"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.job_company_linkedin_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.job_company_facebook_url && (
                      <Col md={12} style={{ marginBottom: "1rem" }}>
                        <p className="text-titulo-modal-huella margin-5">
                          facebook de la empresa
                        </p>
                        <a
                          href={`https://${hola.data.job_company_facebook_url}`}
                          target="_blank"
                          className="text-content-modal links"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.job_company_facebook_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.job_company_twitter_url && (
                      <Col md={12} style={{ marginBottom: "1rem" }}>
                        <p className="text-titulo-modal-huella margin-5">
                          twitter de la empresa
                        </p>
                        <a
                          href={`https://${hola.data.job_company_twitter_url}`}
                          target="_blank"
                          className="text-content-modal links"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.job_company_twitter_url}
                        </a>
                      </Col>
                    )}
                    {hola.data.job_company_location_name && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">
                          localización de la empresa
                        </p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          <b>{hola.data.job_company_location_name}</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.phone_numbers &&
                      hola.data.phone_numbers.map((item, i) => {
                        return (
                          <Col md={12}>
                            <p className="text-titulo-modal-huella">
                              numero de celular {i + 1}
                            </p>
                            <p
                              className="text-content-modal"
                              style={{ fontSize: 18 }}
                            >
                              {item}
                            </p>
                          </Col>
                        );
                      })}

                    {hola.data.emails &&
                      hola.data.emails.map((item, i) => {
                        return (
                          <Col md={12}>
                            <p className="text-titulo-modal-huella">
                              Correo {i + 1}
                            </p>
                            <p
                              className="text-content-modal"
                              style={{ fontSize: 18 }}
                            >
                              {item.address}
                            </p>
                          </Col>
                        );
                      })}
                    {hola.data.interests != "" && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">itereses</p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.interests.map((item) => (
                            <p>{`-${item}\n`}</p>
                          ))}
                        </p>
                      </Col>
                    )}
                    {hola.data.skills != "" && (
                      <Col md={12}>
                        <p className="text-titulo-modal-huella">habilidades</p>
                        <p
                          className="text-content-modal"
                          style={{ fontSize: 18 }}
                        >
                          {hola.data.skills.map((item) => (
                            <p>{`-${item}\n`}</p>
                          ))}
                        </p>
                      </Col>
                    )}
                  </div>
                )}
                {experience && (
                  <div style={{ width: "627px" }}>
                    {hola.data.experience != "" && (
                      <Col md={12}>
                        <p className="titulo-modal">
                          <b>experiencia</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.experience &&
                      hola.data.experience.map((item, i) => {
                        return (
                          <div style={{ width: "627px" }}>
                            {item.company["name"] && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  nombre de la compañia{" "}
                                </p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.company["name"]}
                                </p>
                              </Col>
                            )}
                            {item.company["industry"] && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  industria
                                </p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.company["industry"]}
                                </p>
                              </Col>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
                {educacion && (
                  <div style={{ width: "627px" }}>
                    {hola.data.education != "" && (
                      <Col md={12}>
                        <p className="titulo-modal">
                          <b>educacion</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.education &&
                      hola.data.education.map((item, i) => {
                        return (
                          <div style={{ width: "627px" }}>
                            {item.school["name"] && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  nombre de la escuela{" "}
                                </p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.school["name"]}
                                </p>
                              </Col>
                            )}
                            {item.school["website"] && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  webside de la escuela
                                </p>
                                <a
                                  href={`https://${item.school["website"]}`}
                                  target="_blank"
                                  className="text-content-modal links"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.school["website"]}
                                </a>
                              </Col>
                            )}
                            {item.start_date && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  inicio
                                </p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.start_date}
                                </p>
                              </Col>
                            )}
                            {item.end_date && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">
                                  termino
                                </p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.end_date}
                                </p>
                              </Col>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}

                {perfiles && (
                  <div style={{ width: "627px" }}>
                    {hola.data.profiles != "" && (
                      <Col md={12}>
                        <p className="titulo-modal">
                          <b>perfiles</b>
                        </p>
                      </Col>
                    )}
                    {hola.data.profiles &&
                      hola.data.profiles.map((item, i) => {
                        return (
                          <div style={{ width: "672px" }}>
                            {item.network && (
                              <Col md={12}>
                                <p className="text-titulo-modal-huella">red</p>
                                <p
                                  className="text-content-modal"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.network}
                                </p>
                              </Col>
                            )}
                            {item.username && (
                              <Col key={i} md={12}>
                                <p className="text-titulo-modal-huella">
                                  nombre de usuario
                                </p>
                                <p
                                  className="text-content-modal "
                                  style={{ fontSize: 18 }}
                                >
                                  {item.username}
                                </p>
                              </Col>
                            )}
                            {item.url && (
                              <Col md={12} style={{ marginBottom: "1rem" }}>
                                <p className="text-titulo-modal-huella margin-5">
                                  links del perfil
                                </p>
                                <a
                                  href={`https://${item.url}`}
                                  target="_blank"
                                  className="text-content-modal links"
                                  style={{ fontSize: 18 }}
                                >
                                  {item.url}
                                </a>
                              </Col>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
              </Row>
            )}
            {props.item.response.status == 404 && (
              <div>
                <h4 className="text-center">
                  No se encontraron coincidencias para esta búsqueda
                </h4>
              </div>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
const ModalHuellaDigital2Loading = (props) => {
  return (
    <>
      <Modal isOpen={props.modal} backdrop={"static"} className="modal-loading">
        <img
          style={{ margin: "0 auto" }}
          src="/assets/loadingIcon.gif"
          alt="img loading"
          width="50"
          height="50"
        />
      </Modal>
    </>
  );
};

export { ModalHuellaDigital, ModalHuellaDigital2Loading };
