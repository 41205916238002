import React, { useState, useEffect, useContext } from "react";

import { Col, Row, Button, Form, FormGroup, Input, Table } from "reactstrap";
import ConsultaHuella_digital from "../consultas/huella_digital";
import {
  QuerySarlaftAsync,
  GetTypeQuery,
  GetLogsQuery,
  GetFilterQuery,
  DownloadPDF,
  getIP,
  DownloadPDFHuella,
} from "../managers/ApiManager";
import Fraud from "../consultas/Fraud"

import { Score } from "../download/Score"

import { IdentidadPDF } from "../download/identidadPDF"

import { BinPDF } from "../download/BinPDF"

import { IpPDF } from "../download/IpPDF"

import { Huella2PDF } from "../download/huella2PDF"

import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

import { ModalHuella } from "../consultas/ModalHuella";

import ConsultaInformacion from "../consultas/informacionLaboral";

import ConsultaImage from "../consultas/imagenPerfil";

import ConsultaBin from "../consultas/bin";

import ConsultaScore from "../consultas/scoreRiesgo";

import ConsultaIP from "../consultas/ip";

import ConsultaIdentidad from "../consultas/identidad";

import { HuellaPDF } from "../download/huellaPDF"

import { FunctionContext } from "../managers/FuntionContext";
import {
  AlertSwal,
  ResponsePDF,
  FormatDocument,
  TitleCase,
  FormatPhone,
} from "../managers/HelperManager";

import { useGlobal } from "../managers/store/Context";
import NavComponent from "./Nav";
import moment from "moment";

import swal from "sweetalert";
import { useHistory, Link, Route, Switch, Redirect } from "react-router-dom";
import { data } from "jquery";

export default function QueryComponent(props) {
  /*     let history = useHistory(); */
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getTypeQuery, setTypeQuery] = useState([]);
  const [getLogsQuery, setLogsQuery] = useState(props.getLogsQuery);
  const [getBtnText, setBtnText] = useState("Consultar");

  const [show, setShow] = useState(false);


  const [dataDescarga, setDataDescarga] = useState(null);
  const [, dispatch] = useGlobal();
  const { Balance, funGetBalance } = useContext(FunctionContext);
  const [idTypeQuery, setIdTypeQuery] = useState(
    window.location.pathname == "/consultar/la_ft"
      ? "1"
      : window.location.pathname == "/consultar/huella_digital"
        ? "3"
        : window.location.pathname == "/consultar/identidad"
          ? "4"
          : window.location.pathname == "/consultar/validacion_IP"
            ? "5"
            : window.location.pathname == "/consultar/validacion_BIN"
              ? "6"
              : window.location.pathname == "/consultar/score_de_riesgo"
                ? "7"
                : window.location.pathname == "/consultar/imagen_perfil"
                  ? "8"
                  : window.location.pathname == "/consultar/informacion_laboral"
                    ? "9"
                    : window.location.pathname == "/consultar/fraude"
                      ? "10"
                      : "0"
  );

  const [DataID, setDataID] = useState(0);

  const [render, setRender] = useState(false);

  var history = useHistory();

  const [ipUser, setIpUser] = useState("");
  const [RenderPdf, setRenderPdf] = useState(false);
  useEffect(() => {
    renderIP();
    funTypeQuery();
  }, []);
  useEffect(() => {
    funLogsQuery()
  }, []);

  useEffect(() => {


    setShow(false)
    setRenderPdf(false)
    setDataID(0)
  }, [RenderPdf == true]);





  const renderIP = async () => {
    let ip = await getIP();
    setIpUser(ip);
  };

  const tipoConsulta = async (x) => {
    if (x == 3) {
      var sumaHuellaLogs = 0;
      getLogsQuery.map((item, i) => {
        if (item.type_query == "Huella digital") {
          sumaHuellaLogs++;
        }
      });

      if (sumaHuellaLogs == 0) {
        AlertSwal("MENSAJE_SELECT_HUELLA");
      }
    }
    if (x == 1) {
      history.push("/consultar/la_ft");
    } else if (x == "3") {
      history.push("/consultar/huella_digital");
    } else if (x == "4") {
      history.push("/consultar/identidad");
    } else if (x == "5") {
      history.push("/consultar/validacion_IP");
    } else if (x == "6") {
      history.push("/consultar/validacion_BIN");
    } else if (x == "7") {
      history.push("/consultar/score_de_riesgo");
    } else if (x == "8") {
      history.push("/consultar/imagen_perfil");
    } else if (x == "9") {
      history.push("/consultar/informacion_laboral");
    } else if (x == "10") {
      history.push("/consultar/fraude");
    }


    setIdTypeQuery(x);
  };

  // get list balance
  const funTypeQuery = async () => {
    let resTypeQuery = await GetTypeQuery();
    if (resTypeQuery) {
      setTypeQuery(resTypeQuery.data);
    }
  };

  const funLogsQuery = async () => {
    let resLogsQuery = await GetLogsQuery();
    const newData = resLogsQuery.results;
    let vuelta = resLogsQuery.results.map((item, index) => {


      if (item.response_log === "" || item.response_log === "User not found") {
        var response = item.request_log === "" ? "" : "User not found"

      }
      else {


        var response = (item.response_log)

      }
      if (item.request_log === "" || item.request_log === "User not found") {

        var request = item.request_log === "" ? "" : "User not found"
      }
      else {

        var request = (item.request_log)
      }

      return { response: response, id: index, request: request }

    })
    var final = vuelta.map((item, index) => {
      const nuevoObjeto = {
        ...newData[index], // Copiar las propiedades existentes del objeto original
        response: item.response,
        request: item.request // Agregar la propiedad "response" con el valor de la respuesta
      };


      return nuevoObjeto
    })

    setLoading(false);
    setLogsQuery(final);
    setRender(true);
  };

  // check sarlaft information
  const funHandleQuery = async (e) => {
    e.preventDefault();
    setBtnText("Consultando...");
    let { document_number, first_name, last_name, type_query_id } = e.target,
      res = await QuerySarlaftAsync({
        document_number: document_number.value.toLowerCase(),
        first_name: first_name.value.toLowerCase(),
        last_name: last_name.value.toLowerCase(),
        type_query_id: type_query_id.value.toLowerCase(),
      });
    funDispatchQuery(res);
    setBtnText("Consultar");


    funGetBalance();
  };

  // see query button
  const funSeeQuery = async (e, id) => {
    e.preventDefault();
    props.funToggleModalLoading(true);
    let res = await GetFilterQuery(id);

    if (res.data[0].type_query == "Huella digital") {
      props.modalHuellaItem(res.data[0].response);
      props.funToggleModalLoading(false);
      props.funTogglemodalHuella(true);
    } else if (res.data[0].type_query == "Identidad") {
      props.modalIdentidadItem(res.data[0].response);
      props.funToggleModalLoading(false);
      props.funTogglemodalIdentidad(true);
    } else if (res.data[0].type_query == "Validación IP") {
      props.itemIP(res.data[0]);
      props.funToggleModalLoading(false);
      props.modalIP(true);
    } else if (res.data[0].type_query == "Validación BIN") {
      props.itemBIN(res.data[0]);
      props.modalBIN(true);
      props.funToggleModalLoading(false);
    } else if (res.data[0].type_query == "Score de riesgo") {
      props.itemScore(res.data[0]);
      props.funToggleModalLoading(false);
      props.modalScore(true);
    } else if (res.data[0].type_query == "Imagen perfil") {
      props.itemimage(res.data[0]);
      props.funToggleModalLoading(false);
      props.modalimage(true);
    } else if (res.data[0].type_query == "Informacion laboral") {
      props.huella2Item(res.data[0]);
      props.funToggleModalLoading(false);
      props.huella2(true);
    } else {
      let res = await GetFilterQuery(id);

      funDispatchQuery(res);
      props.funToggleModalLoading(false);
    }
  };

  // download query button
  const funDownloadQuery = async (e, query) => {
    e.preventDefault();


    if (query.type_query == "Huella digital") {
      let res = await GetFilterQuery(query.id)
      setDataDescarga(res.data[0].response)




      /*    ResponsePDF(await DownloadPDFHuella(query.id)); */
    } /* else if (res.data[0].type_query == "Identidad") {
      ResponsePDF(await DownloadPDF(id));
    } else if (res.data[0].type_query == "Validación IP") {
      ResponsePDF(await DownloadPDF(id));
    } else if (res.data[0].type_query == "Validación BIN") {
      ResponsePDF(await DownloadPDF(id));
    } else if (res.data[0].type_query == "Score de riesgo") {
      ResponsePDF(await DownloadPDF(id));
    } else if (res.data[0].type_query == "Imagen perfil") {
      ResponsePDF(await DownloadPDF(id));
    } else if (res.data[0].type_query == "Informacion laboral") {
      ResponsePDF(await DownloadPDF(id));
    } */ else {
      ResponsePDF(await DownloadPDF(query.id));
    }
  };

  // validate data query
  const funDispatchQuery = (res) => {
    if (!res.error) {
      dispatch({
        type: "DATA_QUERY_MODAL",
        dataQuery: res,
        company: props.resProfile.company,
      });
      props.funToggleModalQuery(true);
    } else if (res.message == "NOT_ENOUGH_BALANCE") {
      swal({
        title: "No tienes saldo disponible para realizar esta consulta",
        text: "¿Deseas Recargar? ",
        icon: "info",
        buttons: {
          cancel: "No",
          confirm: "Si",
        },
      }).then((willDelete) => {
        if (willDelete) {
          var itemMenu = "reload";
          dispatch({
            type: "SELECTED_ITEM_MENU",
            itemMenu,
          });
        } else {
        }
      });
    } else {
      AlertSwal(res.message);
    }
  };
  const descargaPdf = (id) => {

    setShow(true)
    setDataID(id)

  }


  const funRenderSendQuery = () => {
    return (
      <>
        <Route path="/consultar">
          {idTypeQuery == 0 && (
            <ConsultaIdentidad
              funLogsQuery={() => funLogsQuery()}
              idTypeQuery={idTypeQuery}
            />
          )}
        </Route>

        <Route path="/consultar/la_ft">
          {idTypeQuery == 1 && (
            <div className="content-jumbotron basket animated fadeIn mt-2 mb-2">
              <Form onSubmit={(e) => funHandleQuery(e)}>
                <Row form>
                  <Col md={3}>
                    <FormGroup>
                      <Input
                        type="select"
                        name="type_query_id"
                        defaultValue="1"
                        onChange={(e) => tipoConsulta(e.target.value)}
                        required
                      >
                        <option hidden disabled={true} value="">
                          Tipo de consulta
                        </option>
                        <option value="1"> LA / FT </option>
                        {/*                         <option value="3"> Huella digital </option>
 */}                        <option value="4"> Identidad </option>
                        <option value="5"> Validación IP </option>
                        <option value="6"> Validación BIN </option>
                        <option value="7"> Score de riesgo </option>
                        <option value="8"> Imagen perfil </option>
                        <option value="9"> Informacion laboral </option>
                        <option value="10"> Fraude </option>

                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="first_name"
                        placeholder="Nombres"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="last_name"
                        placeholder="Apellidos"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Input
                        type="number"
                        name="document_number"
                        placeholder="Cédula"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Button
                        className="btn-green"
                        disabled={getBtnText === "Consultando..."}
                        block
                      >
                        {getBtnText}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </Route>

        <Route path="/consultar/huella_digital">
          <ConsultaHuella_digital
            funLogsQuery={() => funLogsQuery()}
            idTypeQuery={idTypeQuery}
          />
        </Route>

        <Route path="/consultar/identidad">
          {idTypeQuery == 4 && (
            <ConsultaIdentidad
              funLogsQuery={() => funLogsQuery()}
              idTypeQuery={idTypeQuery}
            />
          )}
        </Route>


        <Route path="/consultar/validacion_IP">
          {idTypeQuery == 5 && (
            <ConsultaIP
              ipUser={ipUser}
              funLogsQuery={() => funLogsQuery()}
              idTypeQuery={idTypeQuery}
            />
          )}
        </Route>
        <Route path="/consultar/validacion_BIN">
          {idTypeQuery == 6 && (
            <ConsultaBin
              funLogsQuery={() => funLogsQuery()}
              idTypeQuery={idTypeQuery}
            />
          )}
        </Route>

        <Route path="/consultar/score_de_riesgo">
          {idTypeQuery == 7 && (
            <ConsultaScore
              funLogsQuery={() => funLogsQuery()}
              idTypeQuery={idTypeQuery}
            />
          )}
        </Route>

        <Route path="/consultar/imagen_perfil">
          {idTypeQuery == 8 && (
            <ConsultaImage
              funLogsQuery={() => funLogsQuery()}
              idTypeQuery={idTypeQuery}
            />
          )}
        </Route>
        <Route path="/consultar/informacion_laboral">
          {idTypeQuery == 9 && (
            <ConsultaInformacion
              funLogsQuery={() => funLogsQuery()}
              idTypeQuery={idTypeQuery}
            />
          )}
        </Route>
        <Route path="/consultar/fraude">

          {idTypeQuery == 10 && (
            <Fraud
              funLogsQuery={() => funLogsQuery()}
              idTypeQuery={idTypeQuery}
            />
          )}
        </Route>
      </>
    );
  };

  const funRenderListQuery = () => {
    return (
      <>
        <div className="scrolltable ">
          <Table borderless hover className="text-white custom-table">
            <thead>
              <tr>
                <th> ID </th>
                <th> VER </th>
                <th > DESCARGAR </th>
                <th> </th>
                <th> </th>
                <th> FECHA DE CONSULTA </th>
                <th> TIPO DE CONSULTA </th>
              </tr>
            </thead>
            <tbody>

              {getLogsQuery.map((item, i) => {

                return (
                  <tr key={i}>
                    <td> {item.id} </td>
                    <td>
                      <a
                        href="/seequery"
                        onClick={(e) => funSeeQuery(e, item.id)}
                      >
                        <i className="fas fa-eye"> </i>
                      </a>
                    </td>
                    <td>
                      {item.type_query == "LA/FT" && <a
                        href="/download"
                        onClick={(e) => funDownloadQuery(e, item)}
                      >

                        <i className="fas fa-file-download"> </i>
                      </a>}
                      {item.type_query === "Huella digital" &&
                        <div >

                          {item.id !== DataID && <Button className="btn-descarga" onClick={() => descargaPdf(item.id)}>generar pdf</Button>}
                          {show && item.id === DataID &&

                            < PDFDownloadLink document={<HuellaPDF id={DataID} show={show} />} fileName="HuellaDigital.pdf" >
                              {({ blob, url, loading, error }) =>
                                loading ? "generando..." : <i onClick={() => setRenderPdf(true)} className="centrar-text fas fa-file-download "> </i>
                              }
                            </PDFDownloadLink>

                          }
                        </div>

                      }
                      {item.type_query === "Identidad" &&
                        <div >

                          {item.id !== DataID && <Button className="btn-descarga" onClick={() => descargaPdf(item.id)}>generar pdf</Button>}
                          {show && item.id === DataID &&

                            < PDFDownloadLink document={<IdentidadPDF id={DataID} show={show} />} fileName="Identidad.pdf" >
                              {({ blob, url, loading, error }) =>
                                loading ? "generando..." : <i onClick={() => setRenderPdf(true)} className="centrar-text fas fa-file-download "> </i>
                              }
                            </PDFDownloadLink>

                          }
                        </div>

                      }
                      {item.type_query === "Validación BIN" &&
                        <div >

                          {item.id !== DataID && <Button className="btn-descarga" onClick={() => descargaPdf(item.id)}>generar pdf</Button>}
                          {show && item.id === DataID &&

                            < PDFDownloadLink document={<BinPDF id={DataID} show={show} />} fileName="Bin.pdf" >
                              {({ blob, url, loading, error }) =>
                                loading ? "generando..." : <i onClick={() => setRenderPdf(true)} className="centrar-text fas fa-file-download "> </i>
                              }
                            </PDFDownloadLink>

                          }
                        </div>

                      }
                      {item.type_query === "Servicio de Fraude" &&
                        <div >

                          {item.id !== DataID && <Button className="btn-descarga" onClick={() => descargaPdf(item.id)}>generar pdf</Button>}
                          {show && item.id === DataID &&

                            < PDFDownloadLink document={<BinPDF id={DataID} show={show} />} fileName="Bin.pdf" >
                              {({ blob, url, loading, error }) =>
                                loading ? "generando..." : <i onClick={() => setRenderPdf(true)} className="centrar-text fas fa-file-download "> </i>
                              }
                            </PDFDownloadLink>

                          }
                        </div>

                      }
                      {item.type_query === "Validación IP" &&
                        <div >

                          {item.id !== DataID && <Button className="btn-descarga" onClick={() => descargaPdf(item.id)}>generar pdf</Button>}
                          {show && item.id === DataID &&

                            < PDFDownloadLink document={<IpPDF id={DataID} show={show} />} fileName="IP.pdf" >
                              {({ blob, url, loading, error }) =>
                                loading ? "generando..." : <i onClick={() => setRenderPdf(true)} className="centrar-text fas fa-file-download "> </i>
                              }
                            </PDFDownloadLink>

                          }
                        </div>

                      }
                      {item.type_query === "Score de riesgo" &&
                        <div >

                          {item.id !== DataID && <Button className="btn-descarga" onClick={() => descargaPdf(item.id)}>generar pdf</Button>}
                          {show && item.id === DataID &&

                            < PDFDownloadLink document={<Score id={DataID} show={show} />} fileName="Score_de_riesgo.pdf" >
                              {({ blob, url, loading, error }) =>
                                loading ? "generando..." : <i onClick={() => setRenderPdf(true)} className="centrar-text fas fa-file-download "> </i>
                              }
                            </PDFDownloadLink>

                          }
                        </div>

                      }
                      {item.type_query === "Informacion laboral" &&
                        <div >

                          {item.id !== DataID && <Button className="btn-descarga" onClick={() => descargaPdf(item.id)}>generar pdf</Button>}
                          {show && item.id === DataID &&

                            < PDFDownloadLink document={<Huella2PDF id={DataID} show={show} />} fileName="infomacion_laboral.pdf" >
                              {({ blob, url, loading, error }) =>
                                loading ? "generando..." : <i onClick={() => setRenderPdf(true)} className="centrar-text fas fa-file-download "> </i>
                              }
                            </PDFDownloadLink>

                          }
                        </div>

                      }





                    </td>

                    {
                      item.type_query == "LA/FT" && (
                        <td> {`${item.request_log?.name != undefined ? item.request_log?.name : ""} ${item.request_log?.last_name != undefined ? item.request_log?.last_name : ""}`} </td>
                      )
                    }
                    {
                      item.type_query == "Servicio de Fraude" && (
                        <td> {`${item.response?.data?.fraud_score !== undefined ? `Score Fraude: ${item.response.data.fraud_score}` :
                          item.response?.data?.score !== undefined ? `Score Fraude: ${item.response.data.score}` : ""}`} </td>
                      )
                    }
                    {
                      item.type_query == "Servicio de Fraude" && (
                        <td> {`${item.request.email !== null ? item.request.email :
                          item.request.ip !== null ? item.request.ip :
                            item.request.phone !== null ? item.request.phone :
                              ""}`} </td>
                      )
                    }
                    {
                      item.type_query == "LA/FT" && (
                        <td>
                          {FormatDocument(item.request_log?.document)}
                        </td>
                      )
                    }
                    {
                      item.type_query == "Identidad" && (
                        <td>
                          {`${item.request.first_name != false
                            ? item.request.first_name
                            : TitleCase(
                              item.response.name != ""
                                ? item.response.name
                                : "No encontrado"
                            )
                            } ${item.request.last_name != false &&
                              item.request.first_name != false
                              ? item.request.last_name
                              : ""
                            }`}
                        </td>
                      )
                    }
                    {
                      item.type_query == "Identidad" && (
                        <td> {FormatDocument(item.request.document)} </td>
                      )
                    }
                    {
                      item.type_query == "Huella digital" && (
                        <td>
                          {item.request.full_name != null
                            ? item.request.full_name
                            : "No Encontrado"}
                        </td>
                      )
                    }

                    {
                      item.type_query == "Huella digital" && (
                        <td
                          style={{
                            textTransform: "lowercase",
                          }}
                        >
                          {item.request.email == null
                            ? FormatPhone(item.request.phone)
                            : item.request.email}
                        </td>
                      )
                    }
                    {
                      item.type_query == null && (
                        <td> {`${item.name} ${item.last_name}`} </td>
                      )
                    }
                    {
                      item.type_query == null && (
                        <td>
                          {FormatDocument(item.document_number)}
                        </td>
                      )
                    }
                    {
                      item.type_query == "Validación IP" && (
                        <td> {item.response.org} </td>
                      )
                    }
                    {
                      item.type_query == "Validación IP" && (
                        <td> {item.request.ip} </td>
                      )
                    }
                    {
                      item.type_query == "Validación BIN" && (
                        <td className="capi">
                          {item.response.card == "" ||
                            item.response.card == undefined
                            ? TitleCase(item.employee)
                            : TitleCase(item.response.card)}
                        </td>
                      )
                    }
                    {
                      item.type_query == "Validación BIN" && (
                        <td> {item.request.BIN} </td>
                      )
                    }
                    {
                      item.type_query == "Score de riesgo" && (
                        <td className="capi">

                          {item.response.suspicious == true
                            ? "sospechoso: Si"
                            : item.response.reputation == "low" ? "reputacion: baja" : item.response.reputation == "medium" ? "reputacion: mediana" : item.response.reputation == "high" ? "reputacion: alta" : item.response.reputation == "none" ? "reputacion: nada" : ""}
                        </td>
                      )
                    }
                    {
                      item.type_query == "Score de riesgo" && (
                        <td
                          style={{
                            textTransform: "lowercase",
                          }}
                        >
                          {item.request.email}
                        </td>
                      )
                    }
                    {
                      item.type_query == "Imagen perfil" && (
                        <td className="capi">
                          {item.response == "User not found" ? (
                            "imagen no encontrada"
                          ) : (
                            <a
                              className="link-a"
                              href={`${item.response.entry[0].photos[0].value}?s=1200`}
                              target="_blank"
                            >
                              Imagen
                            </a>
                          )}
                        </td>
                      )
                    }
                    {
                      item.type_query == "Imagen perfil" && (
                        <td
                          style={{
                            textTransform: "lowercase",
                          }}
                        >
                          {item.request.email}
                        </td>
                      )
                    }
                    {
                      item.type_query == "Informacion laboral" && (
                        <td>
                          {item.response.data != undefined
                            ? item.response.data.full_name
                            : "no encontrado"}
                        </td>
                      )
                    }
                    {
                      item.type_query == "Informacion laboral" && (
                        <td
                          style={{
                            textTransform: "lowercase",
                          }}
                        >
                          {item.request.email != ""
                            ? item.request.email
                            : item.request.name != ""
                              ? item.request.name
                              : `+${item.request.phone}`}
                        </td>
                      )
                    }
                    <td> {moment(item.date_created).fromNow()} </td>
                    <td> {item.type_query} </td>
                    {/* <td>
                                                                            <Badge className="badge-percent" style={{ backgroundColor: funBadgeColor(item.percent) }}> </Badge>
                                                                            &nbsp; {Math.round(item.percent)}
                                                                        </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {loading === true && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/assets/loadingIcon.gif"
                alt="img loading"
                width="40"
                height="40"
              />
            </div>
          )}
          {message === true && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 200,
              }}
            >
              <h2
                style={{
                  fontSize: "bold",
                  color: "white",
                }}
              >
                No se ha realizado ninguna consulta
              </h2>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <NavComponent
        title="Consultar"
        name={props.resProfile.name}
        photo={props.resProfile.photo}
      />
      {funRenderSendQuery()} <br />
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }} children={<Huella2PDF />}></PDFViewer> */}
      {/* <Nav className="nav-content-items">
                            <NavItem className="active-menu-scroll ">
                                <NavLink href="#" >Todos</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#">Validados</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#">Fallidos</NavLink>
                            </NavItem>
                        </Nav> */}
      <br /> {funRenderListQuery()}
    </>
  );
}
