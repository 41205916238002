import React, { useState, useEffect } from 'react';
// import { businessConstant } from '../config';
import {
    // FormatCreditCard,
    // FormatExpirationDate,
    // FormatDocument,
    // FormatPhoneNumber,
    FormatCOPNumber,
    GetDigits,
    AlertSwal
} from '../managers/HelperManager';
import { useGlobal } from '../managers/store/Context';
import { ReloadAsync, GetBusinessConstant } from '../managers/ApiManager';
import NavComponent from './Nav';
import {
    Col, Card, CardHeader, CardBody, CardFooter, Button, Input, Form, Row
} from 'reactstrap';
import { Link, Redirect, useLocation } from 'react-router-dom';
export default function ReloadComponent(props) {

    const [state, dispatch] = useGlobal();
    const [getAmount, setAmount] = useState("$0");
    const [getPreviewCobru, setPreviewCobru] = useState("");
    const [getMinValue, setMinValue] = useState(10000);



    useEffect(() => {
        funGetBusinessConstant();
    }, [])

    const funGetBusinessConstant = async () => {
        let resBusinessConstant = await GetBusinessConstant();
        if (resBusinessConstant) {
            resBusinessConstant.forEach(item => {
                if (item.id === 7) {
                    setMinValue(item.int_value)
                }
            })
        }
    }
    const redirigirCobru = (data) => {
        if (data != "") {
            window.location.href = `https://prod.cobru.co/${data}`;
        }

    }

    // const [getBtnText, setBtnText] = useState("");
    // const [getListBanks] = useState([]);
    // const [getListPaymentMethods] = useState([]);
    // const [getSelectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
    // const [getProyectCode, setProyectCode] = useState(0);
    // const [getPinCode, setPinCode] = useState(0);


    // useEffect(() => {
    //     funGetListBanks();
    //     funGetListPaymentMethods();
    // }, [])



    // const funGetListBanks = async () => {
    //     let resListBanks = await GetListBanks();
    //     if (resListBanks) setListBanks(resListBanks.data);
    // }

    // const funGetListPaymentMethods = async () => {
    //     let resListPaymentMethods = await GetListPaymentMethods();
    //     if (resListPaymentMethods) setListPaymentMethods(resListPaymentMethods);

    // }


    // select method avaliable
    // const funSeletedPaymentMethod = (e, item) => {
    //     e.preventDefault();
    //     setSelectedPaymentMethod(item.id);
    //     setBtnText(item.name);
    //     setProyectCode(0);
    //     setPinCode(0);
    // }

    // return max and min value object data
    // const funMinMaxValue = () => {
    //     let businessConstantObj = {};
    //     if (getSelectedPaymentMethod === businessConstant.CONSTANT_BALOTO) {
    //         businessConstantObj = businessConstant.BALOTO.MIN_MAX_VALUE;
    //     } else if (getSelectedPaymentMethod === businessConstant.CONSTANT_EFECTY) {
    //         businessConstantObj = businessConstant.EFECTY.MIN_MAX_VALUE;
    //     } else if (getSelectedPaymentMethod === businessConstant.CONSTANT_CREDIT_CARD) {
    //         businessConstantObj = businessConstant.CREDIT_CART.MIN_MAX_VALUE;
    //     } else if (getSelectedPaymentMethod === businessConstant.CONSTANT_PSE) {
    //         businessConstantObj = businessConstant.PSE.MIN_MAX_VALUE;
    //     }
    //     return businessConstantObj;
    // }

    // go to recharge money
    // const funRecharge = async e => {
    //     e.preventDefault();
    //     let number = GetDigits(getAmount, true);
    //     if (getSelectedPaymentMethod <= 0) {
    //         return AlertSwal("SELECT_PAYMENT_METHOD");
    //     }
    //     if (number > funMinMaxValue().max) {
    //         number = funMinMaxValue().max.toString();
    //         return AlertSwal("MIN_MAX_VALUE", `El monto maximo es ${FormatCOPNumber(number)}`);
    //     } else if (number < funMinMaxValue().min || number === 0) {
    //         return AlertSwal("MIN_MAX_VALUE", `El monto minimo es ${FormatCOPNumber(funMinMaxValue().min)}`);
    //     }
    //     props.funToggleModalLoading(true);
    //     let { cc, type_document, bank_id, name_tc, credit_card, expiration_date, cvv, dues, phone, email } = e.target,
    //         res = await ReloadAsync({
    //             amount: GetDigits(getAmount, true),
    //             cc: GetDigits(cc.value),
    //             type_document: type_document.value,
    //             type_payment: getSelectedPaymentMethod,
    //             bank_id: bank_id ? bank_id.value : 0,
    //             name_tc: name_tc.value,
    //             credit_card: credit_card ? GetDigits(credit_card.value) : "",
    //             expiration_date: expiration_date ? expiration_date.value : "",
    //             cvv: cvv ? cvv.value : "",
    //             dues: dues ? dues.value : "",
    //             phone: GetDigits(phone.value),
    //             email: email.value
    //         });
    //     if (!res.error) {
    //         if (getSelectedPaymentMethod === businessConstant.CONSTANT_PSE) {
    //             window.open(res.data.url, '_blank');
    //         } else if (getSelectedPaymentMethod === businessConstant.CONSTANT_BALOTO || getSelectedPaymentMethod === businessConstant.CONSTANT_EFECTY) {
    //             setProyectCode(res.data.project);
    //             setPinCode(res.data.ref);
    //         } else if (getSelectedPaymentMethod === businessConstant.CONSTANT_CREDIT_CARD) {
    //             console.log(res);
    //             AlertSwal(res.data.pago === 1 ? "RELOAD_SUCCESS" : "RELOAD_REJECT");
    //         }
    //         props.funGetBalance();
    //     } else {
    //         AlertSwal(res.message)
    //     }
    //     props.funToggleModalLoading(false);
    // }


    // render to form reload
    // const funFormContent = () => {
    //     return (
    //         <>
    //             <Form onSubmit={e => funRecharge(e)}>
    //                 <Card>
    //                     <CardBody style={{ padding: '4% 4% 0% 4%' }}>
    //                         <Row form>

    //                             {getSelectedPaymentMethod === businessConstant.CONSTANT_CREDIT_CARD &&
    //                                 <Col md={12}>
    //                                     <FormGroup>
    //                                         <Input type="tel" name="credit_card" placeholder="Número de la tarjeta" pattern="[0-9]*" autoComplete="on" autoCorrect="off" spellCheck="true" autoCapitalize="off" maxLength="20"
    //                                         // onChange={e => e.target.value = FormatCreditCard(e.target.value)}
    //                                         />
    //                                     </FormGroup>
    //                                 </Col>
    //                             }
    //                             {getSelectedPaymentMethod === businessConstant.CONSTANT_CREDIT_CARD &&
    //                                 <Col md={4}>
    //                                     <FormGroup>
    //                                         <Input type="tel" name="expiration_date" autoComplete="of" autoCorrect="off" spellCheck="true" autoCapitalize="off" placeholder="MM/AA" required maxLength="5"
    //                                             onChange={e => e.target.value = FormatExpirationDate(e.target.value)}
    //                                         />
    //                                     </FormGroup>
    //                                 </Col>
    //                             }
    //                             {getSelectedPaymentMethod === businessConstant.CONSTANT_CREDIT_CARD &&
    //                                 <Col md={4}>
    //                                     <FormGroup>
    //                                         <Input type="tel" name="cvv" pattern="[0-9]*" autoComplete="of" autoCorrect="off" spellCheck="true" autoCapitalize="off" placeholder="CVV" required maxLength="4" />
    //                                     </FormGroup>
    //                                 </Col>
    //                             }
    //                             {getSelectedPaymentMethod === businessConstant.CONSTANT_CREDIT_CARD &&
    //                                 <Col md={4}>
    //                                     <FormGroup>
    //                                         <Input type="tel" name="dues" pattern="[0-9]*" autoComplete="of" autoCorrect="off" spellCheck="true" autoCapitalize="off" placeholder="Cuotas" required maxLength="2" />
    //                                     </FormGroup>
    //                                 </Col>
    //                             }
    //                             <Col md={12}>
    //                                 <FormGroup>
    //                                     <Input type="text" name="name_tc" placeholder={getSelectedPaymentMethod === businessConstant.CONSTANT_CREDIT_CARD ? "Nombre como aparece en la tarjeta" : "Nombre completo"} />
    //                                 </FormGroup>
    //                             </Col>
    //                             <Col md={4}>
    //                                 <FormGroup>
    //                                     <Input type="select" name="type_document" >
    //                                         <option value="CC">CC</option>
    //                                         <option value="CE">CE</option>
    //                                         <option value="TI">TI</option>
    //                                         <option value="NIT">NIT</option>
    //                                         <option value="PPN">PPN</option>
    //                                     </Input>
    //                                 </FormGroup>
    //                             </Col>
    //                             <Col md={8}>
    //                                 <FormGroup>
    //                                     <Input type="tel" name="cc" autoComplete="on" autoCorrect="on" spellCheck="true" autoCapitalize="off" placeholder="Número de identificación" required maxLength="13"
    //                                         onChange={e => e.target.value = FormatDocument(e.target.value)}
    //                                     />
    //                                 </FormGroup>
    //                             </Col>
    //                             <Col md={12}>
    //                                 <FormGroup>
    //                                     <Input type="email" name="email" placeholder="Correo electrónico" />
    //                                 </FormGroup>
    //                             </Col>
    //                             <Col md={12}>
    //                                 <FormGroup>
    //                                     <Input type="tel" name="phone" autoComplete="on" autoCorrect="off" spellCheck="true" autoCapitalize="off" placeholder="Número de celular" required maxLength="14"
    //                                         onChange={e => e.target.value = FormatPhoneNumber(e.target.value)}
    //                                     />
    //                                 </FormGroup>
    //                             </Col>
    //                             {getSelectedPaymentMethod === businessConstant.CONSTANT_PSE &&
    //                                 <Col md={12}>
    //                                     <FormGroup>
    //                                         <Input type="select" name="bank_id">
    //                                             {getListBanks.map((item, i) => <option key={i} value={item.bankCode}>{item.bankName}</option>)}
    //                                         </Input>
    //                                     </FormGroup>
    //                                 </Col>
    //                             }
    //                         </Row>
    //                     </CardBody>
    //                 </Card>

    //                 <Button className="btn-green mt-3 mb-3" block  >
    //                     {`Recargar ${getBtnText !== "" ? `con ${getBtnText}` : ""}`}
    //                 </Button>
    //             </Form>
    //         </>
    //     );
    // }


    const funSendReloadCobru = async e => {
        e.preventDefault();
        let amountMin;
        if (props.resProfile.minimum_reload === -1) {
            amountMin = getMinValue;
        } else {
            amountMin = props.resProfile.minimum_reload;
        }
        let number = GetDigits(getAmount, true);
        if (number < amountMin || number === 0) {
            return AlertSwal("MIN_MAX_VALUE", `Monto minimo de recarga ${FormatCOPNumber(amountMin)}`);
        }
        props.funToggleModalLoading(true);
        let resReload = await ReloadAsync({
            amount: GetDigits(getAmount, true),
            description: "Recarga Validu"
        });
        if (!resReload.error) {
            // console.log("response ", resReload);
            setPreviewCobru(resReload.data);
            window.open(`https://cobru.me/${resReload.data}`, '_blank');
            funItemMenuSelected(e, "query")
            window.location.href = "/Consultar";

            console.log(resReload.data, "aqui esta la data")


        } else {
            AlertSwal(resReload.message)
        }
        props.funToggleModalLoading(false);
    }
    const funItemMenuSelected = (e, itemMenu) => {
        e.preventDefault();
        dispatch({ type: "SELECTED_ITEM_MENU", itemMenu });
        // toogle menu if resolution <= 800
        if (window.screen.availWidth <= 800) {
            document.getElementById("list-group-sidewrapper").style.width = "15rem";
            document.getElementById("wrapper").classList.toggle("toggled");
        }
    }



    // render to quantity money reload
    const funHowMuchReload = () => {
        // if (getProyectCode <= 0 && getPinCode <= 0) {
        return (
            <>
                <h5 className="text-white pt-5 pb-2" >¿Cuánto quieres recargar?</h5>
                <div className="container-amount pt-2">
                    <Form onSubmit={e => funSendReloadCobru(e)}>
                        <Card className="mb-3">
                            <Input type="text" name="amount" value={getAmount}
                                placeholder="$0"
                                className="input-amount" required
                                onChange={e => setAmount(FormatCOPNumber(e.target.value))}
                            />
                            <CardBody style={{ padding: '4% 4% 0% 4%' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <div className="text-left">
                                        <p><b>Monto a Recargar</b></p>
                                        <p className="text-gray">Comisión transacción</p>
                                        <p className="text-gray">IVA comisión transacción</p>
                                        <p><b>Monto a Abonar</b></p>
                                    </div>
                                    <div className="text-right">
                                        <p ><b>{getAmount}</b></p>
                                        <p className="text-gray ">$0</p>
                                        <p className="text-gray ">$0</p>
                                        <p ><b>{getAmount}</b></p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Link></Link>
                        <Button className="btn-green mt-5 mb-5" block  >
                            {`Ir a Pagar ${getAmount !== "$0" ? getAmount : ""}`}
                        </Button>

                    </Form>
                    {/* {funFormContent()} */}

                </div>
            </>
        );
        // }
    }

    // render list icon payment methods
    // const funListPaymentMethod = () => {
    //     let render = null;
    //     if (getListPaymentMethods.length < 1) {
    //         render = <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             <img src="/assets/loadingIcon.gif" alt="img loading" width="40" height="40" />
    //         </div >
    //     } else {
    //         render = getListPaymentMethods.map((item, i) => {
    //             return (
    //                 <a href="/" key={i} onClick={e => funSeletedPaymentMethod(e, item)}
    //                     className={`${item.id === getSelectedPaymentMethod ? 'icon-selected-payment-method' : ''}`}>
    //                     <img src={item.photo} width={80} height={65} alt={`img icon method ${i}`} />
    //                 </a>
    //             );
    //         })
    //     }
    //     return render;
    // }

    // render content proyect and pin payment
    // const funGetSuccessProyectPinCode = () => {
    //     if (getProyectCode > 0 && getPinCode > 0) {
    //         return (
    //             <>
    //                 <div className="container-amount pb-5 pt-3">
    //                     <Card >
    //                         <CardBody style={{ padding: '4% 4% 0% 4%' }}>
    //                             <Row>
    //                                 <Col md={12} >
    //                                     <h6 className="pt-2" >Menciona estos datos cuando estés en un punto {getBtnText}</h6>
    //                                     <p className="first-code">Proyecto:  <span className="second-code">{getProyectCode}</span> </p>
    //                                     <hr />
    //                                 </Col>
    //                                 <Col md={12}>
    //                                     <p className="first-code">Pin:  <span className="second-code">{getPinCode}</span> </p>
    //                                 </Col>
    //                                 <Col md={12}>
    //                                     <p className="three-code"> Válido por  7 días  </p>
    //                                 </Col>
    //                             </Row>
    //                         </CardBody>
    //                     </Card>
    //                 </div>
    //             </>
    //         )
    //     }
    // }




    return (
        <>
            <NavComponent title="Recargar" name={props.resProfile.name} photo={props.resProfile.photo} />

            {getPreviewCobru === "" &&
                <div className="text-center content-scrollmenu animated fadeIn mt-2 mb-2 content-scroll-active-y" >
                    {/* //style={{ overflow: 'auto', height: '87vh' }}> */}
                    {/* <h5 className="text-white pt-5 pb-3" >Escoge tu medio de pago</h5> */}
                    {/* <div className="scrollmenu">
                        {funListPaymentMethod()}
                    </div> */}

                    {funHowMuchReload()}

                    {/* {funGetSuccessProyectPinCode()} */}

                    <CardFooter style={{ backgroundColor: 'white' }}>
                        <img src="/assets/methodsPay.png" width="80%" alt="methosPay" />
                    </CardFooter>
                </div>
            }
            {/* {
                getPreviewCobru !== "" &&
                <Card className="animated fadeIn content-iframe-reload mt-2 mb-2 content-scroll-active-y" >
                    <CardHeader style={{ backgroundColor: "#d5d5d7", borderBottom: 'none' }}>
                        <Row>
                            <Col md={6} xs={10}>
                                <h5 className="mt-2">Paga fácil usando Cobru</h5>
                            </Col>
                            <Col md={6} xs={2}>
                                <Button type="button" className="close mt-2" aria-label="Close"
                                    onClick={() => setPreviewCobru("")}
                                ><span aria-hidden="true">×</span></Button>
                            </Col>
                        </Row>
                    </CardHeader>
                     <iframe src={`https://prod.cobru.co/${getPreviewCobru}`} style={{ border: 'none', height: '75vh' }} title="ifr" >  

                     </iframe> 
                </Card>
            } */}
        </>
    );
}

