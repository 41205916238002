import React, { useContext, useEffect } from "react";
import { FormatCOPNumber } from "../managers/HelperManager";
import { FunctionContext } from "../managers/FuntionContext";
import { useGlobal } from "../managers/store/Context";
import { Spinner, Button } from "reactstrap";

import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
export default function SideBarComponent(props) {
  const { funGetBalance, Balance } = useContext(FunctionContext);
  const [state, dispatch] = useGlobal();
  let permission =
    props.resProfile.permissions && props.resProfile.permissions[0];
  useEffect(() => {
    funGetBalance();
  }, []);
  const funItemMenuSelected = (itemMenu) => {
    dispatch({ type: "SELECTED_ITEM_MENU", itemMenu });
    // toogle menu if resolution <= 800
    if (window.screen.availWidth <= 980) {
      document.getElementById("list-group-sidewrapper").style.width = "15rem";
      document.getElementById("wrapper").classList.toggle("toggled");
    }
  };

  const funItemActive = (itemMenu, icon = false) => {
    return itemMenu === state.itemMenu
      ? icon
        ? "active-item-icon"
        : "active-item"
      : "";
  };

  const funRenderBalance = () =>
    Balance == null ? (
      <Spinner type="grow" color="secondary" />
    ) : (
      <h6>{FormatCOPNumber(Balance)}</h6>
    );

  return (
    <>
      <div id="sidebar-wrapper">
        <div className="sidebar-heading">
          <img
            src="/assets/logo.png"
            alt="img logo"
            className="img-logo-sidebar"
          />
        </div>
        <div
          className="list-group-sitebar list-group-flush"
          id="list-group-sidewrapper"
        >
          <br />
          <Link
            to="/resumen"
            className={`list-group-item list-group-item-action ${funItemActive(
              "summary"
            )}`}
            onClick={() => funItemMenuSelected("summary")}
          >
            Resumen
          </Link>
          <Link
            to="/consultar"
            className={`list-group-item list-group-item-action ${funItemActive(
              "query"
            )}`}
            onClick={() => funItemMenuSelected("query")}
          >
            Consultar
          </Link>
          <Link
            to="/clientes"
            className={`list-group-item list-group-item-action ${funItemActive(
              "client"
            )}`}
            onClick={() => funItemMenuSelected("client")}
          >
            Clientes
          </Link>

          {permission && permission.by_admin && (
            <Link
              to="/empleados"
              className={`list-group-item list-group-item-action ${funItemActive(
                "users"
              )}`}
              onClick={() => funItemMenuSelected("users")}
            >
              Empleados
            </Link>
          )}

          <div className="logout-content">
            <a
              href="/logout"
              className="logout"
              onClick={(e) => {
                e.preventDefault();
                props.logout();
              }}
            >
              &nbsp; Cerrar Sesión&nbsp;
            </a>
          </div>
        </div>

        <hr className="list-item-hr" />
        <div className="list-group-sitebar list-group-flush">
          <div className="sidebar-footer">
            <span style={{ paddingTop: 4 }}>
              Disponible
              <br />
              {funRenderBalance()}
            </span>
          </div>
          <Link
            to="/recargar"
            className={`list-group-item list-group-item-action ${funItemActive(
              "reload",
              true
            )}`}
            onClick={() => funItemMenuSelected("reload")}
          >
            <img
              src="/assets/icon-cobruwhite.png"
              alt="img icon reload"
              className="img-icon-left"
            />
            &nbsp; Recargar
          </Link>
          <a
            href="/"
            className={`list-group-item list-group-item-action ${funItemActive(
              "support",
              true
            )}`}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:juanguillermo@trixel.co";
            }}
          >
            <img
              src="/assets/icon-help.png"
              alt="img icon reload"
              className="img-icon-left"
            />
            &nbsp; Soporte&nbsp;
          </a>
        </div>
      </div>
    </>
  );
}
