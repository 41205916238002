import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Badge, Col, Modal, ModalHeader, ModalBody, Row, Button } from 'reactstrap';
import _ from 'lodash';
import { ChangeDocumentStatus, GetClients } from '../managers/ApiManager';
import { AlertSwal, TitleCase, FormatDocument } from '../managers/HelperManager';







const ModalClient = props => {


    const [saveChanges, setSaveChanges] = useState(false);
    const [isPdfRUT, setisPdfRUT] = useState(true);
    const [reload, setReload] = useState(false);
    const [documentsList, setDocumentlList] = useState([]);
    const [itemMenu, setItemMenu] = useState("Identidad");
    const [antecedentes, setAntecedentes] = useState(null)
    const [sarlaftItem, setSarlaftItem] = useState(null)

    useEffect(() => {
        setSaveChanges(false);
        setDocumentlList([]);
        declarar()
    }, [props.modal]);


    useEffect(() => {
        refreshDocumentSatus();
    }, [reload]);

    const declarar = () => {
        if (props.modalClientLogs.data.length > 0) {

            if (props.modalClientLogs.data[0].response.records !== undefined) {
                setAntecedentes(props.modalClientLogs.data[0].response.records)
            }

            if (props.modalClientLogs.data[1] !== undefined) {
                setSarlaftItem(props.modalClientLogs.data[1])
            }
        }

    }
    // return the badge color and paddingleft content 
    const funGetBadgeColorAndPaddingLeft = percent => {
        let badgeColor = "";

        // change color bagdecolor
        if (percent > 50) {
            badgeColor = '#1CCE28';
        } else if (percent > 30) {
            badgeColor = '#E6C029';
        } else if (percent < 30) {
            badgeColor = '#FF5A52';
        }


        return badgeColor;
    }


    const changeDocumentStatus = (event, item) => {

        let type = event.target.value;



        if (documentsList.length > 0) {
            let index = _.findIndex(documentsList, { document_id: item.id });

            if (index == -1) {

                if (type === 'approve') {
                    documentsList.push({ document_id: item.id, aproved: true, rejected: false });
                } else if (type === 'refuse') {
                    documentsList.push({ document_id: item.id, aproved: false, rejected: true });
                }

            } else {

                if (type === 'approve') {
                    documentsList[index].aproved = true
                    documentsList[index].rejected = false
                } else if (type === 'refuse') {
                    documentsList[index].aproved = false
                    documentsList[index].rejected = true
                }
            }

        } else {

            setSaveChanges(true);

            if (type === 'approve') {
                documentsList.push({ document_id: item.id, aproved: true, rejected: false })
            } else if (type === 'refuse') {
                documentsList.push({ document_id: item.id, aproved: false, rejected: true })
            }
        }



    }


    const saveDocumentStatus = async () => {

        let payload = {
            "documents": documentsList
        }



        let response = await ChangeDocumentStatus(payload);


        setDocumentlList([]);
        setSaveChanges(false);
        setReload(true);

        if (response.message === 'OK') {

        } else {
            AlertSwal(response.message);
        }


    }


    const refreshDocumentSatus = async () => {

        if (props.item.id !== undefined) {

            let response = await GetClients(props.item.id);

            if (response.data !== undefined) {


                await props.modalClientDocuments(response.data.documents);
                setReload(false);
            }
        }
    }


    const DocumentName = (name) => {

        let documentName;

        if (name === 'Cedula-Frente') {

            documentName = "Cédula frente";

        } else if (name === 'Cedula-Reverso') {

            documentName = "Cédula reverso";

        } else if (name === 'Camara-de-Comercio') {

            documentName = "Cámara de comercio";

        } else {
            documentName = name;
        }


        return documentName;

    }


    const getverifyImg = (isVerified) => {
        let imgUrl;

        if (isVerified) {

            imgUrl = "/assets/modal/verified-icon.png";

        } else {
            imgUrl = "/assets/modal/rejected-icon.png";
        }

        return imgUrl;

    }


    const FormatPhoneNumber = (number) => {

        if (number != undefined) {


            let string = number.toString();
            let phoneFormat = string.replace(/^(\d{3})(\d{3})(\d{}).*/, '($1) $2-$3'); //quitamos los caracteres que no sean digitos

            phoneFormat = phoneFormat.substring(0, 16);// el maximo de digitos para el numero de telefono es 12
            phoneFormat = phoneFormat.replace(/^(\d{3})/g, "(" + phoneFormat.substring(0, 3) + ")"); //agregamos los parentesis. 
            phoneFormat = phoneFormat.replace(/\)(\d{3})/g, ") " + phoneFormat.substring(5, 8) + "-"); //agregamos un espacio y el primer guion

            return phoneFormat;
        }


    }


    const openPdfNewTab = (pdfUrl) => {


        // abrir un PDF en una pestaña nueva
        window.open(pdfUrl, "_blank");

        // redirigir la pestaña actual a otra URL
        //window.location.href = pdfUrl;
    }


    const FormatDocument = (number) => {

        if (number != undefined) {

            let string = number.toString();
            let documentFormat = string.replace(/\./g, '');

            documentFormat = documentFormat.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
            documentFormat = documentFormat.split('').reverse().join('').replace(/^[\.]/, '');

            return documentFormat;

        }
    }


    const FormatDocumentDate = (date, documentState) => {

        let documentDate;

        if (documentState === 'Pendiente') {
            let dateSplit;

            dateSplit = date.split('T');

            documentDate = moment(dateSplit[0]).format('MM/DD/YYYY, h:mm a');

        } else {
            documentDate = moment(date).format('MM/DD/YYYY, h:mm a');

        }

        return documentDate;
    }

    const funItemActive = (itemName) => {
        return itemName === itemMenu ? "modalTab-active-item" : "";
    }

    return (
        <>

            <Modal isOpen={props.modal} modalClassName="" backdrop={'static'}>
                <ModalHeader toggle={props.hideModal} style={{ backgroundColor: "rgb(213,213,215)", justifyContent: 'flex-start' }}>
                    <span className="ml-4" style={{ color: '#3D4052', fontSize: 17 }}>
                        {`${props.item.name} ${props.item.last_name}`}
                    </span>
                    <span className="ml-4" style={{ color: 'rgb(61,64,82)', fontSize: 17 }}>
                        {`${FormatDocument(props.item.document_number)}`}
                    </span>
                    <br />
                    <span className="modalSubtile">
                        {`ID: ${props.item.id} | ${props.item.name} ${props.item.last_name} | ${moment(Date.now()).format('DD/MM/YYYY h:mm:ss a')}`}
                    </span>
                </ModalHeader>
                <ModalBody className={"content-modalbody-query"}>
                    <div>
                        {
                            (saveChanges) &&
                            <div style={{ paddingLeft: '477px', top: '574px', position: 'fixed' }}>
                                <Button className="btn-green modalSaveButton" onClick={saveDocumentStatus}>GUARDAR</Button>
                            </div>
                        }

                        <div className="content-row-modalquery">
                            <div className={"modalTabContainer"}>
                                <div className='modalTabItemContainer'>
                                    <a href="#"
                                        className={`modalTab-item ${funItemActive("Identidad")}`}

                                        name='Identidad'
                                        onClick={e => setItemMenu(e.target.name)}
                                    >
                                        Identidad
                                    </a>
                                </div>

                                <div className='modalTabItemContainer'>
                                    <a href="#"
                                        className={`modalTab-item ${funItemActive("Antecedentes")}`}
                                        name='Antecedentes'
                                        onClick={e => setItemMenu(e.target.name)}
                                    >
                                        Antecedentes
                                    </a>
                                </div>

                                <div className='modalTabItemContainer'>
                                    <a href="#"
                                        className={`modalTab-item ${funItemActive("SARLAFT")}`}
                                        name='SARLAFT'
                                        onClick={e => setItemMenu(e.target.name)}
                                    >
                                        SARLAFT
                                    </a>
                                </div>

                                <div className='modalTabItemContainer'>
                                    <a href="#"
                                        className={`modalTab-item ${funItemActive("Biometría")}`}
                                        name='Biometría'
                                        onClick={e => setItemMenu(e.target.name)}
                                    >
                                        Biometría
                                    </a>
                                </div>
                            </div>

                            {
                                itemMenu === 'Identidad' &&
                                <div>
                                    <div style={{ paddingLeft: '626px', position: 'fixed' }} title="Coincidencia">
                                        <Badge className="badge-modal-query" style={{ backgroundColor: funGetBadgeColorAndPaddingLeft(90) }} >{90}%</Badge>
                                    </div>

                                    <Col md={3}>
                                        <Row>
                                            <Col md={12}>
                                                <p className="title-text-modal">{"Nombre"}</p>
                                                <div className="text-content-container">
                                                    <img className="text-content-modal text-content-Img" src={getverifyImg(false)} alt="img" />
                                                    <p className="text-content-modal"><b>{props.item.name}</b></p>
                                                </div>
                                            </Col>
                                            {
                                                props.item.last_name != '' &&
                                                <Col md={12}>
                                                    <p className="title-text-modal">{"Apellido"}</p>
                                                    <div className="text-content-container">
                                                        <img className="text-content-modal text-content-Img" src={getverifyImg(true)} alt="img" />
                                                        <p className="text-content-modal"><b>{props.item.last_name}</b></p>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col md={3}>
                                        <Row>
                                            <Col md={12}>
                                                <p className="title-text-modal">{"Tipo de documento"}</p>
                                                <div className="text-content-container">
                                                    <img className="text-content-modal text-content-Img" src={getverifyImg(true)} alt="img" />
                                                    <p className="text-content-modal"><b>{props.item.get_type_document_display}</b></p>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <p className="title-text-modal">{"Número de documento"}</p>
                                                <div className="text-content-container">
                                                    <img className="text-content-modal text-content-Img" src={getverifyImg(true)} alt="img" />
                                                    <p className="text-content-modal"><b>{FormatDocument(props.item.document_number)}</b></p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={3}>
                                        <Row>
                                            <Col md={12}>
                                                <p className="title-text-modal">{"Correo"}</p>
                                                <div className="text-content-container">
                                                    <img className="text-content-modal text-content-Img" src={getverifyImg(true)} alt="img" />
                                                    <p className="text-content-modal"><b>{props.item.email}</b></p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <p className="title-text-modal">{"Celular"}</p>
                                                <div className="text-content-container">
                                                    <img className="text-content-modal text-content-Img" src={getverifyImg(true)} alt="img" />
                                                    <p className="text-content-modal"><b>{FormatPhoneNumber(props.item.phone)}</b></p>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Col>

                                    <Col md={5}>
                                        <Row>
                                            <Col md={12}>
                                                <h4 className='modalDocumentTitle'>{"Documentos"}</h4>
                                            </Col>
                                            <div className="line"></div>

                                        </Row>
                                        <Row>
                                            {
                                                (props.clientDocuments.length > 0) &&

                                                props.clientDocuments.map((item, i) => {

                                                    return (
                                                        <Col key={i} md={12}>
                                                            {
                                                                item.get_document_status_display === 'Aprobado' &&
                                                                <div>
                                                                    <img className="text-content-modal modalVerifyImg" src="/assets/modal/check-icon-big.png" alt="img" />
                                                                </div>
                                                            }

                                                            {
                                                                item.type_document.name !== 'RUT' &&
                                                                <img className="text-content-modal modalImg column-edit-profile" src={item.url} alt={item.type_document.name} onClick={() => openPdfNewTab(item.url)} />
                                                            }

                                                            {
                                                                (item.type_document.name === 'RUT' && isPdfRUT) &&
                                                                <div id="rutPdfPlaceholder" className="column-edit-profile" onClick={() => openPdfNewTab(item.url)}>

                                                                    <img id='pdfPlaceholderIcon' className="text-content-modal" src="/assets/modal/icons8-pdf.png" alt={item.type_document.name} />

                                                                    <div style={{ alignSelf: 'center' }}>
                                                                        Ver PDF
                                                                    </div>
                                                                </div>
                                                            }

                                                            {
                                                                (item.type_document.name === 'RUT' && !isPdfRUT) &&
                                                                <img className="text-content-modal modalImg" src={item.url} alt={item.type_document.name} />
                                                            }

                                                            <div className='modalStatusOpt' >
                                                                <div className='modalStatusInfo'>
                                                                    <div>
                                                                        <p className="modalStatusDocumentName">{DocumentName(item.type_document.name)}</p>
                                                                    </div>

                                                                    <div>
                                                                        {
                                                                            item.get_document_status_display === 'Pendiente' &&
                                                                            <p className="modalStatusDate">{`Subida: ${FormatDocumentDate(item.date_created, 'Pendiente')}`}</p>
                                                                        }
                                                                        {
                                                                            item.get_document_status_display === 'Aprobado' &&
                                                                            <p className="modalStatusDate">{`Aprobada: ${FormatDocumentDate(item.date_approved, 'Aprobado')}`}</p>
                                                                        }
                                                                        {
                                                                            item.get_document_status_display === 'Rechazado' &&
                                                                            <p className="modalStatusDate">{`Rechazada: ${FormatDocumentDate(item.date_rejected, 'Rechazado')}`}</p>
                                                                        }
                                                                    </div>

                                                                </div>

                                                                {
                                                                    item.get_document_status_display === 'Pendiente' &&
                                                                    <div id="selectContainer">
                                                                        <select name="select" id="selectOpt" defaultValue={"pending"} onChange={(e) => changeDocumentStatus(e, item)}>
                                                                            <option className='selectItem' value="pending" >Pendiente</option>
                                                                            <option className='selectItem' value="approve" >Aprobado</option>
                                                                            <option className='selectItem' value="refuse">Rechazado</option>
                                                                        </select>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.get_document_status_display === 'Aprobado' &&
                                                                    <div id="selectContainer">
                                                                        <select name="select" id="selectOpt" defaultValue={"approve"} onChange={(e) => changeDocumentStatus(e, item)}>
                                                                            <option className='selectItem' value="pending" >Pendiente</option>
                                                                            <option className='selectItem' value="approve" >Aprobado</option>
                                                                            <option className='selectItem' value="refuse">Rechazado</option>
                                                                        </select>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.get_document_status_display === 'Rechazado' &&
                                                                    <div id="selectContainer">
                                                                        <select name="select" id="selectOpt" defaultValue={"refuse"} onChange={(e) => changeDocumentStatus(e, item)}>
                                                                            <option className='selectItem' value="pending" >Pendiente</option>
                                                                            <option className='selectItem' value="approve" >Aprobado</option>
                                                                            <option className='selectItem' value="refuse">Rechazado</option>
                                                                        </select>
                                                                    </div>
                                                                }

                                                            </div>
                                                        </Col>
                                                    );
                                                })
                                            }

                                        </Row>

                                    </Col>

                                </div>
                            }


                            {
                                itemMenu === 'Antecedentes' &&
                                <Row>
                                    <Col md={12}>
                                        <h4 className='modalDocumentTitle'>{'Antecedentes'}</h4>

                                    </Col>
                                    {props.modalClientLogs.data.length > 0 &&
                                        <div>


                                            <Col md={12}>
                                                <p className="text-titulo-modal-huella">Nombre</p>
                                                <p className="text-content-modal"><b>{TitleCase(props.modalClientLogs.data[0].response.name)}</b></p>
                                            </Col>
                                            <Col md={12}>
                                                <p className="text-titulo-modal-huella">Estatus</p>
                                                {props.modalClientLogs.data[0].response.status == "expired" &&
                                                    <p className="text-content-modal"><b>{TitleCase("Documento vencido")}</b></p>
                                                }
                                                {props.modalClientLogs.data[0].response.status == "records" &&
                                                    <p className="text-content-modal"><b>{TitleCase("Con Antecedentes")}</b></p>
                                                }
                                                {props.modalClientLogs.data[0].response.status == "no_records" &&
                                                    <p className="text-content-modal"><b>{TitleCase("Sin Antecedentes")}</b></p>
                                                }
                                            </Col>
                                            <Col md={12}>
                                                <p className="text-titulo-modal-huella">Mensaje</p>
                                                <p className="text-content-modal"><b>{TitleCase(props.modalClientLogs.data[0].response.message)}</b></p>
                                            </Col>


                                            {
                                                antecedentes !== null &&
                                                <div  >
                                                    <Row className="content-row-modalquery" style={{ paddingLeft: 15, paddingRight: 15 }}>

                                                        <Col md={6}>
                                                            <hr className="hr-modal-query" style={{ width: "1000%" }} />
                                                            <p className="titulo-modal" style={{ width: "100" }}><b>Antesedentes</b></p>
                                                            {antecedentes.INHABILIDADES != "" &&
                                                                antecedentes.INHABILIDADES.map((item, i) => {
                                                                    return (

                                                                        <div style={{ width: "100%" }} >

                                                                            <Row className="content-row-modalquery">
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">inabilidataciones</p>
                                                                                    <p className="text-content-modal"><b>{TitleCase(item["Inhabilidad legal"])}</b></p>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">módulo</p>
                                                                                    <p className="text-content-modal"><b>{TitleCase(item["Módulo"])}</b></p>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">desde</p>
                                                                                    <p className="text-content-modal"><b>{item["Fecha de inicio"]}</b></p>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">hasta</p>
                                                                                    <p className="text-content-modal"><b>{item["Fecha fin"]}</b></p>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">SIRI</p>
                                                                                    <p className="text-content-modal"><b>{item["SIRI"]}</b></p>
                                                                                </Col>

                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                })

                                                            }
                                                            {antecedentes.Sanciones != "" &&
                                                                antecedentes.Sanciones.map((item, i) => {
                                                                    return (

                                                                        <div style={{ width: "100%" }} >
                                                                            <Col md={6}>
                                                                                <hr className="hr-modal-query" style={{ paddingLeft: -15, width: "500%", paddingRight: 15 }} />

                                                                            </Col>
                                                                            <Row className="content-row-modalquery">
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">inabilidataciones</p>
                                                                                    <p className="text-content-modal"><b>{TitleCase(item["Suspendida"])}</b></p>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">Clase</p>
                                                                                    <p className="text-content-modal"><b>{TitleCase(item["Clase"])}</b></p>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">sancion</p>
                                                                                    <p className="text-content-modal"><b>{TitleCase(item["Sanción"])}</b></p>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">Termino</p>
                                                                                    <p className="text-content-modal"><b>{TitleCase(item["Término"])}</b></p>
                                                                                </Col>


                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                })

                                                            }
                                                            {antecedentes.Instancias != "" &&
                                                                antecedentes.Instancias.map((item, i) => {
                                                                    return (

                                                                        <div style={{ width: "100%" }} >
                                                                            <Col md={6}>
                                                                                <hr className="hr-modal-query" style={{ paddingLeft: -15, width: "500%", paddingRight: 15 }} />

                                                                            </Col>
                                                                            <Row className="content-row-modalquery">
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">Autoridad</p>
                                                                                    <p className="text-content-modal"><b>{TitleCase(item["Autoridad"])}</b></p>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">Nombre</p>
                                                                                    <p className="text-content-modal"><b>{TitleCase(item["Nombre"])}</b></p>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">fecha efecto Juridicos</p>
                                                                                    <p className="text-content-modal"><b>{item["fecha efecto Juridicos"]}</b></p>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">Fecha providencia</p>
                                                                                    <p className="text-content-modal"><b>{item["Fecha providencia"]}</b></p>
                                                                                </Col>



                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                })

                                                            }
                                                            {antecedentes.Delitos != "" &&
                                                                antecedentes.Delitos.map((item, i) => {
                                                                    return (

                                                                        <div style={{ width: "100%" }} >
                                                                            <hr className="hr-modal-query" style={{ paddingLeft: -15, width: "500%", paddingRight: 15 }} />
                                                                            <Col sytle={{ paddingLeft: 0 }} md={6}>


                                                                            </Col>
                                                                            <Row className="content-row-modalquery">
                                                                                <Col md={12}>
                                                                                    <p className="text-titulo-modal-huella">Descripción del Delito</p>
                                                                                    <p className="text-content-modal"><b>{TitleCase(item["Descripción del Delito"])}</b></p>
                                                                                </Col>

                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                })

                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </div>
                                    }
                                </Row>

                            }

                            {
                                itemMenu === 'SARLAFT' &&
                                <Row>
                                    <Col md={12}>
                                        <h4 className='modalDocumentTitle'>{'SARLAFT'}</h4>


                                    </Col>
                                    {props.modalClientLogs.data.length > 0 &&
                                        <div>
                                            <Col md={12}>
                                                <p className="text-titulo-modal-huella">Nombre</p>
                                                <p className="text-content-modal"><b>{TitleCase(props.modalClientLogs.data[0].response.name)}</b></p>
                                            </Col>
                                            <Col md={12}>
                                                <p className="text-titulo-modal-huella">Documento</p>
                                                <p className="text-content-modal"><b>{FormatDocument(sarlaftItem.document_number)}</b></p>
                                            </Col>
                                            {sarlaftItem.response.lista_clinton == false &&
                                                <Col md={12}>
                                                    <p className="text-titulo-modal-huella">En lista clinton</p>
                                                    <p className="text-content-modal"><b>{"No"}</b></p>
                                                </Col>
                                            }
                                            {sarlaftItem.response.lista_clinton !== false &&
                                                <Col md={12}>
                                                    <p className="text-titulo-modal-huella">En lista clinton</p>
                                                    <p className="text-content-modal"><b>{sarlaftItem.lista_clinton}</b></p>
                                                </Col>
                                            }
                                            {sarlaftItem.response.otros_organismos == false && <Col md={12}>
                                                <p className="text-titulo-modal-huella">otros organismos</p>
                                                <p className="text-content-modal"><b>{"No"}</b></p>
                                            </Col>}
                                            {sarlaftItem.response.otros_organismos !== false && <Col md={12}>
                                                <p className="text-titulo-modal-huella">otros organismos</p>
                                                <p className="text-content-modal"><b>{"Si"}</b></p>
                                            </Col>}
                                            {sarlaftItem.response.link !== null && <Col md={12}>
                                                <p className="text-titulo-modal-huella">link</p>
                                                <p className="text-content-modal"><b>{FormatDocument(sarlaftItem.link)}</b></p>
                                            </Col>}

                                            {sarlaftItem.response.detalle_us.length > 0 &&
                                                sarlaftItem.response.detalle_us.map((item, i) => {
                                                    return (
                                                        <div>
                                                            <hr className="hr-modal-query" style={{ width: " 176%" }} />

                                                            {item.informacion !== "" && <Col md={12}>
                                                                <p className="text-titulo-modal-huella">Informacion </p>
                                                                <p className="text-content-modal"><b>{item.informacion}</b></p>
                                                            </Col>}
                                                            {item.coincidencia !== "" && <Col md={12}>
                                                                <p className="text-titulo-modal-huella">Coincidencia </p>
                                                                <p className="text-content-modal"><b>{item.coincidencia}</b></p>
                                                            </Col>}
                                                            {item.fuente !== "" && <Col md={12}>
                                                                <p className="text-titulo-modal-huella">Fuente </p>
                                                                <p className="text-content-modal"><b>{item.fuente}</b></p>
                                                            </Col>}
                                                            {item.fuente !== "" && <Col md={12}>
                                                                <p className="text-titulo-modal-huella">Hecho </p>
                                                                <p className="text-content-modal"><b>{item.hecho}</b></p>
                                                            </Col>}
                                                            {item.codcliente !== "" && <Col md={12}>
                                                                <p className="text-titulo-modal-huella">Fecha de noticia </p>
                                                                <p className="text-content-modal"><b>{item.fecha_noticia}</b></p>
                                                            </Col>}
                                                            <hr className="hr-modal-query" />
                                                        </div>
                                                    )
                                                })

                                            }
                                            {sarlaftItem.response.detalle_otros.length > 0 &&
                                                sarlaftItem.response.detalle_otros.map((item, i) => {
                                                    return (
                                                        <div>
                                                            <hr className="hr-modal-query" style={{ width: " 175%" }} />

                                                            {item.informacion !== "" && <Col md={12}>
                                                                <p className="text-titulo-modal-huella">Informacion </p>
                                                                <p className="text-content-modal"><b>{item.informacion}</b></p>
                                                            </Col>}
                                                            {item.coincidencia !== "" && <Col md={12}>
                                                                <p className="text-titulo-modal-huella">Coincidencia </p>
                                                                <p className="text-content-modal"><b>{item.coincidencia}</b></p>
                                                            </Col>}
                                                            {item.fuente !== "" && <Col md={12}>
                                                                <p className="text-titulo-modal-huella">Fuente </p>
                                                                <p className="text-content-modal"><b>{item.fuente}</b></p>
                                                            </Col>}
                                                            {item.fuente !== "" && <Col md={12}>
                                                                <p className="text-titulo-modal-huella">Hecho </p>
                                                                <p className="text-content-modal"><b>{item.hecho}</b></p>
                                                            </Col>}
                                                            {item.codcliente !== "" && <Col md={12}>
                                                                <p className="text-titulo-modal-huella">Fecha de noticia </p>
                                                                <p className="text-content-modal"><b>{item.fecha_noticia}</b></p>
                                                            </Col>}

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </Row>

                            }

                            {
                                itemMenu === 'Biometría' &&
                                <Row>
                                    <Col md={12}>
                                        <h4 className='modalDocumentTitle'>{'Biometría'}</h4>
                                    </Col>


                                </Row>

                            }

                        </div>
                        <hr className="hr-modal-query" />
                    </div>

                </ModalBody>
            </Modal>

        </>
    );
}


const ModalClientLoading = props => {
    return (
        <>
            <Modal isOpen={props.modal} backdrop={'static'} className="modal-loading"  >
                <img style={{ margin: '0 auto' }} src="/assets/loadingIcon.gif" alt="img loading" width="50" height="50" />
            </Modal>
        </>
    )
}

export {
    ModalClientLoading,
    ModalClient
}
