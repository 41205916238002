import React, { useState, useEffect } from "react";
import { FaApple, FaEbay, FaFacebookF, FaFlickr, FaFoursquare, FaGithub, FaGoogle, FaInstagram, FaLastfm, FaAtlassian, FaQuora, FaLinkedin, FaWindows, FaPinterest, FaSkype, FaSpotify, FaTumblr, FaTwitter, FaVimeoV, FaWeibo, FaYahoo, FaDiscord, FaOdnoklassniki, FaAirbnb, FaAmazon, FaWordpress, FaEvernote, FaPatreon } from 'react-icons/fa';



import moment from 'moment';
export default function ModalFraud(props) {
    console.log("props normal 1", props);
    const [value, setValue] = useState(7);
    const [data, setData] = useState(props.item.data)

    return (
        <>
            {(
                <>
                    <div className="modal-data-main">


                        <div className="modal-sub-main">

                            <div className="modal-sub-data-main">
                                <button
                                    className="btn-x"
                                    onClick={() => props.setModal(false)}
                                >
                                    <span className="btn-span-x">
                                        ×
                                    </span>
                                </button>
                                <div className="relative px-6 pb-1 flex-auto">

                                    <h3 style={{ marginLeft: 20 }}>
                                        {<p>Datos:</p>}
                                    </h3>

                                    <div className="modal-data-body ">
                                        {!!data?.email && <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: "1rem", color: '#969696' }}>Email</p>
                                            <p style={{ color: '#101050', fontSize: '1.125rem' }}>{data?.email}</p>
                                        </div>}
                                        {!!data?.number && <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: "1rem", color: '#969696' }}>Teléfono</p>
                                            <p style={{ color: '#101050', fontSize: '1.125rem' }}>{data?.number}</p>
                                        </div>}
                                        {!!data?.ip && <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: "1rem", color: '#969696' }}>IP</p>
                                            <p style={{ color: '#101050', fontSize: '1.125rem' }}>{data?.ip}</p>
                                        </div>}
                                        {!!data?.country && <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: "1rem", color: '#969696' }}>Pais</p>
                                            <p style={{ color: '#101050', fontSize: '1.125rem' }}>{data?.country}</p>
                                        </div>}
                                        {!!data?.city && <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: "1rem", color: '#969696' }}>Ciudad</p>
                                            <p style={{ color: '#101050', fontSize: '1.125rem' }}>{data?.city}</p>
                                        </div>}
                                        {!!data?.isp_name && <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: "1rem", color: '#969696' }}>Nombre del</p>
                                            <p style={{ color: '#101050', fontSize: '1.125rem' }}>{data?.isp_name}</p>
                                        </div>}



                                        <div>
                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                                <p style={{ fontSize: "1rem", color: '#969696' }}>Puntuación</p>
                                                <p>{`${data.score}/100`}</p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", width: "100%", height: 4, borderRadius: 4, marginBottom: 40 }}>

                                                <div style={{ backgroundColor: data.score > 8 ? "#f03c69" : "#34c1b6", height: 4, width: `${data.score}%`, borderTopRightRadius: 4, borderBottomRightRadius: 4 }} />
                                                <div style={{ color: '#101050', fontSize: '1.125rem', backgroundColor: "#e7e7e7", height: 4, width: `${100 - data.score}%` }} />

                                            </div>
                                        </div>


                                        <p style={{ color: '#101050', fontSize: '1.125rem' }}>Perfiles en línea</p>
                                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-around', }}>
                                            {/*   {Object.entries(data).map(([key, value]) => (
                                                <div key={key}>
                                                    {value ? (
                                                        <img src={key} alt={key} />
                                                    ) : (
                                                        <p>{key} está en false</p>
                                                    )}
                                                </div>
                                            ))} */}
                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-around', marginBottom: 10, alignItems: 'center' }}>

                                                <FaApple title="Apple" color={data.account_details?.apple?.registered == false || data.account_details?.apple?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaGoogle title="Google" color={data.account_details?.google?.registered == false || data.account_details?.google?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaTwitter title="Twitter" color={data.account_details?.twitter?.registered == false || data.account_details?.twitter?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaSpotify title="Spotify" color={data.account_details?.spotify?.registered == false || data.account_details?.spotify?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaEbay title="Ebay" color={data.account_details?.ebay?.registered == false || data.account_details?.ebay?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaFacebookF title="Facebook" color={data.account_details?.facebook?.registered == false || data.account_details?.facebook?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaFlickr title="Flickr" color={data.account_details?.flickr?.registered == false || data.account_details?.flickr?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaFoursquare title="Foursquare Labs, Inc." color={data.account_details?.foursquare?.registered == false || data.account_details?.foursquare?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaGithub title="Github" color={data.account_details?.github?.registered == false || data.account_details?.github?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaInstagram title="Instagram" color={data.account_details?.instagram?.registered == false || data.account_details?.instagram?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaLastfm title="Last.fm" color={data.account_details?.lastfm?.registered == false || data.account_details?.lastfm?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-around', marginBottom: 10, alignItems: 'center' }}>
                                                <FaLinkedin title="Linkedin" color={data.account_details?.linkedin?.registered == false || data.account_details?.linkedin?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaWindows title="Windows" color={data.account_details?.apple?.registered == false || data.account_details?.apple?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaPinterest title="Pinterest" color={data.account_details?.pinterest?.registered == false || data.account_details?.pinterest?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaSkype title="Skype" color={data.account_details?.skype?.registered == false || data.account_details?.skype?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaTumblr title="Tumblr" color={data.account_details?.tumblr?.registered == false || data.account_details?.tumblr?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaVimeoV title="VimeoV" color={data.account_details?.apple?.registered == false || data.account_details?.apple?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaWeibo title="Weibo" color={data.account_details?.weibo?.registered == false || data.account_details?.weibo?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaYahoo title="Yahoo" color={data.account_details?.yahoo?.registered == false || data.account_details?.yahoo?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaDiscord title="Discord" color={data.account_details?.discord?.registered == false || data.account_details?.discord?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaOdnoklassniki title="Odnoklassniki" color={data.account_details?.apple?.registered == false || data.account_details?.apple?.registered == null ? "#cecece" : "#34c1b6"} size={21} />

                                            </div>
                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-around', marginBottom: 10, alignItems: 'center' }}>
                                                <img title="Booking" src={data.account_details?.booking?.registered == false || data.account_details?.booking?.registered == null ? "https://resources.cdn.seon.io/assets/logos/booking.svg" : require("../download/icons/booking.svg")} /* src="" */ className="icon-gravatar" alt="icon_booking" />
                                                <img title="Kakao" src={data.account_details?.kakao?.registered == false || data.account_details?.kakao?.registered == null ? "https://resources.cdn.seon.io/assets/logos/kakao.svg" : require("../download/icons/kakao.svg")} /* src="https://resources.cdn.seon.io/assets/logos/kakao.svg"  */ className="icon-gravatar" alt="icon_kakao" />
                                                <FaAirbnb title="Airbnb" color={data.account_details?.airbnb?.registered == false || data.account_details?.airbnb?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaAmazon title="Amazon" color={data.account_details?.amazon?.registered == false || data.account_details?.amazon?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <img title="qzone" src={data.account_details?.qzone?.registered == false || data.account_details?.qzone?.registered == null ? "https://resources.cdn.seon.io/assets/logos/qzone.svg" : require("../download/icons/qzone.svg")} /* src="https://resources.cdn.seon.io/assets/logos/qzone.svg" */ className="icon-gravatar" alt="icon_qzone" />
                                                <img title="adobe" src={data.account_details?.adobe?.registered == false || data.account_details?.adobe?.registered == null ? "https://resources.cdn.seon.io/assets/logos/adobe.svg" : require("../download/icons/adobe.svg")}/* src="" */ className="icon-gravatar" alt="icon_adobe" />
                                                <FaWordpress title="Wordpress" color={data.account_details?.wordpress?.registered == false || data.account_details?.wordpress?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <img title="Imgur" src={data.account_details?.imgur?.registered == false || data.account_details?.imgur?.registered == null ? "https://resources.cdn.seon.io/assets/logos/imgur.svg" : require("../download/icons/imgur.svg")}/* src="https://resources.cdn.seon.io/assets/logos/imgur.svg" */ className="icon-gravatar" alt="icon_imgur"></img>
                                                <img title="Disney" src={data.account_details?.disneyplus?.registered == false || data.account_details?.disneyplus?.registered == null ? "https://resources.cdn.seon.io/assets/logos/disney.svg" : require("../download/icons/disney.svg")}/* src="https://resources.cdn.seon.io/assets/logos/disney.svg"  */ className="icon-gravatar" alt="icon_disney" />
                                                <img title="Netflix" src={data.account_details?.netflix?.registered == false || data.account_details?.netflix?.registered == null ? "https://resources.cdn.seon.io/assets/logos/netflix.svg" : require("../download/icons/netflix.svg")}/* src="https://resources.cdn.seon.io/assets/logos/netflix.svg" */ className="icon-gravatar" alt="icon_netflix"></img>

                                            </div>
                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-around', alignItems: 'center', marginBottom: 10, }}>
                                                <img title="Aazada" src={data.account_details?.lazada?.registered == false || data.account_details?.lazada?.registered == null ? "https://resources.cdn.seon.io/assets/logos/lazada.svg" : require("../download/icons/lazada.svg")}/* src="https://resources.cdn.seon.io/assets/logos/lazada.svg" */ className="icon-gravatar" alt="icon_lazada"></img>

                                                <img title="Zoho" src={data.account_details?.lazada?.registered == false || data.account_details?.lazada?.registered == null ? "https://resources.cdn.seon.io/assets/logos/zoho.svg" : require("../download/icons/zoho.svg")}/* src="" */ className="icon-gravatar" alt="icon_zoho"></img>
                                                <img title="Samsung" src={data.account_details?.lazada?.registered == false || data.account_details?.lazada?.registered == null ? "https://resources.cdn.seon.io/assets/logos/samsung.svg" : require("../download/icons/samsung.svg")}/* src="https://resources.cdn.seon.io/assets/logos/samsung.svg" */ className="icon-gravatar" alt="icon_samsung"></img>
                                                <FaEvernote title="Evernote" color={data.account_details?.evernote?.registered == false || data.account_details?.evernote?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <img title="envato" src={data.account_details?.envato?.registered == false || data.account_details?.envato?.registered == null ? "https://resources.cdn.seon.io/assets/logos/envato.svg" : require("../download/icons/envato.svg")}/* src="https://resources.cdn.seon.io/assets/logos/envato.svg" */ className="icon-gravatar" alt="icon_envato"></img>
                                                <FaPatreon title="Patreon" color={data.account_details?.patreon?.registered == false || data.account_details?.patreon?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <img title="Tokopedia" src={data.account_details?.lazada?.registered == false || data.account_details?.lazada?.registered == null ? "https://resources.cdn.seon.io/assets/logos/tokopedia.svg" : require("../download/icons/tokopedia.svg")}/* src="https://resources.cdn.seon.io/assets/logos/tokopedia.svg" */ className="icon-gravatar" alt="icon_tokopedia"></img>
                                                <img title="Rambler" src={data.account_details?.rambler?.registered == false || data.account_details?.rambler?.registered == null ? "https://resources.cdn.seon.io/assets/logos/rambler.svg" : require("../download/icons/rambler.svg")}/* src="https://resources.cdn.seon.io/assets/logos/rambler.svg" */ className="icon-gravatar" alt="icon_rambler"></img>
                                                <FaQuora title="Quora" color={data.account_details?.quora?.registered == false || data.account_details?.quora?.registered == null ? "#cecece" : "#34c1b6"} size={21} />
                                                <FaAtlassian title="Atlassian" color={data.account_details?.atlassian?.registered == false || data.account_details?.atlassian?.registered == null ? "#cecece" : "#34c1b6"} size={21} />

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" fixed inset-0 z-40 " style={{
                        zIndex: 40,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        position: 'fixed',
                        background: 'rgba(47, 46, 44, 0.8)',
                        backdropFilter: 'blur(10px)',
                    }}></div>
                </>
            )}
        </>
    );
}
