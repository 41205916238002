import React, { useState, useContext } from "react";
import { FunctionContext } from "../managers/FuntionContext";
import { Col, Row, Button, Form, FormGroup, Input } from "reactstrap";
import { QueryBIN } from "../managers/ApiManager";
import { AlertSwal } from "../managers/HelperManager";
import { useGlobal } from "../managers/store/Context";
import { ModalBIN, ModalBINLoading } from "./modalBIN";
import moment from "moment";
import { data } from "jquery";
import ReactFlagsSelect from "react-flags-select";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

export default function ConsultaBin(props) {
  const [getBtnText, setBtnText] = useState("Consultar");
  const [, dispatch] = useGlobal();
  const [idTypeQuery, setIdTypeQuery] = useState(props.idTypeQuery);
  var history = useHistory();

  const { funGetBalance } = useContext(FunctionContext);
  const [BINItem, setBINItem] = useState(props.itemBin);
  const [getModalBINLoading, setModalBINLoading] = useState(false);
  const [getModalBIN, setModalBIN] = useState(props.modalBin);

  const consultaBIN = async (e) => {
    e.preventDefault();
    setModalBINLoading(true);
    setBtnText("Consultando...");
    let { BIN, type_query_id } = e.target;

    const DataBIN = await BIN.value.toLowerCase();

    if (DataBIN != "") {
      const response = await QueryBIN(DataBIN);

      if (response.error == false) {
        setBINItem(response.data);

        setModalBINLoading(false);
        setModalBIN(true);
        funGetBalance();
        props.funLogsQuery();
      } else if (response.message == "NOT_ENOUGH_BALANCE") {
        swal({
          title: "No tienes saldo disponible para realizar esta consulta",
          text: "¿Deseas Recargar? ",
          icon: "info",
          buttons: {
            cancel: "No",
            confirm: "Si",
          },
        }).then((willDelete) => {
          if (willDelete) {
            var itemMenu = "reload";
            dispatch({ type: "SELECTED_ITEM_MENU", itemMenu });
          } else {
          }
        });
      } else {
        AlertSwal(response.message);
      }
    }
    setModalBINLoading(false);
    setBtnText("Consultar");
  };

  const tipoConsulta = async (x) => {
    if (x == 1) {
      history.push("/consultar/la_ft");
    } else if (x == "3") {
      history.push("/consultar/huella_digital");
    } else if (x == "4") {
      history.push("/consultar/identidad");
    } else if (x == "5") {
      history.push("/consultar/validacion_IP");
    } else if (x == "6") {
      history.push("/consultar/validacion_BIN");
    } else if (x == "7") {
      history.push("/consultar/score_de_riesgo");
    } else if (x == "8") {
      history.push("/consultar/imagen_perfil");
    } else if (x == "9") {
      history.push("/consultar/informacion_laboral");
    } else if (x == "10") {
      history.push("/consultar/fraude");
    }
  };

  return (
    <div className="content-jumbotron  basket animated fadeIn mt-2 mb-2">
      <Form onSubmit={(e) => consultaBIN(e)}>
        <Row form style={{ alignitems: "center" }}>
          <Col md={5}>
            <FormGroup>
              <Input
                type="select"
                name="type_query_id"
                defaultValue="6"
                onChange={(e) => tipoConsulta(e.target.value)}
                required
              >
                <option hidden disabled={true} value="">
                  Tipo de consulta
                </option>
                <option value="1">LA/FT</option>
                {/*                 <option value="3">Huella digital</option>
 */}                <option value="4">Identidad</option>
                <option value="5"> Validación IP</option>
                <option value="6"> Validación BIN</option>
                <option value="7">Score de riesgo</option>
                <option value="8">Imagen perfil</option>
                <option value="9">Informacion laboral</option>
                <option value="10"> Fraude </option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Input type="number" name="BIN" placeholder="BIN" />
            </FormGroup>
          </Col>

          <Col md={2} style={{ marginleft: 10 }}>
            <FormGroup>
              <Button
                className="btn-green"
                disabled={getBtnText === "Consultando..."}
                block
              >
                {getBtnText}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>

      {BINItem != null && (
        <div className="d-flex" id="wrapper">
          <ModalBIN
            item={BINItem}
            modal={getModalBIN}
            hideModal={() => setModalBIN(false)}
          />
        </div>
      )}
      <ModalBINLoading modal={getModalBINLoading} />
    </div>
  );
}
