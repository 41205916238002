import React, { useEffect, useState, useContext } from 'react';




const NotFound = (props) => {

    var url = window.location.pathname

    return (
        <>
            <div style={{ marginTop: "100px" }}>
                <h1 className="title-notFound">404</h1>
                <p className="text-notFound" >la pagina no fue encontrada</p>
            </div>
        </>
    )
}

export default NotFound;