import React, { useState, useEffect } from "react";
import {
  Badge,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Input,
} from "reactstrap";
import { TitleCase } from "../managers/HelperManager";
import moment from "moment";

const ModalBIN = (props) => {
  console.log(props.item);

  return (
    <>
      {props.item.response == undefined && (
        <Modal isOpen={props.modal} backdrop={"static"}>
          <ModalHeader
            toggle={props.hideModal}
            style={{ backgroundColor: "rgb(213,213,215)" }}
          >
            {/* {`${props.item.name}`}julio alfredo bargas */}Validación BIN
            <br />
            <span
              style={{ color: "rgb(61,64,82)", fontSize: 15, marginLeft: 0 }}
            >
              ID:{props.item.id}&nbsp;|{props.item.employee}&nbsp;|&nbsp;
              {moment(props.item.date_created).format("L h:mm:ss a")}
              <br />
            </span>
            <span
              className="ml-4"
              style={{ color: "rgb(61,64,82)", fontSize: 15 }}
            ></span>
            <a href="/download">
              <i className="fas fa-file-download download-button"></i>
            </a>
          </ModalHeader>
          <ModalBody
            className={"content-modalbody-query"}
            style={{ padding: 28 }}
          >
            {props.item.data.valid == "true" && (
              <Row className="content-row-modalquery">
                <Col md={12}>
                  <h4 className="modalDocumentTitle">{"Datos"}</h4>
                </Col>

                <Col md={12}>
                  <p className="text-titulo-modal-huella">BIN</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{props.item.data.bin}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Banco</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{TitleCase(props.item.data.bank)}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Card</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{TitleCase(props.item.data.card)}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Tipo</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{TitleCase(props.item.data.type)}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Nivel</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{TitleCase(props.item.data.level)}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Pais</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{TitleCase(props.item.data.country)}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Codigo del pais</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{props.item.data.countrycode}</b>
                  </p>
                </Col>
                {props.item.data.website != "" && (
                  <Col md={12} style={{ marginBottom: "1rem" }}>
                    <p className="text-titulo-modal-huella margin-5">Website</p>
                    <a target="_blank" href={props.item.data.website} className="text-content-modal links lower" style={{ fontSize: 18 }}>
                      {props.item.data.website}
                    </a>
                  </Col>
                )}
                {props.item.data.phone != "" && (
                  <Col md={12}>
                    <p className="text-titulo-modal-huella">phone</p>
                    <p className="text-content-modal" style={{ fontSize: 18 }}>
                      <b>{props.item.data.phone}</b>
                    </p>
                  </Col>
                )}
              </Row>
            )}
            {props.item.data.valid == "false" && (
              <h4 className="text-center">
                No se encontraron coincidencias para esta búsqueda
              </h4>
            )}
          </ModalBody>
        </Modal>
      )}
      {props.item.response != undefined && (
        <Modal isOpen={props.modal} backdrop={"static"}>
          {console.log}
          <ModalHeader
            toggle={props.hideModal}
            style={{ backgroundColor: "rgb(213,213,215)" }}
          >
            {/* {`${props.item.name}`}julio alfredo bargas */}Validación BIN
            <br />
            <span
              style={{ color: "rgb(61,64,82)", fontSize: 15, marginLeft: 0 }}
            >
              ID:{props.item.id}&nbsp;|{props.item.employee}&nbsp;|&nbsp;
              {moment(props.item.date_created).format("L h:mm:ss a")}
              <br />
            </span>
            <span
              className="ml-4"
              style={{ color: "rgb(61,64,82)", fontSize: 15 }}
            ></span>
            <a href="/download">
              <i className="fas fa-file-download download-button"></i>
            </a>
          </ModalHeader>
          <ModalBody
            className={"content-modalbody-query"}
            style={{ padding: 28 }}
          >
            {props.item.response.valid == "true" && (
              <Row className="content-row-modalquery">
                <Col md={12}>
                  <h4 className="modalDocumentTitle">{"Datos"}</h4>
                </Col>

                <Col md={12}>
                  <p className="text-titulo-modal-huella">BIN</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{props.item.response.bin}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Banco</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{TitleCase(props.item.response.bank)}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Card</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{TitleCase(props.item.response.card)}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Tipo</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{TitleCase(props.item.response.type)}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Nivel</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{TitleCase(props.item.response.level)}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Pais</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{TitleCase(props.item.response.country)}</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="text-titulo-modal-huella">Codigo del pais</p>
                  <p className="text-content-modal" style={{ fontSize: 18 }}>
                    <b>{props.item.response.countrycode}</b>
                  </p>
                </Col>
                {props.item.response.website != "" && (
                  <Col md={12} style={{ marginBottom: "1rem" }}>
                    <p className="text-titulo-modal-huella margin-5"  >Website</p>
                    <a target="_blank" href={props.item.response.website} className="text-content-modal links lower" style={{ fontSize: 18 }}>
                      {props.item.response.website}
                    </a>
                  </Col>
                )}
                {props.item.response.phone != "" && (
                  <Col md={12}>
                    <p className="text-titulo-modal-huella">phone</p>
                    <p className="text-content-modal" style={{ fontSize: 18 }}>
                      <b>{props.item.response.phone}</b>
                    </p>
                  </Col>
                )}
              </Row>
            )}
            {props.item.response.valid == "false" && (
              <h4 className="text-center">
                No se encontraron coincidencias para esta búsqueda
              </h4>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
const ModalBINLoading = (props) => {
  return (
    <>
      <Modal isOpen={props.modal} backdrop={"static"} className="modal-loading">
        <img
          style={{ margin: "0 auto" }}
          src="/assets/loadingIcon.gif"
          alt="img loading"
          width="50"
          height="50"
        />
      </Modal>
    </>
  );
};

export { ModalBINLoading, ModalBIN };
