const config = {
    // validu key produccion 
    validu_api_key: "7oa71voJ.4gFO1Bul2aOS3zfpd9ARvIeeFofPQL0I",
    /* validu key dev */
    //validu_api_key: "2lbppaor.BumDRRDqXBPaVxcNjkHBPkpc0XJkXRap", 
}

const businessConstant = {
    CREDIT_CART: {
        MIN_MAX_VALUE: {
            min: 60000,
            max: 100000
        }
    },
    BALOTO: {
        MIN_MAX_VALUE: {
            min: 25000,
            max: 100000
        }
    },
    EFECTY: {
        MIN_MAX_VALUE: {
            min: 25000,
            max: 100000
        }
    },
    PSE: {
        MIN_MAX_VALUE: {
            min: 10000,
            max: 100000
        }
    },
    CONSTANT_BALOTO: 1,
    CONSTANT_EFECTY: 2,
    CONSTANT_PSE: 3,
    CONSTANT_CREDIT_CARD: 4
}



export { config, businessConstant };
