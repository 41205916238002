import React, { Component, Fragment } from 'react';
import { Alert, Button, Form, FormGroup, Input, } from 'reactstrap';
import { LoginAsync } from '../managers/ApiManager';
import { urlVerificator } from '../managers/HelperManager';

// componente padre inicial que carga al componente login
function App(props) {
  return (
    <Fragment>
      {props.fakeAuth('public')}
      <Login />
    </Fragment>
  );
}

// componente para la vista de inicio se sesión
class Login extends Component {

  constructor() {
    super();

    this.state = {
      LoginBtnText: "Ingresar",
      LoginBtnDisabled: false,
      errorMsg: "",
      visible: true
    }
  }

  componentDidMount() {
    urlVerificator();
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  handleLoginUser = async e => {
    let { email, password } = e.target;
    e.preventDefault();
    this.setState({ LoginBtnText: "Ingresando...", LoginBtnDisabled: true });

    let res = await LoginAsync(email.value.toLowerCase(), password.value);
    if (res === 2) {
      this.initSetState("El usuario esta inactivo, No puede iniciar sesion")


    }
    if (res === null) {
      this.initSetState("Ha ocurrido un error, intente nuevamente por favor")
    }
    else if (res === true) {

      window.location.href = "/resumen"
    } else if (res === false) {
      this.initSetState("Usuario o contraseña incorrectos")
    }
  }
  // initialize set state function
  initSetState = message => {
    return this.setState({
      LoginBtnText: "Ingresar",
      LoginBtnDisabled: false,
      errorMsg: message,
      visible: true
    });
  }

  render() {
    return (
      <div className="container-app bg-dark-content">
        <img onClick={() => (window.location.href = "/")} src="/assets/logo.png" className="img-login-logo" alt="img logo" />
        <Form className="form-login" onSubmit={this.handleLoginUser.bind(this)}>
          {this.state.errorMsg !== "" &&
            <Alert color="danger" isOpen={this.state.visible} toggle={this.onDismiss.bind(this)} >
              {this.state.errorMsg}
            </Alert>
          }
          <FormGroup>
            <Input type="text" name="email" placeholder="Usuario" required onChange={e => e.target.value.toLowerCase()} />
          </FormGroup>
          <FormGroup>
            <Input type="password" name="password" placeholder="Contraseña" required />
          </FormGroup>
          <FormGroup>
            <Button className="btn-green" block disabled={this.state.LoginBtnDisabled}>{this.state.LoginBtnText}</Button>
          </FormGroup>
          <FormGroup >
            <Button className="btn-register" onClick={() => (window.location.href = "/registro")} block>Registrarse</Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}


export default App;
