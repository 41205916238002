import React, { useState, useEffect } from 'react';
import { Badge, Col, Modal, ModalHeader, ModalBody, Row, Input } from 'reactstrap';
import { TitleCase } from '../managers/HelperManager';
import moment from 'moment';

const ModalScore = (props) => {

    console.log(props.item.response)
    return (
        <>
            {props.item.response == undefined &&
                <Modal isOpen={props.modal} backdrop={'static'}>
                    <ModalHeader toggle={props.hideModal} style={{ backgroundColor: "rgb(213,213,215)" }}>
                        {/* {`${props.item.name}`}julio alfredo bargas */} Score de riesgo
                        <br />
                        <span style={{ color: 'rgb(61,64,82)', fontSize: 15, marginLeft: 0 }}>
                            ID:{props.item.id}&nbsp;|{props.item.employee}&nbsp;|&nbsp;{moment(props.item.date_created).format('L h:mm:ss a')}
                            <br />
                        </span>

                        <span className="ml-4" style={{ color: 'rgb(61,64,82)', fontSize: 15 }}>

                        </span>

                        <a href="/download" ><i className="fas fa-file-download download-button"></i></a>
                    </ModalHeader>
                    <ModalBody className={"content-modalbody-query"} style={{ padding: 28 }}>

                        <Row className="content-row-modalquery">
                            <Col md={12}>
                                <h4 className='modalDocumentTitle'>{'Datos'}</h4>

                            </Col>


                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Email</p>
                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.email}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Reputacion</p>
                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.reputation}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Sosprechoso</p>
                                {props.item.data.suspicious == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                                {props.item.data.suspicious == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Lista Negra</p>
                                {props.item.data.details?.blacklisted == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                                {props.item.data.details?.blacklisted == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Actividad Maliciosa</p>
                                {props.item.data.details?.malicious_activity == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.malicious_activity == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Actividad Maliciosa reciente</p>
                                {props.item.data.details?.malicious_activity_recent == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.malicious_activity_recent == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Credenciales Filtradas</p>
                                {props.item.data.details?.credentials_leaked == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.credentials_leaked == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Credenciales Filtradas Reciente</p>
                                {props.item.data.details?.credentials_leaked_recent == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.credentials_leaked_recent == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Filtración de datos</p>
                                {props.item.data.details?.data_breach == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.data_breach == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">visto por primera vez</p>

                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.details?.first_seen}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">ultima vez visto</p>

                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.details?.last_seen}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">el dominio existe</p>
                                {props.item.data.details?.domain_exists == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.domain_exists == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">reputación de dominio</p>

                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.details?.domain_reputation}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">nuevo dominio</p>
                                {props.item.data.details?.new_domain == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.new_domain == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">días desde la creación del dominio</p>

                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.details?.days_since_domain_creation}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Sospechoso tld</p>
                                {props.item.data.details?.suspicious_tld == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.suspicious_tld == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> spam</p>
                                {props.item.data.details?.spam == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.spam == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> proveedor gratuito</p>
                                {props.item.data.details?.free_provider == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.free_provider == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> entregable</p>
                                {props.item.data.details?.disposable == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.disposable == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> aceptar todo</p>
                                {props.item.data.details?.accept_all == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.accept_all == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> válido mx</p>
                                {props.item.data.details?.valid_mx == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.valid_mx == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> mx primaria</p>
                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.details?.primary_mx}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> falsificable</p>
                                {props.item.data.details?.spoofable == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.spoofable == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> SPF estricto</p>
                                {props.item.data.details?.spf_strict == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.spf_strict == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> Dmarc aplicado</p>
                                {props.item.data.details?.dmarc_enforced == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.data.details?.dmarc_enforced == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            {props.item.data.details?.profiles != "" && <Col md={12}>
                                <p className="text-titulo-modal-huella"> perfiles</p>
                                {props.item.data.details?.profiles.map((item, i) => {
                                    return (
                                        <p className="text-content-modal" style={{ fontSize: 18 }}><b>{item}</b></p>
                                    )
                                })
                                }


                            </Col>}






                        </Row>

                    </ModalBody>
                </Modal>
            }
            {props.item.response != undefined &&
                <Modal isOpen={props.modal} backdrop={'static'}>
                    <ModalHeader toggle={props.hideModal} style={{ backgroundColor: "rgb(213,213,215)" }}>
                        {/* {`${props.item.name}`}julio alfredo bargas */} Score de riesgo
                        <br />
                        <span style={{ color: 'rgb(61,64,82)', fontSize: 15, marginLeft: 0 }}>
                            ID:{props.item.id}&nbsp;|{props.item.employee}&nbsp;|&nbsp;{moment(props.item.date_created).format('L h:mm:ss a')}
                            <br />
                        </span>

                        <span className="ml-4" style={{ color: 'rgb(61,64,82)', fontSize: 15 }}>

                        </span>

                        <a href="/download" ><i className="fas fa-file-download download-button"></i></a>
                    </ModalHeader>
                    <ModalBody className={"content-modalbody-query"} style={{ padding: 28 }}>

                        <Row className="content-row-modalquery">
                            <Col md={12}>
                                <h4 className='modalDocumentTitle'>{'Datos'}</h4>

                            </Col>


                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Email</p>
                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.email}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Reputacion</p>
                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.reputation}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Sosprechoso</p>
                                {props.item.response.suspicious == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                                {props.item.response.suspicious == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Lista Negra</p>
                                {props.item.response.details?.blacklisted == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                                {props.item.response.details?.blacklisted == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Actividad Maliciosa</p>
                                {props.item.response.details.malicious_activity == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.malicious_activity == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Actividad Maliciosa reciente</p>
                                {props.item.response.details.malicious_activity_recent == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.malicious_activity_recent == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Credenciales Filtradas</p>
                                {props.item.response.details.credentials_leaked == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.credentials_leaked == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Credenciales Filtradas Reciente</p>
                                {props.item.response.details.credentials_leaked_recent == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.credentials_leaked_recent == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Filtración de datos</p>
                                {props.item.response.details.data_breach == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.data_breach == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">visto por primera vez</p>

                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.details.first_seen}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">ultima vez visto</p>

                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.details.last_seen}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">el dominio existe</p>
                                {props.item.response.details.domain_exists == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.domain_exists == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">reputación de dominio</p>

                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.details.domain_reputation}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">nuevo dominio</p>
                                {props.item.response.details.new_domain == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.new_domain == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">días desde la creación del dominio</p>

                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.details.days_since_domain_creation}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella">Sospechoso tld</p>
                                {props.item.response.details.suspicious_tld == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.suspicious_tld == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> spam</p>
                                {props.item.response.details.spam == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.spam == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> proveedor gratuito</p>
                                {props.item.response.details.free_provider == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.free_provider == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> entregable</p>
                                {props.item.response.details.disposable == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.disposable == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> aceptar todo</p>
                                {props.item.response.details.accept_all == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.accept_all == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> válido mx</p>
                                {props.item.response.details.valid_mx == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.valid_mx == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> mx primaria</p>
                                <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.details.primary_mx}</b></p>
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> falsificable</p>
                                {props.item.response.details.spoofable == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.spoofable == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> SPF estricto</p>
                                {props.item.response.details.spf_strict == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.spf_strict == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            <Col md={12}>
                                <p className="text-titulo-modal-huella"> Dmarc aplicado</p>
                                {props.item.response.details.dmarc_enforced == true &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>Si</b></p>}
                                {props.item.response.details.dmarc_enforced == false &&
                                    <p className="text-content-modal" style={{ fontSize: 18 }}><b>No</b></p>}
                            </Col>
                            {props.item.response.details.profiles != "" && <Col md={12}>
                                <p className="text-titulo-modal-huella"> perfiles</p>
                                {props.item.response.details.profiles.map((item, i) => {
                                    return (
                                        <p className="text-content-modal" style={{ fontSize: 18 }}><b>{item}</b></p>
                                    )
                                })
                                }


                            </Col>}






                        </Row>

                    </ModalBody>
                </Modal>

            }
        </>

    )
}
const ModalScoreLoading = props => {
    return (
        <>
            <Modal isOpen={props.modal} backdrop={'static'} className="modal-loading"  >
                <img style={{ margin: '0 auto' }} src="/assets/loadingIcon.gif" alt="img loading" width="50" height="50" />
            </Modal>
        </>
    )
}

export {
    ModalScoreLoading,
    ModalScore
}