import React, { useState, useEffect } from "react";
import {
  Badge,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Input,
} from "reactstrap";
import { TitleCase } from "../managers/HelperManager";
import moment from "moment";

const ModalImage = (props) => {
  return props.item.response == undefined ? (
    <>
      <Modal isOpen={props.modal} backdrop={"static"}>
        <ModalHeader
          toggle={props.hideModal}
          style={{ backgroundColor: "rgb(213,213,215)" }}
        >
          Imagen Perfil
          <br />
          <span style={{ color: "rgb(61,64,82)", fontSize: 15, marginLeft: 0 }}>
            ID:{props.item.id}&nbsp;|{props.item.employee}&nbsp;|&nbsp;
            {moment(props.item.date_created).format("L h:mm:ss a")}
            <br />
          </span>
          <span
            className="ml-4 "
            style={{ color: "rgb(61,64,82)", fontSize: 15 }}
          ></span>
          <a href="/download">
            <i className="fas fa-file-download download-button"></i>
          </a>
        </ModalHeader>
        <ModalBody
          className={"content-modalbody-query"}
          style={{ padding: 28 }}
        >
          <div className="justify-content-center align-item-center">
            {props.item.data !== "User not found" && (
              <img
                className="img-fluid  mx-auto"
                src={`${props.item.data.entry[0].photos[0].value}` + "?s=600"}
              />
            )}
            {props.item.data == "User not found" && (
              <h4 className="text-center center-block">
                No se encontraron coincidencias para esta búsqueda
              </h4>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  ) : (
    <Modal isOpen={props.modal} backdrop={"static"}>
      <ModalHeader
        toggle={props.hideModal}
        style={{ backgroundColor: "rgb(213,213,215)" }}
      >
        Imagen Perfil
        <br />
        <span style={{ color: "rgb(61,64,82)", fontSize: 15, marginLeft: 0 }}>
          ID:{props.item.id}&nbsp;|{props.item.employee}&nbsp;|&nbsp;
          {moment(props.item.date_created).format("L h:mm:ss a")}
          <br />
        </span>
        <span
          className="ml-4 "
          style={{ color: "rgb(61,64,82)", fontSize: 15 }}
        ></span>
        <a href="/download">
          <i className="fas fa-file-download download-button"></i>
        </a>
      </ModalHeader>
      <ModalBody className={"content-modalbody-query"} style={{ padding: 28 }}>
        <div className="justify-content-center align-item-center">
          {props.item.response !== "User not found" && (
            <img
              className="img-fluid  mx-auto"
              src={`${props.item.response.entry[0].photos[0].value}` + "?s=600"}
            />
          )}
          {props.item.response == "User not found" && (
            <h4 className="text-center center-block">
              No se encontraron coincidencias para esta búsqueda
            </h4>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
const ModalImageLoading = (props) => {
  return (
    <>
      <Modal isOpen={props.modal} backdrop={"static"} className="modal-loading">
        <img
          style={{ margin: "0 auto" }}
          src="/assets/loadingIcon.gif"
          alt="img loading"
          width="50"
          height="50"
        />
      </Modal>
    </>
  );
};

export { ModalImage, ModalImageLoading };
