import React, { useState, useEffect } from 'react';
import NavComponent from './Nav';
import {
    Card, CardTitle, Row, Col
} from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import 'daterangepicker';
import { Pie, Line, defaults } from 'react-chartjs-2';
import { SummaryAsync, GetLogsQuery, CountQuery } from '../managers/ApiManager';
import { FormatCOPNumber, AlertSwal } from '../managers/HelperManager';

// chage font color chartjs
defaults.global.defaultFontColor = 'white';
const dateRanges = {
    'Hoy': [moment(), moment()],
    'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Última semana': [moment().subtract(6, 'days'), moment()],
    'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
    'Este mes': [moment().startOf('month'), moment().endOf('month')],
    'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
}

export default function SummaryComponent(props) {
    const [getTitleQuery] = useState([
        {
            title: "Este mes",
        },
        {
            title: "Este mes",
        },
        {
            title: "Este mes",
        },
        {
            title: "Este mes",
        },
        {
            title: "Este mes",
        }
    ]);
    const [getTotalSpent, setTotalSpent] = useState(0);
    const [getTotalQuery, setTotalQuery] = useState(0);
    const [getTotalIdentity] = useState(0);
    const [getTotalJudicial] = useState(0);
    const [getPieGraph, setPieGraph] = useState([]);
    const [graficapie, setGraficaPie] = useState([]);
    const [getLineGraph, setLineGraph] = useState([]);
    const dateDefaultStart = dateRanges['Este mes'][0];
    const dateDefaultEnd = dateRanges['Este mes'][1];
    const dateFormat = "YYYY-MM-DD";
    const lineOptions = {
        scales: {
            yAxes: [{
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: value => FormatCOPNumber(value)
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: tooltipItems => FormatCOPNumber(tooltipItems.yLabel)
            }
        }
    }
    let lineGraph = {
        labels: getLineGraph.map((item) => moment(item.date).format("MMM-DD")),
        datasets: [
            {
                label: 'Consultas',
                backgroundColor: '#599930',
                pointBackgroundColor: 'rgba(111,211,1,0.8)',
                data: getLineGraph.map((item) => item.spend),

            },
            {

                label: 'Costos',
                backgroundColor: 'rgba(247,107,28,0.6)',
                pointBackgroundColor: 'rgba(247,107,28,0.8)',
                data: getLineGraph.map((item) => item.total)
            },

        ],
        borderColor: 'rgba(0, 0, 0, 0)'
    };

    let pieGraph = {
        labels: graficapie.map((item) => item.type),
        datasets: [{
            data: graficapie.map((item) => item.total),
            // data: [100],
            backgroundColor: [

                '#006d46',
                '#599930',
                '#2ca880',
                '#0c9b8d',
                "#85fafded",
                "#439fc4",
                "#106491",
                "#014171",


            ],
            hoverBackgroundColor: [

                '#006d46',
                '#599930',
                '#2ca880',
                '#0c9b8d',
                "#85fafded",
                "#439fc4",
                "#106491",
                "#014171",



            ],
            borderColor: 'rgba(0, 0, 0, 0)',

        }]
    };



    useEffect(() => {
        funSendDataSummary(dateDefaultStart.format(dateFormat), dateDefaultEnd.format(dateFormat));
        grafica()
    }, [dateDefaultStart, dateDefaultEnd])

    // initialize library daterangepicker for items selector querys...
    useEffect(() => {
        // initialize text for dat range picker
        funDateRangePicker('.query0')
        funDateRangePicker('.query1')
        funDateRangePicker('.query2')
        funDateRangePicker('.query3')
        funDateRangePicker('.query4')
    })

    // function send and received data graph
    const funSendDataSummary = async (date_start, date_end, type_query = 0) => {
        let resSummary = await SummaryAsync({ date_start, date_end, type_query });
        console.log(resSummary)
        if (!resSummary.error) {
            try {
                switch (type_query) {
                    case 0:
                        setTotalQuery(resSummary.data[0].count_query);
                        setTotalSpent(resSummary.data[0].sum_balance);
                        setPieGraph(resSummary.data[0].query_pie);
                        setLineGraph(resSummary.data[0].query_bar);
                        break;
                    case 1:
                        setLineGraph(resSummary.data);
                        break;
                    case 2:
                        // setPieGraph(resSummary.data);
                        break;
                    case 3:
                        setTotalQuery(resSummary.data);
                        break;
                    case 4:
                        setTotalSpent(resSummary.data);
                        break;
                    default:
                        break;
                }
            } catch (error) { }
        } else {
            AlertSwal(resSummary.message)
        }
    }
    const grafica = async () => {

        let res = await CountQuery();
        console.log("res", res.data)
        var total = 0

        var identidad = 0
        var fraude = 0
        var huella = 0
        var bin = 0
        var laft = 0
        var imagen = 0
        var score = 0
        var ip = 0
        var laboral = 0
        var SMSMasivo = 0
        res.data.map((item, i) => {


            if (item.name == "Identidad y antecedentes (Colombia)") {
                identidad = item.total_consumed
                total = total + item.total_consumed
            }
            else if (item.name == "Fraude") {
                fraude = item.total_consumed
                total = total + item.total_consumed
            }
            else if (item.name == "Huella digital (Mundial)") {
                huella = item.total_consumed
                total = total + item.total_consumed
            }
            else if (item.name == "Validación BIN") {
                bin = item.total_consumed
                total = total + item.total_consumed
            }
            else if (item.name == "LA/FT (Mundial)") {
                laft = item.total_consumed
                total = total + item.total_consumed
            }
            else if (item.name == "Imagen perfil") {
                imagen = item.total_consumed
                total = total + item.total_consumed
            }
            else if (item.name == "Score de riesgo") {
                score = item.total_consumed
                total = total + item.total_consumed
            }
            else if (item.name == "Validación IP") {
                ip = item.total_consumed
                total = total + item.total_consumed
            }
            else if (item.name == "Huella digital 2") {
                laboral = item.total_consumed
                total = total + item.total_consumed
            }
            else if (item.name == "SMS Masivo") {
                SMSMasivo = item.total_consumed
                total = total + item.total_consumed
            }

            /*  else {
 
                 total = total + item.total_consumed
             } */
        })
        let totalquerys = [
            {
                type: "Huella digital",
                total: huella
            },

            {
                type: "Identidad",
                total: identidad
            },
            {
                type: "Validación IP",
                total: ip
            },
            {
                type: "Validación BIN",
                total: bin
            },
            {
                type: "Score de riesgo",
                total: score
            },
            {
                type: "Imagen perfil",
                total: imagen
            },
            {
                type: "Informacion laboral",
                total: laboral
            },
            {
                type: "LA/FT",
                total: laft
            },
            {
                type: "Fraude",
                total: fraude
            },
            {
                type: "SMS Masivo",
                total: SMSMasivo
            },


        ]
        console.log(totalquerys.type)
        setGraficaPie(totalquerys)
    }

    // function for initialize date range picker selector
    const funDateRangePicker = selector => {
        $(selector).daterangepicker({
            opens: 'left',
            locale: {
                "format": "MM/DD/YYYY",
                "separator": " - ",
                "applyLabel": "Aplicar",
                "cancelLabel": "Cancelar",
                "fromLabel": "Desde",
                "toLabel": "Hasta",
                "customRangeLabel": "Personalizado",
                "weekLabel": "S",
                "daysOfWeek": [
                    "Do",
                    "Lu",
                    "Ma",
                    "Mi",
                    "Ju",
                    "Vi",
                    "Sa"
                ],
                "monthNames": ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                "firstDay": 1
            },
            startDate: dateDefaultStart,
            endDate: dateDefaultEnd,
            ranges: dateRanges,
        }, function (s, e) {
            funChangeDate(selector, s, e);
        });

    }

    // function get change date range picker
    const funChangeDate = (selector, start, end) => {
        let typeQuery = 0;
        $(`${selector} span`).html(`${start.format(dateFormat)} - ${end.format(dateFormat)}`);
        // // lets set the label text: 
        for (let prop in dateRanges) {
            if (dateRanges[prop][0].format(dateFormat) === start.format(dateFormat) && dateRanges[prop][1].format(dateFormat) === end.format(dateFormat)) {
                $(`${selector} span`).html(prop);
            }
        }
        switch (selector) {
            case '.query0':
                // query total spent
                typeQuery = 4;
                break;
            case '.query1':
                // query la/ft
                typeQuery = 3;
                break;
            case '.query2':
                typeQuery = 2;
                break;
            case '.query3':
                typeQuery = 5
                break;
            case '.query4':
                // query line graph
                typeQuery = 1;
                break;
            default:
                break;
        }
        funSendDataSummary(start.format(dateFormat), end.format(dateFormat), typeQuery);
    }



    const funRenderOneColumns = () => {
        return (
            <>
                <Col md={6} className="mt-2 mb-2">
                    <Card body style={{ backgroundColor: 'rgb(51, 54, 70)', border: 'none' }}>
                        <CardTitle className="d-flex justify-content-between" style={{ fontWeight: 'bold' }}>Consultas
                            <span className={`query4`}>
                                <span style={{ fontSize: 11, fontWeight: 'normal' }} >{getTitleQuery[4].title} </span>&nbsp;
                                <i className="fa fa-caret-down" style={{ fontSize: 11 }} />&nbsp;&nbsp;&nbsp;
                            </span>
                        </CardTitle>
                        <Line data={lineGraph} options={lineOptions} />
                    </Card>
                </Col>
                <Col md={6} className="mt-2 mb-2">
                    <Card body style={{ backgroundColor: 'rgb(51, 54, 70)', border: 'none' }}>
                        <CardTitle style={{ fontWeight: 'bold' }}>Consultas por tipo</CardTitle>
                        <Pie data={pieGraph} />
                    </Card>
                </Col>
            </>
        );
    }


    const funRenderTwoColumns = () => {
        let listTotalQuery = [
            {
                name: 'Total gastados',
                value: FormatCOPNumber(getTotalSpent)
            },
            {
                name: 'Consultas LA/FT',
                value: getTotalQuery
            },
            {
                name: 'Consultas de identidad',
                value: getTotalIdentity
            },
            {
                name: 'Consultas judiciales',
                value: getTotalJudicial
            }
        ]
        return (
            <>
                {
                    listTotalQuery.map((item, i) => {
                        funDateRangePicker(`.query${i}`)
                        return (
                            <Col md={3} className="mt-2 mb-2" key={i}>
                                <Card body style={{ backgroundColor: 'rgb(51, 54, 70)', border: 'none', height: '20vh' }}>
                                    <CardTitle className={i < 2 ? `query${i} text-right` : ''}>
                                        {i < 2 && <span style={{ fontSize: 11 }}> {getTitleQuery[i].title} </span>}&nbsp;
                                        {i < 2 && <i className="fa fa-caret-down" style={{ fontSize: 11 }} />}&nbsp;&nbsp;&nbsp;
                                    </CardTitle>
                                    <h4 style={{ fontWeight: 'bold' }}>{i < 2 ? item.value : ''}</h4>
                                    <p style={{ fontSize: 11 }} >{i < 2 ? item.name : ''}</p>
                                </Card>
                            </Col>
                        )
                    })
                }
            </>
        );
    }

    return (
        <>
            <NavComponent title="Resumen" name={props.resProfile.name} photo={props.resProfile.photo} />
            <div className="animated fadeIn content-scroll-active-y">
                <Row className="text-white ">
                    {funRenderOneColumns()}
                </Row>
                <Row className="mt-2 mb-4 text-white text-center">
                    {funRenderTwoColumns()}
                </Row>
            </div>
        </>
    );
}