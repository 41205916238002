import React, { useState, useEffect } from 'react';
import NavComponent from './Nav';
import {
    Button, Col, Container, Card, CardTitle, Form, FormGroup, Input, Label, Row
} from 'reactstrap';
import { GetRols, GetListTypeDocument, ProfileAsync, GetListEmployees, GetFormProfileEmployee, ChangeStatusAsync } from '../managers/ApiManager';
import { AlertSwal, FormatDocument, FormatPhoneNumber, GetDigits } from '../managers/HelperManager';

export default function UsersComponent(props) {
    const [message, setMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const URL_NOT_FOUND = "https://validu-media.s3.amazonaws.com/dev/employees/placeholder.png";
    const [getRols, setRols] = useState([]);
    const [getListEmployees, setListEmployees] = useState([]);
    const [getTypeDocument, setTypeDocument] = useState([]);
    const [getTypeSendProfile, setTypeSendProfile] = useState("register");
    const [getIdUpdateProfile, setIdUpdateProfile] = useState(0);
    const [getTitleForm, setTitleForm] = useState("Agregar nuevo empleado");
    const [getBtnText, setBtnText] = useState("Agregar");
    const [getName, setName] = useState("");
    const [getTypeDocumentId, setTypeDocumentId] = useState();
    const [getDocumentNumber, setDocumentNumber] = useState("");
    const [getEmail, setEmail] = useState("");
    const [getPhone, setPhone] = useState("");
    const [getRolesId, setRolesId] = useState(1);
    const [getUsername, setUsername] = useState("");
    const [getPhoto, setPhoto] = useState(URL_NOT_FOUND);




    useEffect(() => {

        funGetListEmployees();
        funGetRols();
        funGetListTypeDocument();
    }, []);



    // get list rols
    const funGetRols = async () => {
        let resRols = await GetRols();
        if (resRols) setRols(resRols);
    }

    // get list type document
    const funGetListTypeDocument = async () => {
        let resTypeDocument = await GetListTypeDocument();
        if (resTypeDocument) setTypeDocument(resTypeDocument);

    }
    //  selecionar el tipo de documento 
    const SelectTypeDocumenthandle = (e) => {
        if (e === "CC") {
            setTypeDocumentId(2)

        } else if (e === "CE") {
            setTypeDocumentId(4)

        } else if (e === "PPN") {
            setTypeDocumentId(5)
        }
    }


    // get list rols
    const funGetListEmployees = async () => {
        setLoading(true)
        let resListEmployees = await GetListEmployees();
        setLoading(false)

        if (resListEmployees) setListEmployees(resListEmployees.data);
        if (loading === false && resListEmployees.data.length === 0) {
            setMessage(true);
        }
    }




    const funFileSelected = input => {
        var ext = input.files[0]['name'].substring(input.files[0]['name'].lastIndexOf('.') + 1).toLowerCase();
        if (input.files && input.files[0] && (ext === "gif" || ext === "png" || ext === "jpeg" || ext === "jpg")) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setPhoto(e.target.result)
            }
            reader.readAsDataURL(input.files[0]);
        } else {
            setPhoto(URL_NOT_FOUND);
        }
    }




    const funProfile = async e => {
        e.preventDefault();


        console.log("files tienes", e.target.photo.files);


        const file = e.target.photo.files.length > 0 ? e.target.photo.files[0] : null;

        let method = "POST",
            jsonData = {
                name: getName,
                type_document_id: getTypeDocumentId,
                document_number: GetDigits(getDocumentNumber),
                email: getEmail,
                phone: GetDigits(getPhone),
                roles_id: getRolesId,
                username: getUsername,
                password: e.target.password.value,
                photo: file
            };

        if (getTypeSendProfile === "update") {
            jsonData.employee_id = getIdUpdateProfile;
            method = "PUT";
        }

        let resProfile = await ProfileAsync(jsonData, method);
        console.log(resProfile.message);
        setListEmployees([]);
        funGetListEmployees();
        AlertSwal(!resProfile.error ? "PROFILE_USER" : resProfile.message, getTypeSendProfile);
        /* AlertSwal(!resProfile.error ? "PROFILE_USER" : resProfile.message, getTypeSendProfile); */
    }

    const funEditFormProfile = async employee_id => {
        let resEditFormProfile = await GetFormProfileEmployee(employee_id);
        if (!resEditFormProfile.error) {
            let data = resEditFormProfile.data[0];
            let photo = data.photo === URL_NOT_FOUND ? URL_NOT_FOUND : "https://validu-media.s3.amazonaws.com/dev/" + data.photo;
            setIdUpdateProfile(employee_id);
            setTypeSendProfile("update")
            setBtnText("Actualizar");
            setTitleForm("Editar datos del perfil");
            setName(data.name);
            setTypeDocumentId(data.type_document_id);
            setDocumentNumber(data.document_number);
            setEmail(data.email);
            setPhone(data.phone);
            setRolesId(data.roles_id);
            setUsername(data.username);
            setPhoto(photo);
            // document.getElementById('asgnmnt_file_img').src = "https://validu-media.s3.amazonaws.com/dev/"+getPhoto;
        }
    }

    const funChangeStatus = async (e, employee_id) => {
        const employee_status = e.target.checked ? 1 : 2;
        let resChageStatus = await ChangeStatusAsync({ employee_id, employee_status });
        AlertSwal(!resChageStatus.error ? "CHANGE_STATUS" : resChageStatus.message, employee_status);
    }


    return (
        <>
            <NavComponent title="Empleados" name={props.resProfile.name} photo={props.resProfile.photo} />
            <div className="animated fadeIn mt-2 mb-2 content-scroll-active-y">
                <Row className="text-white ">
                    <Col md={5} xs={12}>
                        <Card body className="user-content-form">
                            <CardTitle >
                                {getTitleForm}
                            </CardTitle>
                            <Form onSubmit={e => funProfile(e)} className="mt-3">
                                <Row form>
                                    <div className="col-md-4">
                                        <Col md={4} >
                                            <FormGroup>
                                                <Label for="upload-photo" className="user-upload-photo">
                                                    {/* <i className="fas fa-camera icon-camera-user"></i> */}
                                                    <img name="photo" className="rounded-circle user-avatar" src={getPhoto} id="asgnmnt_file_img" alt="avatar" />
                                                </Label>
                                                <Input type="file" name="photo" id="upload-photo" onChange={e => funFileSelected(e.target)}
                                                />
                                            </FormGroup>

                                        </Col>
                                    </div>
                                    <Col md={8}>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Input type="text" name="name" placeholder="Nombre"
                                                        required onChange={e => setName(e.target.value)} value={getName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    {/*  <Input type="select" name="type_document_id"
                                                        required onChange={e => setTypeDocumentId(e.target.value)}
                                                        value={getTypeDocumentId} defaultValue={getTypeDocument.name}
                                                    >
                                                        {getTypeDocument.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name.toUpperCase()}</option>)}
                                                    </Input> */}
                                                    <Input type="select" name="type_document_id"

                                                        required onChange={e => SelectTypeDocumenthandle(e.target.value)}
                                                        defaultValue="placeholder"
                                                    >
                                                        <option hidden disabled={true} value="placeholder" >Tipo De Documento</option>
                                                        <option value="CC" > CC - Cédula De Ciudadanía </option>
                                                        <option value="CE" >CE - Cédula De Extranjería</option>
                                                        <option value="PPN">PPN - Pasaporte</option>

                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>

                                                <FormGroup>
                                                    <Input type="text" name="document_number" placeholder="Número de documento" maxLength="13"
                                                        required onChange={e => setDocumentNumber(e.target.value)}
                                                        value={(getDocumentNumber)}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input type="email" name="email" placeholder="Correo electrónico"
                                                required onChange={e => setEmail(e.target.value)}
                                                value={getEmail}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <Row form>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Input type="tel" name="phone" placeholder="Número de celular" required maxLength="14"
                                                        onChange={e => setPhone(FormatPhoneNumber(e.target.value))}
                                                        value={getPhone}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Input type="select" name="roles_id"
                                                        required onChange={e => setRolesId(e.target.value)}
                                                        value={getRolesId}
                                                    >
                                                        {getRols.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row form>
                                            <Col md={7}>
                                                <FormGroup>
                                                    <Input type="text" name="username" placeholder="Usuario" required
                                                        onChange={e => setUsername(e.target.value)}
                                                        value={getUsername}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Input type="password" name="password" placeholder="Contraseña" required={getTypeSendProfile !== "update"}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Button className="btn-green mt-2 mb-3" block  >
                                                {getBtnText}
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                    <Col md={7} xs={12}>
                        <Container>
                            <Row className="mt-4">
                                <Col md={8} xs={6}>
                                    Empleados agregados
                                </Col>
                                <Col md={2} xs={3}>
                                    Rol
                                </Col>
                                <Col md={2} xs={3}>
                                    Activo
                                </Col>
                            </Row>
                            <div className="user-content-list">
                                {getListEmployees.length > 0 && getListEmployees.map((item, i) => {
                                    return (
                                        <div key={i} >
                                            <Row className="mt-4" >
                                                <Col md={8} xs={6} onClick={() => funEditFormProfile(item.id)} className="column-edit-profile">
                                                    <Row>
                                                        <Col md={2} xs={2}>
                                                            <img className="rounded-circle" src={item.photo ? "https://validu-media.s3.amazonaws.com/dev/" + item.photo : URL_NOT_FOUND} width="55" height="55" alt="avatar" />
                                                        </Col>
                                                        <Col md={10} xs={10}>
                                                            <span style={{
                                                                fontSize: 14
                                                            }}>
                                                                {item.first_name} <br />
                                                                {item.phone}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={2} xs={3}>
                                                    <span className="span-rol" >
                                                        {item.rol}
                                                    </span>
                                                </Col>
                                                <Col md={2} xs={3}>
                                                    <Label className="switch">
                                                        <Input type="checkbox" onChange={e => funChangeStatus(e, item.id)} defaultChecked={item.employee_status === 2 ? false : true} />
                                                        <span className="slider round"></span>
                                                    </Label>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </div>
                                    );
                                })}
                                {
                                    loading === true && <div className="mt-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="/assets/loadingIcon.gif" alt="img loading" width="40" height="40" />
                                    </div >
                                }
                                {
                                    message === true && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 200 }}>
                                        <h2 style={{ fontSize: "bold", color: "white" }} > No tienes ningun empleado</h2>
                                    </div >
                                }
                            </div>
                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    );
}