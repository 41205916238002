import numeral from "numeral";
import swal from "sweetalert";

export const AlertSwal = (message, textValue = "") => {
  let title = "",
    text = "",
    icon = "";
  if (message === "EMPTY_FIELD") {
    text = "Por favor completa los campos requeridos";
    icon = "warning";
  } else if (message === "ERROR_SERVER") {
    title = "Ups!";
    text =
      "Ha ocurrido un error inesperado en la aplicación. Por favor intente nuevamente";
    icon = "error";
  } else if (message === "NOT_ENOUGH_BALANCE") {
    text = "No tienes saldo disponible para realizar esta consulta";
    icon = "info";
  } else if (message === "SELECT_PAYMENT_METHOD") {
    text = "Debes seleccionar un metodo de pago para continuar";
    icon = "info";
  } else if (message === "MIN_MAX_VALUE") {
    text = textValue;
    icon = "warning";
  } else if (message === "RELOAD_SUCCESS") {
    text = "Recarga realizada con éxito";
    icon = "success";
  } else if (message === "RELOAD_REJECT") {
    title = "Ups!";
    text = "Esta recarga ha sido rechazada";
    icon = "warning";
  } else if (message === "CHANGE_STATUS") {
    text = `Usuario ${textValue === 1 ? "activado" : "desactivado"}`;
    icon = "success";
  } else if (message === "PROFILE_USER") {
    text = `Usuario ${textValue === "register" ? "registrado" : "actualizado"
      } con éxito`;
    icon = "success";
  } else if (message === "USER_INACTIVE") {
    text = "El usuario se encuentra inactivo";
    icon = "info";
  } else if (message === "MINIMUM_RELOAD") {
    text =
      "No cuenta con el monto minimo de recarga, por favor intente nuevamente";
    icon = "warning";
  } else if (message === "El Usuario Ya Existe") {
    text = "El usuario ya se encuentra registrado";
    icon = "info";
  } else if (message === "El Email Ya Existe") {
    text = "El email ya se encuentra registrado";
    icon = "info";
  } else if (message === "El Número De Celular Ya Existe") {
    text = "el numero de celular ya se encuentra registrado";
    icon = "info";
  } else if (message === "El Número De Documento Ya Existe") {
    text = "El numero de documento ya esta registrado";
    icon = "info";
  } else if (message === "Verificar campos" || message === "CHECK_FIELDS") {
    text = "Hubo un error verifica que los datos esten correctos";
    icon = "info";
  } else if (message === "CORREO_INVALIDO") {
    text = "Correo Electrónico ingresado es invalido  ";
    icon = "info";
  } else if (message === "IP_INVALIDO") {
    text = "El IP ingresado es invalido";
    icon = "info";
  } else if (message === "MENSAJE_SELECT_HUELLA") {
    title = "Informacion importante";
    text =
      "Ingrese toda la información que tenga sobre la persona que está buscando. Se requiere al menos un campo para realizar la búsqueda";
    icon = "info";
  }

  swal({ title, text, icon });
};

export const validateEmail = (email) => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return true;
  }
  return false;
};
export const validateIP = (IP) => {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      IP
    )
  ) {
    return true;
  }
  return false;
};

// capitalize the first letter
export const FirstCapitalLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

// determine the time elapsed to refresh the token
export const diff_minutes = function (dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

/** Este funcion formatea un numero a COP devuelve por ejemplo: $10.000 */
export function FormatCOPNumber(number, commas = false) {
  try {
    //algunos inputs de la aplicacion envian textos con "$" para formatearlos,
    //pero estos no se les puede hacer parse int. Por eso lo quitamos si no funciona.
    if (number.toString().includes("$")) {
      number = number.toString().replace(/\D/g, "");
    }
    //si number esta vacio en este momento devolvemos "$0"
    if (!number) {
      return "$0";
    }
    //esta linea mira si debemos agregar un signo negativo al numero cuando es menor a 0
    let prefix = parseInt(number) >= 0 ? "$" : "-$";
    if (!commas)
      return (
        prefix +
        numeral(number.toString().replace(/\D/g, ""))
          .format("0,0")
          .replace(/,/g, ".")
      );
    else return prefix + numeral(number).format("0,0");
  } catch (error) {
    return "";
  }
}

export function ResponsePDF(blob) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], { type: "application/pdf" });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  link.download = "reporte.pdf";
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

// function formater date
export function FormatExpirationDate(numberString) {
  try {
    return numberString
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // To handle 3/ > 03/
      )
      .replace(
        /^(0[1-9]{1}|1[0-2]{1})$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1]{1})([3-9]{1})$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(\d)\/(\d\d)$/g,
        "0$1/$2" // To handle 1/11 > 01/11
      )
      .replace(
        /^(0?[1-9]{1}|1[0-2]{1})([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^([0]{1,})\/|[0]{1,}$/g,
        "0" // To handle 0/ > 0 and 00 > 0
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 /
      );
  } catch (error) {
    console.log("error on expiration date formating");
    console.log(error);
    return numberString;
  }
}

// function formater phone number
export function FormatPhoneNumber(number) {
  var l = number.slice(-1);
  if (l === ")" || l === "-") return number.slice(0, -1);

  var t = number.replace(/\D/g, ""); //quitamos los caracteres que no sean digitos

  t = t.substring(0, 11); // el maximo de digitos para el numero de telefono es 12
  t = t.replace(/^(\d{3})/g, "(" + t.substring(0, 3) + ")"); //agregamos los parentesis.
  t = t.replace(/\)(\d{3})/g, ") " + t.substring(5, 8) + "-"); //agregamos un espacio y el primer guion
  return t;
}

// function formater credit card number
export function FormatCreditCard(credit_card_number) {
  // var v = credit_card_number.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
  // var matches = v.match(/\d{4,16}/g);
  // var match = matches && matches[0] || ''
  // var parts = []
  // var i, len;
  // for (i = 0, len = match.length; i < len; i += 4) {
  //     parts.push(match.substring(i, i + 4))
  // }
  // if (parts.length) {
  //     credit_card_number = parts.join(' ')
  // }
}

// function formater document number
export function FormatDocument(number) {
  console.log("number", number);
  if (number === null || number === undefined || number === "") {
    return ""
  }
  try {
    return numeral(number.toString().replace(/\D/g, ""))
      .format("0,0")
      .replace(/,/g, ".");
  } catch (error) {
    return "";
  }
}

// function get value formater string or number
export function GetDigits(text, isDataNumeric = false) {
  return isDataNumeric
    ? parseInt(text.toString().replace(/\D/g, ""))
    : text.toString().replace(/\D/g, "");
}

export function urlVerificator() {
  let location = window.location;
  //console.log(location,'location');

  if (location.hostname !== "localhost") {
    if (location.protocol !== "https:") {
      location.href = location.href.replace("http://", "https://");
    }
  }
}
export function TitleCase(texto) {
  const re =
    /(^|[^A-Za-zÁÉÍÓÚÜÑáéíóúüñ])(?:([a-záéíóúüñ])|([A-ZÁÉÍÓÚÜÑ]))|([A-ZÁÉÍÓÚÜÑ]+)/gu;
  return texto.replace(
    re,
    (m, caracterPrevio, minuscInicial, mayuscInicial, mayuscIntermedias) => {
      const locale = ["es", "gl", "ca", "pt", "en"];
      if (mayuscIntermedias) return mayuscIntermedias.toLocaleLowerCase(locale);
      return (
        caracterPrevio +
        (minuscInicial
          ? minuscInicial.toLocaleUpperCase(locale)
          : mayuscInicial)
      );
    }
  );
}
export function FormatPhone(number) {
  if (number != undefined) {
    //console.log(number,'number');

    let string = number.toString();
    let phoneFormat = string.replace(/^(\d{3})(\d{3})(\d{}).*/, "($1) $2-$3"); //quitamos los caracteres que no sean digitos

    phoneFormat = phoneFormat.substring(0, 16); // el maximo de digitos para el numero de telefono es 12
    phoneFormat = phoneFormat.replace(
      /^(\d{3})/g,
      "(" + phoneFormat.substring(0, 3) + ")"
    ); //agregamos los parentesis.
    phoneFormat = phoneFormat.replace(
      /\)(\d{3})/g,
      ") " + phoneFormat.substring(5, 8) + "-"
    ); //agregamos un espacio y el primer guion

    return phoneFormat;
  }
}
