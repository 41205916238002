import React, { useState, useContext } from "react";
import { FunctionContext } from "../managers/FuntionContext";
import {
  Badge,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  Table,
} from "reactstrap";
import { ModalIdentidad, ModalIdentidadLoading } from "./ModalIdentidad.js";
import ConsultaHuella_digital from "../consultas/huella_digital";
import {
  QuerySarlaftAsync,
  GetTypeQuery,
  GetLogsQuery,
  GetFilterQuery,
  DownloadPDF,
  fetchPipl,
  QueryIdentidad,
  getIP,
  QueryIP,
  QueryBIN,
  QueryScore,
  QueryImage,
  QueryHuellaDigital2,
} from "../managers/ApiManager";
import ConsultaBin from "../consultas/bin";

import {
  AlertSwal,
  ResponsePDF,
  FormatDocument,
  TitleCase,
  FormatPhone,
} from "../managers/HelperManager";

import { useGlobal } from "../managers/store/Context";

import moment from "moment";
import { data } from "jquery";
import ReactFlagsSelect from "react-flags-select";
import swal from "sweetalert";
import { useHistory, Link, Route, Switch, Redirect } from "react-router-dom";

export default function ConsultaIdentidad(props) {
  const [getLogsQuery, setLogsQuery] = useState([]);
  const [getBtnText, setBtnText] = useState("Consultar");
  const [, dispatch] = useGlobal();
  const [idTypeQuery, setIdTypeQuery] = useState(props.idTypeQuery);
  var history = useHistory();
  const { funGetBalance } = useContext(FunctionContext);

  const [identidadItem, setIdentidadItem] = useState(null);
  const [getModalIdentidadLoading, setModalIdentidadLoading] = useState(false);
  const [getModalIdentidad, setModalIdentidad] = useState(false);

  const Consultaidentidad = async (e) => {
    try {
      e.preventDefault();
      setModalIdentidadLoading(true);
      setBtnText("Consultando...");
      let { Nombre, Apellido, document_number, document_type } = e.target;
      const DataName = await Nombre.value.toLowerCase();
      const dataApellido = await Apellido.value.toLowerCase();
      const dataCC = await document_number.value.toLowerCase();
      const documentType = await document_type.value.toLowerCase();
      if (DataName != "") {
        var paramsKeyName = "name=";
        var paramsname = `${DataName}&`;
      } else {
        var paramsKeyName = "";
        var paramsname = "";
      }
      if (dataApellido != "") {
        var paramsKeyLastName = "last_name=";
        var paramsLastName = `${dataApellido}&`;
      } else {
        var paramsKeyLastName = "";
        var paramsLastName = "";
      }

      const response = await QueryIdentidad(
        paramsKeyName,
        paramsname,
        paramsKeyLastName,
        paramsLastName,
        dataCC,
        documentType
      );
      if (response.error != true) {
        setIdentidadItem(response);
        setModalIdentidadLoading(false);
        setModalIdentidad(true);

        setBtnText("Consultar");
        funGetBalance();
        props.funLogsQuery();
      }
      if (response == "") {
        setIdentidadItem(response);
        setModalIdentidadLoading(false);
        setModalIdentidad(true);

        setBtnText("Consultar");
        funGetBalance();
        props.funLogsQuery();
      } else if (response.message == "NOT_ENOUGH_BALANCE") {
        swal({
          title: "No tienes saldo disponible para realizar esta consulta",
          text: "¿Deseas Recargar? ",
          icon: "info",
          buttons: {
            cancel: "No",
            confirm: "Si",
          },
        }).then((willDelete) => {
          if (willDelete) {
            var itemMenu = "reload";
            dispatch({ type: "SELECTED_ITEM_MENU", itemMenu });
          } else {
          }
        });
      }
      setModalIdentidadLoading(false);
      setBtnText("Consultar");
    } catch (error) {
      setModalIdentidadLoading(false);
      //AlertSwal('ERROR_SERVER')
      setBtnText("Consultar");
    }
  };

  const tipoConsulta = async (x) => {
    if (x == 1) {
      history.push("/consultar/la_ft");
    } else if (x == "3") {
      history.push("/consultar/huella_digital");
    } else if (x == "4") {
      history.push("/consultar/identidad");
    } else if (x == "5") {
      history.push("/consultar/validacion_IP");
    } else if (x == "6") {
      history.push("/consultar/validacion_BIN");
    } else if (x == "7") {
      history.push("/consultar/score_de_riesgo");
    } else if (x == "8") {
      history.push("/consultar/imagen_perfil");
    } else if (x == "9") {
      history.push("/consultar/informacion_laboral");
    } else if (x == "10") {
      history.push("/consultar/fraude");
    }
  };

  return (
    <div className="content-jumbotron  basket animated fadeIn mt-2 mb-2">
      <Form onSubmit={(e) => Consultaidentidad(e)}>
        <Row
          form
          style={{
            alignitems: "center",
          }}
        >
          <Col md={2}>
            <FormGroup>
              <Input
                type="select"
                name="type_query_id"
                defaultValue={`${idTypeQuery}`}
                onChange={(e) => tipoConsulta(e.target.value)}
                required
              >
                <option hidden disabled={true} value="0">
                  Tipo de consulta
                </option>
                <option value="1"> LA / FT </option>
                {/*                 <option value="3"> Huella digital </option>
 */}                <option value="4"> Identidad </option>
                <option value="5"> Validación IP </option>
                <option value="6"> Validación BIN </option>
                <option value="7"> Score de riesgo </option>
                <option value="8"> Imagen perfil </option>
                <option value="9"> Informacion laboral </option>
                <option value="10"> Fraude </option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input type="text" name="Nombre" placeholder="Nombre " />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input type="text" name="Apellido" placeholder="Apellido " />
            </FormGroup>
          </Col>
          <Col md={1}>
            <FormGroup>
              <Input
                type="select"
                name="document_type"
                defaultValue={`${idTypeQuery}`}
                required
              >
                <option hidden disabled={true} value={null}>
                  Tipo de Documento
                </option>
                <option value="0">CC</option>
                <option value="1">CE</option>
                <option value="2">TI</option>
                <option value="3">NIT</option>
                <option value="4">PA</option>
                <option value="5">PPT</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Input
                type="number"
                id="number"
                name="document_number"
                placeholder="Cédula"
                required
              />
            </FormGroup>
          </Col>
          <Col
            md={2}
            style={{
              marginleft: 10,
            }}
          >
            <FormGroup>
              <Button
                className="btn-green"
                disabled={getBtnText === "Consultando..."}
                block
              >
                {getBtnText}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      {getModalIdentidad !== false && (
        <div>
          <ModalIdentidad
            item={identidadItem}
            modal={getModalIdentidad}
            hideModal={() => setModalIdentidad(false)}
          />
        </div>
      )}
      <ModalIdentidadLoading modal={getModalIdentidadLoading} />
    </div>
  );
}
