import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { GetFilterQuery } from "../managers/ApiManager";
import moment, { relativeTimeRounding } from 'moment';
import { TitleCase } from "../managers/HelperManager";


const styles = StyleSheet.create({
    page: {
        padding: "25px"

    },
    section: {
        backgroundColor: "#d5d5d7",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        margin: "10px 20px 10px 20px",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",



    },
    header: {
        paddingLeft: "15px",
        display: "flex",
        width: "100%",
        marginBottom: "10px"
    },
    data: {
        paddingLeft: "15px",
        flexDirection: 'row',
        width: "100%"
    },
    textTitule: {
        fontSize: "12px",
        fontWeight: "medium"
    },
    BodyView: {
        margin: "0px 20px 00px 20px",
        paddingLeft: "15px",


    },
    linia: {
        borderBottom: "2px solid gray"
    },
    liniaDivisoria: {
        borderBottom: "1px solid gray",
        marginBottom: "15px",

    },
    tituloPrincipal: {
        marginTop: "5px",
        fontSize: "18px",

    },
    tituloSecundario: {
        fontSize: "16px",
        marginBottom: "5px",
        marginTop: "10px",
        color: "black"


    },
    titulo1: {
        color: "#777",
        fontSize: "11px",

        marginTop: "5px",


    },
    Text1: {
        fontSize: "14px",
        marginBottom: "5px",


    },
    CentrarText: {

        marginTop: "30%",
        marginLeft: "-25px",
        display: "flex",
        alignContent: "center",
        alignItems: "center"
    }

});

const IdentidadPDF = (props) => {
    console.log("aqui esta pros:  ", props)

    const [data, setData] = useState(null);
    useEffect(() => {
        if (props.show) {
            render();
        }
    }, [props.show]);


    const render = async () => {
        let res = await GetFilterQuery(props.id)
        setData(res)
        console.log(res)

    }


    return props.show && data !== null ? (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.header}>
                        <Text >Identidad</Text>

                    </View>
                    <View style={styles.data}>
                        <Text style={styles.textTitule}>ID: </Text>
                        <Text style={styles.textTitule}>{data.data[0].id} </Text>
                        <Text style={styles.textTitule}> | </Text>
                        <Text style={styles.textTitule}>{data.data[0].employee} </Text>

                        <Text style={styles.textTitule}> | </Text>
                        <Text style={styles.textTitule}>{moment(data.data[0].date_created).format(('L h:mm:ss a'))}</Text>
                    </View>

                </View >
                {data.data[0].response &&
                    <View style={styles.BodyView}>
                        <View style={styles.liniaDivisoria}>
                            <Text style={styles.tituloPrincipal}>Datos</Text>
                            <Text style={styles.titulo1}>Nombre Completo</Text>
                            <Text style={styles.Text1}>{TitleCase(data.data[0].response.name)}</Text>
                            <Text style={styles.titulo1}>Documento</Text>
                            <Text style={styles.Text1}>{data.data[0].response.cc}</Text>
                            <Text style={styles.titulo1}>Estatus de la persona</Text>
                            <Text style={styles.Text1}>{data.data[0].response.status === "records" ? "Antecedentes Penales" : data.data[0].response.status === "expired" ? "Documento vencido" : "Sin Antecedentes"}</Text>
                            <Text style={styles.titulo1}>Mensaje</Text>
                            <Text style={styles.Text1}>{TitleCase(data.data[0].response.message)}</Text>
                        </View>
                        {data.data[0].response.records !== undefined &&
                            <View>
                                <View>
                                    <Text>Antesedentes</Text>
                                </View>
                                <View>


                                    {data.data[0].response.records.INHABILIDADES !== undefined && data.data[0].response.records.INHABILIDADES.map((item, i) => {
                                        return (
                                            <View style={styles.liniaDivisoria}>
                                                <Text>Inhabilidataciones</Text>
                                                <Text style={styles.titulo1}>Inhabilidad legal</Text>
                                                <Text style={styles.Text1}>{TitleCase(item["Inhabilidad legal"])}</Text>
                                                <Text style={styles.titulo1}>Módulo</Text>
                                                <Text style={styles.Text1}>{TitleCase(item["Módulo"])}</Text>
                                                <Text style={styles.titulo1}>SIRI</Text>
                                                <Text style={styles.Text1} >{item["SIRI"]}</Text>
                                                <Text style={styles.titulo1}>Desde</Text>
                                                <Text style={styles.Text1}>{item["Fecha de inicio"]}</Text>
                                                <Text style={styles.titulo1}>Hasta</Text>
                                                <Text style={styles.Text1}>{item["Fecha fin"]}</Text>
                                            </View>
                                        )
                                    }
                                    )
                                    }

                                </View>


                                {data.data[0].response.records.Sanciones !== undefined && data.data[0].response.records.Sanciones.map((item, i) => {
                                    return (
                                        <View style={styles.liniaDivisoria}>
                                            <Text>Sanciones</Text>
                                            <Text style={styles.titulo1}>Sanción</Text>
                                            <Text style={styles.Text1}>{TitleCase(item["Sanción"])}</Text>
                                            <Text style={styles.titulo1}>Clase</Text>
                                            <Text style={styles.Text1}>{TitleCase(item["Clase"])}</Text>
                                            <Text style={styles.titulo1}>Suspendida</Text>
                                            <Text style={styles.Text1}>{TitleCase(item["Suspendida"])}</Text>
                                            <Text style={styles.titulo1}>Término</Text>
                                            <Text style={styles.Text1}>{TitleCase(item["Término"])}</Text>

                                        </View>
                                    )
                                }
                                )
                                }
                                {data.data[0].response.records.Instancias !== undefined && data.data[0].response.records.Instancias.map((item, i) => {
                                    return (
                                        <View style={styles.liniaDivisoria}>
                                            <Text>Instancias</Text>
                                            <Text style={styles.titulo1}>Nombre</Text>
                                            <Text style={styles.Text1}>{TitleCase(item["Nombre"])}</Text>
                                            <Text style={styles.titulo1}>Autoridad</Text>
                                            <Text style={styles.Text1}>{TitleCase(item["Autoridad"])}</Text>
                                            <Text style={styles.titulo1}>Fecha providencia</Text>
                                            <Text style={styles.Text1}>{TitleCase(item["Fecha providencia"])}</Text>
                                            <Text style={styles.titulo1}>fecha efecto Juridicos</Text>
                                            <Text style={styles.Text1}>{TitleCase(item["fecha efecto Juridicos"])}</Text>

                                        </View>
                                    )
                                }
                                )
                                }

                                {data.data[0].response.records.Delitos !== undefined &&
                                    <View>
                                        <Text>Delitos</Text>
                                    </View>
                                }

                                {data.data[0].response.records.Delitos !== undefined && data.data[0].response.records.Delitos.map((item, i) => {
                                    return (


                                        <View style={styles.liniaDivisoria}>
                                            <Text style={styles.titulo1}>Descripción del Delito</Text>
                                            <Text style={styles.Text1}>{TitleCase(item["Descripción del Delito"])}</Text>
                                        </View>

                                    )
                                }
                                )
                                }
                            </View>}
                    </View>

                }



                {data.data[0].response.length == 0 &&
                    <View style={styles.CentrarText}><Text>No se encontraron datos</Text>
                    </View>}
            </Page>
        </Document >
    ) : ("")
}

export { IdentidadPDF }


