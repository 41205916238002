
import React, { useState, useEffect } from 'react';
import { Alert, Button, Form, FormGroup, Input, } from 'reactstrap';

import { RegisterUserAsync } from "../managers/ApiManager";
import { LoginAsync } from "../managers/ApiManager";
import { urlVerificator } from '../managers/HelperManager';

import { Link } from 'react-router-dom';

// componente para la vista de registro
export default function RegisterUser() {
  const [accept, setAccept] = useState()
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [type_document_id, setTypeDocumentId] = useState(0);
  const [company_type, setTypeCompanyId] = useState();
  const [username, setUsername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [document_number, setDocumentNumber] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirm] = useState("");
  const [roles_id, setRoles_id] = useState();


  useEffect(() => {
    urlVerificator();
  }, []);


  // aqui se validan todos los campos de el registro   
  const validate = (setAccept) => {


    if (type_document_id === 0) {
      setMessage("escoja un tipo de entidad")
      setVisible(true)
      setAccept(false)

    } else if (username === "") {
      console.log("entro a el validate de username")
      setVisible(true)
      setMessage("por favor ingrese un nombre de usuario")
      setAccept(false)

    }


    else if (first_name === "") {
      if (company_type === 1) {
        setMessage("por favor ingrese un nombre");
      } else {
        setMessage("por favor ingrese el nombre del representante");
      }

      setVisible(true)
      setAccept(false)
    }

    else if (last_name === "") {
      if (company_type === 1) {
        setMessage("por favor ingrese un apellido");
        setVisible(true)

      } else {
        setMessage("por favor ingrese el apellido del representante");
        setVisible(true)

      }
      setVisible(true)
      setAccept(false)
    }

    else if (email === "") {
      setMessage("por favor ingrese un correo electronico");
      setVisible(true)
      setAccept(false)

    } else if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) === false) {
      setMessage("correo invalido");
      setVisible(true)
      setAccept(false)
    }
    else if (phone === "") {
      if (company_type === 1) {
        setMessage("por favor ingrese un celular");
      } else {
        setMessage("por favor ingrese el celular del representante");
      }
      setVisible(true)
      setAccept(false)

    } else if (/^\d{10}$/.test(phone) === false) {
      setMessage("número invalido");
      setVisible(true)
      return setAccept(false)

    } else if (document_number === "") {
      if (company_type === 1) {
        setMessage("por favor ingrese la cedula");
      } else {
        setMessage("por favor ingrese el NIT");
      }
      setVisible(true)
      setAccept(false)

    } else if (isNaN(document_number)) {
      if (company_type === 1) {
        setMessage("La Cédula no puede llevar letras o caracteres");
      } else {
        setMessage("El NIT no puede tener letras");
      }
      setAccept(false)
      setVisible(true)

    } else if (password === "") {
      setMessage("por favor ingrese una contraseña");
      setVisible(true)
      setAccept(false)

    } else if (password.length < 8) {
      setMessage("la contraseña debe tener minimo 8 caracteres");
      setVisible(true)
      return setAccept(false)

    } else if (password_confirmation === "") {
      setMessage("por favor ingrese la confirmacion de la  contraseña");
      setVisible(true)

    } else if (password !== password_confirmation) {
      setMessage("las contraseñas no coinciden ");
      setVisible(true)
      return setAccept(false)
    } else {
      return setAccept(true);
    }
  }



  const submitHandle = async () => {
    setTimeout(() => {
      setVisible(false)

    }, 4000);

    validate(setAccept)

    console.log(accept);
    console.log(visible);
    if (accept || visible === false) {
      let res = await RegisterUserAsync(type_document_id, company_type, username, first_name, last_name, email, phone, document_number, password, password_confirmation, roles_id);
      console.log(res);
      if (res.error) {
        setMessage(res.message)
        setVisible(true)
      }

      else {

        let response = await LoginAsync(username, password);
        if (response === null) {
          this.initSetState("Ha ocurrido un error, intente nuevamente por favor")
        }
        else if (response) {
          window.location.href = "/"
        }
      }
    }
  }
  const SelectTypeDocumenthandle = (e) => {

    if (e === "empresa") {
      setTypeCompanyId(2)
      setTypeDocumentId(1)
      setRoles_id(1)
    } else {
      setTypeDocumentId(3)
      setTypeCompanyId(1)
      setRoles_id(1)

    }

  }

  return (
    <div className="page-register bg-dark-content capi">
      <Link to="/">
        <img src="/assets/logo.png" className="img-register-logo" alt="img logo" />
      </Link>

      <Form className="form-register"  >
        <Alert color="danger" isOpen={visible === true}>
          {message}
        </Alert>
        <FormGroup>

          <Input type="select" name="typo_company" required onChange={e => SelectTypeDocumenthandle(e.target.value)} defaultValue=""  >
            <option hidden disabled={true} value="" >¿que tipo de entidad es?</option>
            <option value="empresa">Empresa</option>
            <option value="natural">Persona Natural</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Input type="text" name="username" placeholder="nombre de usuario" required onChange={e => setUsername(e.target.value.toLowerCase())} />
        </FormGroup>
        <FormGroup>
          <Input type="text" name="first_name"
            placeholder={type_document_id === 1 ? "Nombre del representante" : "Nombre"} required
            onChange={e => setFirstName(e.target.value.toLowerCase())} />
        </FormGroup>
        <FormGroup>
          <Input type="text" name="last_name"
            placeholder={type_document_id === 1 ? "Apellido del representante" : "Apellido"}
            onChange={e => setLastName(e.target.value.toLowerCase())} />
        </FormGroup>
        <FormGroup>
          <Input type="email" name="email" placeholder="Correo electrónico" required onChange={e => setEmail(e.target.value.toLowerCase())} />
        </FormGroup>
        <FormGroup>
          <Input type="text" placeholder="Celular" name="telefono" value={phone} required onChange={e => { setPhone(e.target.value) }} />
        </FormGroup>
        <FormGroup>
          <Input type="text" name="document_number" placeholder={type_document_id === 1 ? "NIT" : "Número de la Cédula"} required onChange={e => setDocumentNumber(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Input type="password" name="Contraseña" placeholder="Contraseña" required onChange={e => setPassword(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Input type="password" name="Contraseña_confirm" placeholder="Confirmar Contraseña" required onChange={e => setPassword_confirm(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Button className="btn-green"

            block onClick={submitHandle}>Registrarse</Button>
        </FormGroup>
      </Form>
    </div>
  );
}






