import { useReducer, useState, useEffect } from "react";

//create storage of global states in the application

const reducer = (state, action) => {
  switch (action.type) {
    case "DATA_QUERY_MODAL":
      return {
        ...state,
        dataQuery: action.dataQuery.data[0].data,
        document_number: action.dataQuery.data[0].document_number,
        name: action.dataQuery.data[0].name,
        last_name: action.dataQuery.data[0].last_name,
        company: action.company,
        id: action.dataQuery.data[0].id,
        date: action.dataQuery.data[0].date_created
          ? action.dataQuery.data[0].date_created
          : Date.now(),
      };
    case "SELECTED_ITEM_MENU":
      return {
        ...state,
        itemMenu: action.itemMenu,
      };
    default:
      return state;
  }
};

console.log(window.location.pathname);

const Store = () => {
  const [state, dispatch] = useReducer(reducer, {
    dataQuery: [],
    document_number: "",
    name: "",
    last_name: "",
    company: "",
    id: 0,
    date: "",
    itemMenu:
      window.location.pathname == "/"
        ? "summary"
        : window.location.pathname == "/resumen"
        ? "summary"
        : window.location.pathname == "/recargar"
        ? "reload"
        : window.location.pathname == "/consultar"
        ? "query"
        : window.location.pathname == "/consultar/la_ft"
        ? "query"
        : window.location.pathname == "/consultar/huella_digital"
        ? "query"
        : window.location.pathname == "/consultar/identidad"
        ? "query"
        : window.location.pathname == "/consultar/validacion_IP"
        ? "query"
        : window.location.pathname == "/consultar/validacion_BIN"
        ? "query"
        : window.location.pathname == "/consultar/score_de_riesgo"
        ? "query"
        : window.location.pathname == "/consultar/imagen_perfil"
        ? "query"
        : window.location.pathname == "/consultar/huella_digital_2"
        ? "query"
        : window.location.pathname == "/clientes"
        ? "client"
        : window.location.pathname == "/empleados"
        ? "users"
        : "",
  });
  return [state, dispatch];
};

export default Store;
