import React, { useState, useEffect } from 'react';
import { Badge, Col, Modal, ModalHeader, ModalBody, Row, Input } from 'reactstrap';
import { TitleCase } from '../managers/HelperManager';
import moment from 'moment';

const ModalIP = (props) => {


    return (
        <>
            {props.item.data.query != null && <Modal isOpen={props.modal} backdrop={'static'}>
                <ModalHeader toggle={props.hideModal} style={{ backgroundColor: "rgb(213,213,215)" }}>
                    {/* {`${props.item.name}`}julio alfredo bargas */}Validación IP
                    <br />
                    <span style={{ color: 'rgb(61,64,82)', fontSize: 15, marginLeft: 0 }}>
                        ID:{props.item.id}&nbsp;|{props.item.employee}&nbsp;|&nbsp;{moment(props.item.date_created).format('L h:mm:ss a')}
                        <br />
                    </span>

                    <span className="ml-4" style={{ color: 'rgb(61,64,82)', fontSize: 15 }}>

                    </span>

                    <a href="/download" ><i className="fas fa-file-download download-button"></i></a>
                </ModalHeader>
                <ModalBody className={"content-modalbody-query"} style={{ padding: 28 }}>
                    <Row className="content-row-modalquery">
                        <Col md={12}>
                            <h4 className='modalDocumentTitle'>{'Datos'}</h4>

                        </Col>


                        <Col md={12}>
                            <p className="text-titulo-modal-huella">IP</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.query}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">Pais</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.country}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">Region</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.regionName}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">Ciudad</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.city}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">Zona horaria</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.timezone}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">Organizacion</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.org}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">status</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.status}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">as</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.as}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">lat</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.lat}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">lon</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.lon}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">zip</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.data.zip}</b></p>
                        </Col>



                    </Row>
                </ModalBody>
            </Modal>
            }
            {props.item.data.query == null && <Modal isOpen={props.modal} backdrop={'static'}>
                <ModalHeader toggle={props.hideModal} style={{ backgroundColor: "rgb(213,213,215)" }}>
                    {/* {`${props.item.name}`}julio alfredo bargas */}Validación IP
                    <br />
                    <span style={{ color: 'rgb(61,64,82)', fontSize: 15, marginLeft: 0 }}>
                        ID:{props.item.id}&nbsp;|{props.item.employee}&nbsp;|&nbsp;{moment(props.item.date_created).format('L h:mm:ss a')}
                        <br />
                    </span>

                    <span className="ml-4" style={{ color: 'rgb(61,64,82)', fontSize: 15 }}>
                        {console.log("entro aqui null", props.item)}
                    </span>

                    <a href="/download" ><i className="fas fa-file-download download-button"></i></a>
                </ModalHeader>
                <ModalBody className={"content-modalbody-query"} style={{ padding: 28 }}>
                    <Row className="content-row-modalquery">
                        <Col md={12}>
                            <h4 className='modalDocumentTitle'>{'Datos'}</h4>

                        </Col>


                        <Col md={12}>
                            <p className="text-titulo-modal-huella">IP</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.query}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">Pais</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.country}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">Region</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.regionName}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">Ciudad</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.city}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">Zona horaria</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.timezone}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">Organizacion</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.org}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">status</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.status}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">as</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.as}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">lat</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.lat}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">lon</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.lon}</b></p>
                        </Col>
                        <Col md={12}>
                            <p className="text-titulo-modal-huella">zip</p>
                            <p className="text-content-modal" style={{ fontSize: 18 }}><b>{props.item.response.zip}</b></p>
                        </Col>



                    </Row>
                </ModalBody>
            </Modal>
            }
        </>

    )
}
const ModalIPLoading = props => {
    return (
        <>
            <Modal isOpen={props.modal} backdrop={'static'} className="modal-loading"  >
                <img style={{ margin: '0 auto' }} src="/assets/loadingIcon.gif" alt="img loading" width="50" height="50" />
            </Modal>
        </>
    )
}

export {
    ModalIPLoading,
    ModalIP
}