import { config } from '../config';
import { diff_minutes } from './HelperManager';

// URL for validu
// prod
const URL = "https://panel.validu.co";
// URL dev 
// const URL = "http://127.0.0.1:8000";
// dev
//  const URL = "http://192.168.1.16:8000";


export async function GetAuthenticatedHeaders(isAuthorization = false, contentType = { 'Content-Type': 'application/json' }) {
    let newHeaders = {
        Accept: 'application/json',
        ...contentType,
        'X-Api-Key': config.validu_api_key,
        'Authorization': "Bearer " + await GetAccessToken()
    }
    if (!isAuthorization) delete newHeaders.Authorization
    return newHeaders;
}

//function to get a token
async function GetAccessToken() {
    let currentTime = new Date(),
        token_created = Date.parse(localStorage.getItem("token_created")),
        minutes_since = diff_minutes(currentTime, new Date(token_created));
    if (minutes_since > 14) {
        return await RefreshAccessToken();
    } else {
        return localStorage.getItem("access_token");
    }
}

//function that controls the power to refresh the session token
async function RefreshAccessToken() {
    let response = await fetch(`${URL}/token/refresh/`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            refresh: localStorage.getItem("refresh_token")
        })
    });
    if (response.status === 200) {
        let responseJson = await response.json();
        localStorage.setItem("access_token", responseJson.access);
        localStorage.setItem("token_created", new Date());
        return responseJson.access
    }
    else {
        return false
    }
}
//   registro de usuarios o compañias 
const RegisterUserAsync = async (type_document_id, company_type, username, first_name, last_name, email, phone, document_number, password, password_confirmation, roles_id) => {

    console.log("login user async...");
    //we send the Register rquest.
    let response = await fetch(`${URL}/api/register/`, {
        method: 'POST',
        headers: await GetAuthenticatedHeaders(),
        body: JSON.stringify({ type_document_id, company_type, username, first_name, last_name, email, phone, document_number, password, password_confirmation, roles_id }),
        mode: 'cors'
    });


    return response.json();
}


//function that controls the login
const LoginAsync = async (username, password) => {
    try {
        // console.log("login user async...");
        //we send the login rquest.
        let response = await fetch(`${URL}/token/`, {
            method: 'POST',
            headers: await GetAuthenticatedHeaders(),
            body: JSON.stringify({ username, password }),
            mode: 'cors'
        });


        // console.log("Status for login token", response.status.toString());

        if (response.status.toString() === "200") {

            let responseJson = await response.json();

            console.log(responseJson, 'responseJson');

            if (responseJson.refresh) {
                localStorage.setItem("access_token", responseJson.access);
                localStorage.setItem("refresh_token", responseJson.refresh);
                localStorage.setItem("token_created", new Date());
                localStorage.setItem("loginOnce", "true");
                let responses = await GetProfile()
                if (responses.data[0].status === 1) {
                    return true;
                } else {
                    localStorage.clear();
                    return 2
                }
            }
        }
        return false;
    } catch (error) {
        console.error(error);
        return null
    }
}


const ChangeDocumentStatus = async data => {
    try {
        let response = await fetch(`${URL}/api/change_status_documents/`, {
            method: 'POST',
            headers: await GetAuthenticatedHeaders(true),
            body: JSON.stringify(data)
        });
        return await response.json();
    } catch (error) {
        console.error(error);
        return { "message": "ERROR_SERVER", "error": true }
    }
}
const fetchPipl = async (paramsKeyName, paramsname, paramsKeyCorreo, paramsCorreo, paramsKeyPhone, paramsPhone, paramsKeyCountry, parmascountry) => {

    try {
        console.log(`${URL}/api/retrieve_pipl/?` + `${paramsKeyName}${paramsname}` + `${paramsKeyCorreo}${paramsCorreo}` + `${paramsKeyPhone}${paramsPhone}` + `${paramsKeyCountry}${parmascountry}`)
        let response = await fetch(`${URL}/api/retrieve_pipl/?` + `${paramsKeyName}${paramsname}` + `${paramsKeyCorreo}${paramsCorreo}` + `${paramsKeyPhone}${paramsPhone}` + `${paramsKeyCountry}${parmascountry}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),


        });
        console.log('request data: ', response.data);
        return await response.json();

    } catch (error) {
        console.error(error);
        return { "message": "ERROR_SERVER", "error": true }
    }

}
const QueryFraude = async (email, ip, phone) => {
    const queryParams = [];

    try {
        if (ip !== "") {
            queryParams.push(`ip=${ip}`);
        }

        if (phone !== "") {
            queryParams.push(`phone=${phone}`);
        }

        if (email !== "") {
            queryParams.push(`email=${email}`);
        }

        // Unir los parámetros en una cadena de consulta con "&" si hay más de un parámetro
        const queryString = queryParams.join('&');

        let response = await fetch(`${URL}/api/fraud/?${queryString}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),


        });
        console.log('request data: ', response.data);
        return await response.json();

    } catch (error) {
        console.error(error);
        return { "message": "ERROR_SERVER", "error": true }
    }

}
const QueryIdentidad = async (paramsKeyName, paramsname, paramsKeyLastName, paramsLastName, paramsCC, documentType) => {

    try {
        console.log(`${URL}/api/id_records/${paramsCC}?` + `${paramsKeyName}${paramsname}` + `${paramsKeyLastName}${paramsLastName}` + `document_type=` + `cc`)
        let response = await fetch(`${URL}/api/id_records/${paramsCC}?` + `${paramsKeyName}${paramsname}` + `${paramsKeyLastName}${paramsLastName}` + `document_type=` + `${documentType == 0 ? "cc" : documentType == 1 ? "ce" : documentType == 2 ? "ti" : documentType == 3 ? "nit" : documentType == 4 ? "pa" : documentType == 5 ? "ppt" : ""}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),


        });
        console.log('request data: ', response.data);
        return await response.json();

    } catch (error) {
        console.error(error);
        return { "message": "ERROR_SERVER", "error": true }
    }

}

//function that controls the data load of the query
const QuerySarlaftAsync = async data => {
    try {
        // console.log("query user async...");
        //we send the login rquest.
        let response = await fetch(`${URL}/api/query_sarlaft/`, {
            method: 'POST',
            headers: await GetAuthenticatedHeaders(true),
            body: JSON.stringify(data)
        });
        return await response.json();
    } catch (error) {
        console.error(error);
        return { "message": "ERROR_SERVER", "error": true }
    }
}

// function  that controls the select type query
const GetTypeQuery = async () => {
    try {
        let response = await fetch(`${URL}/api/type_query/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        // console.log("Status for query  type", response.status.toString());
        if (response.status.toString() === "200") {
            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}


// function  that controls the balance
const GetBalance = async () => {
    try {
        let response = await fetch(`${URL}/api/balance/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}


// function  that controls the balance
const DownloadPDF = async id => {
    try {
        let response = await fetch(`${URL}/api/download_pdf/?log_query_id=${id}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.blob();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}



// function  that controls the data profile
const GetProfile = async () => {
    try {
        let response = await fetch(`${URL}/api/profile/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}


const CountQuery = async () => {
    try {
        let response = await fetch(`${URL}/api/consumption-count/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}
// function  that controls the logs query
const GetLogsQuery = async () => {
    try {
        let response = await fetch(`${URL}/api/logs_query/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}


// function  that controls the logs filter query
const GetFilterQuery = async id => {
    try {
        let response = await fetch(`${URL}/api/retrieve_single_logquery/?log_query_id=${id}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        return await response.json();
    } catch (error) {
        console.error(error);
        return { "message": "ERROR_SERVER", "error": true }
    }
}


// function  that controls the logs filter query
const GetListPaymentMethods = async () => {
    try {
        let response = await fetch(`${URL}/api/list_payment_methods/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}

// function  that controls the logs filter query
const GetListBanks = async () => {
    try {
        let response = await fetch(`${URL}/api/list_bank_cobru/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}


//function that controls the data load of the query
const ReloadAsync = async data => {
    try {
        let response = await fetch(`${URL}/api/reload/`, {
            method: 'POST',
            headers: await GetAuthenticatedHeaders(true),
            body: JSON.stringify(data)
        });
        return await response.json();
    } catch (error) {
        console.error(error);
        return { "message": "ERROR_SERVER", "error": true }
    }
}

//function that controls the data sumary component
const SummaryAsync = async data => {
    try {
        let response = await fetch(`${URL}/api/summary/`, {
            method: 'POST',
            headers: await GetAuthenticatedHeaders(true),
            body: JSON.stringify(data)
        });
        return await response.json();
    } catch (error) {
        console.error(error);
        return { "message": "ERROR_SERVER", "error": true }
    }
}

// function that controls get list type document
const GetListTypeDocument = async () => {
    try {
        let response = await fetch(`${URL}/api/type_document/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {

            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}

// function  that get list rols
const GetRols = async () => {
    try {
        let response = await fetch(`${URL}/api/roles/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}

// function  that get list rols
const GetListEmployees = async () => {
    try {
        let response = await fetch(`${URL}/api/list_employee/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),

        });
        if (response.status.toString() === "200") {

            return await response.json()
        }

        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}

// function  that get list rols
const GetFormProfileEmployee = async employee_id => {
    try {
        let response = await fetch(`${URL}/api/profile/?employee_id=${employee_id}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}


//function that controls the register and update profile 
const ProfileAsync = async (fields, method) => {
    try {

        let data = new FormData(), name;
        for (name in fields) {
            data.append(name, fields[name]);
        }

        let response = await fetch(`${URL}/api/profile/`, {
            method,
            headers: await GetAuthenticatedHeaders(true, {}),
            body: data
        });
        console.log(response)
        return await response.json();
    } catch (error) {
        console.error(error);

        return { "message": "ERROR_SERVER", "error": true }
    }
}

//function that controls the data sumary component
const ChangeStatusAsync = async data => {
    try {
        let response = await fetch(`${URL}/api/change_status/`, {
            method: 'POST',
            headers: await GetAuthenticatedHeaders(true),
            body: JSON.stringify(data)
        });
        return await response.json();
    } catch (error) {
        console.error(error);
        return { "message": "ERROR_SERVER", "error": true }
    }
}

// function  get list bussines constant
const GetBusinessConstant = async () => {
    try {
        let response = await fetch(`${URL}/api/business_constant/`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.json();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}



const GetClients = async (clientId) => {
    try {
        let response = await fetch(`${URL}/api/list_clients?client_id=${clientId}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        //console.log(response,'response');
        if (response.status === 200) {
            return await response.json();
        }

        console.log(response, 'response');

        return response;


    } catch (error) {
        console.error(error);
        return { "message": error, "error": true }
    }
}
const LogsClients = async (clientId) => {
    try {
        let response = await fetch(`${URL}/api/client_logs_query?client_id=${clientId}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });

        if (response.status === 200) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return { "message": error, "error": true }
    }
}
const getIP = async () => {

    try {
        const URL_API = "https://api.ipify.org/?format=json";

        let response = await fetch(URL_API, {
            method: 'GET',
        });


        return await response.json();
    } catch (error) {
        console.error(error);
        return { "message": error, "error": true }
    }
}
const QueryIP = async (IP) => {

    try {
        let response = await fetch(`${URL}/api/ip_validation/?ip=${IP}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });

        if (response.status === 200) {
            return await response.json();
        }

    } catch (error) {
        console.error(error);
        return { "message": error, "error": true }
    }


}
const QueryBIN = async (BIN) => {

    try {
        let response = await fetch(`${URL}/api/bin_validation/?bin=${BIN}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });

        if (response.status === 200) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return { "message": error, "error": true }
    }


}
const QueryScore = async (email) => {

    try {
        let response = await fetch(`${URL}/api/score_email/?email=${email}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });

        if (response.status === 200) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return { "message": error, "error": true }
    }


}
const QueryImage = async (email) => {

    try {
        let response = await fetch(`${URL}/api/image_profile/?email=${email}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });

        if (response.status === 200) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return { "message": error, "error": true }
    }


}
const QueryHuellaDigital2 = async (paramsKeyName, paramsname, paramsKeyCorreo, paramsCorreo, paramsKeyPhone, paramsPhone, paramsKeyCountry, parmascountry) => {
    try {
        let response = await fetch(`${URL}/api/huella_digital_2/?${paramsKeyName}${paramsname}` + `${paramsKeyCorreo}${paramsCorreo}` + `${paramsKeyPhone}${paramsPhone}` + `${paramsKeyCountry}${parmascountry}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });

        if (response.status === 200) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return { "message": error, "error": true }
    }


}
const DownloadPDFHuella = async id => {
    try {
        let response = await fetch(`${URL}/api/download_huella/?log_query_id=${id}`, {
            method: 'GET',
            headers: await GetAuthenticatedHeaders(true),
        });
        if (response.status.toString() === "200") {
            return await response.blob();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}

const pdf = async pag => {
    try {
        let response = await fetch(`https://api.sejda.com/v2/html-pdf`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: "Token: " + "api_80779CC8CDBB4B87957168920788E127",

            },
            body: {
                "htmlCode": `<strong>${pag}</strong>`
            }
        });
        if (response.status.toString() === "200") {
            console.log(response)
            return await response.blob();
        }
        return [];
    } catch (error) {
        console.error(error);
        return null
    }
}
const uploadSmsMassive = async (file) => {
    try {

        const formData = new FormData();
        formData.append('file', file)

        let response = await fetch(`${URL}/api/service_sms_massive/`, {
            method:'POST',
            headers: await GetAuthenticatedHeaders(true, {}),
            body: formData
        });
        return await response.json();
    } catch (error) {

        return { "message": "ERROR_SERVER", "error": true }
    }
}
export {
    LoginAsync,
    QuerySarlaftAsync,
    GetTypeQuery,
    GetBalance,
    GetProfile,
    GetLogsQuery,
    GetFilterQuery,
    DownloadPDF,
    GetListPaymentMethods,
    GetListBanks,
    ReloadAsync,
    SummaryAsync,
    GetListTypeDocument,
    GetRols,
    ProfileAsync,
    GetListEmployees,
    ChangeStatusAsync,
    GetFormProfileEmployee,
    GetBusinessConstant,
    RegisterUserAsync,
    GetClients,
    ChangeDocumentStatus,
    fetchPipl,
    QueryIdentidad,
    QueryFraude,
    LogsClients,
    getIP,
    QueryIP,
    QueryBIN,
    QueryScore,
    QueryImage,
    QueryHuellaDigital2,
    DownloadPDFHuella,
    pdf,
    CountQuery,
    uploadSmsMassive
}

