import React, { useState, useContext } from "react";
import { FunctionContext } from "../managers/FuntionContext";
import { Col, Row, Button, Form, FormGroup, Input } from "reactstrap";

import { fetchPipl } from "../managers/ApiManager";

import { validateEmail, AlertSwal } from "../managers/HelperManager";

import { useGlobal } from "../managers/store/Context";

import ReactFlagsSelect from "react-flags-select";
import swal from "sweetalert";
import { useHistory, Link, Route, Switch, Redirect } from "react-router-dom";

import { ModalHuellaLoading, ModalHuella } from "../consultas/ModalHuella";

export default function ConsultaHuella_digital(props) {
  const { funGetBalance } = useContext(FunctionContext);

  const [getLogsQuery, setLogsQuery] = useState([]);
  const [getBtnText, setBtnText] = useState("Consultar");
  const [, dispatch] = useGlobal();
  const [idTypeQuery, setIdTypeQuery] = useState("0");
  var history = useHistory();
  const [iCorreo, setCorreo] = useState("");
  const [selected, setSelected] = useState("CO");
  const searchable = ("Searchable", false);

  const [getModalHuellaLoading, setModalHuellaLoading] = useState(false);
  const [getModalHuella, setModalHuella] = useState(false);
  const [HuellaItem, setHuellaItem] = useState([]);
  const fetch = async (e) => {
    e.preventDefault();
    setBtnText("Consultando...");
    let { full_name, phone, correo, type_query_id } = e.target;
    const DataName = await full_name.value.toLowerCase();
    const dataPhone = await phone.value.toLowerCase();
    const dataCorreo = await correo.value.toLowerCase();

    verificarDatos(DataName, dataCorreo, dataPhone);
  };
  const verificarDatos = (DataName, dataCorreo, dataPhone) => {
    if (dataCorreo != "") {
      if (validateEmail(dataCorreo) === true) {
        setCorreo(dataCorreo);
      }
      if (validateEmail(dataCorreo) === false) {
        AlertSwal("CORREO_INVALIDO");
        setBtnText("Consultar");
      }
    }

    if (
      DataName == "" &&
      dataPhone == "" &&
      dataCorreo == "" &&
      selected == ""
    ) {
      AlertSwal("EMPTY_FIELD");
      setBtnText("Consultar");
    } else if (DataName !== "" || dataPhone !== "" || dataCorreo !== "") {
      if (dataCorreo != "") {
        if (validateEmail(dataCorreo) === true) {
          setCorreo(dataCorreo);
          fetchpipl(DataName, dataCorreo, dataPhone);
          setBtnText("Consultar");
        }
        if (validateEmail(dataCorreo) === false) {
          AlertSwal("CORREO_INVALIDO");
          setBtnText("Consultar");
        }
      } else {
        fetchpipl(DataName, dataCorreo, dataPhone);
        setBtnText("Consultar");
      }
    }
  };
  const fetchpipl = async (DataName, dataCorreo, dataPhone) => {
    try {
      setModalHuellaLoading(true);

      if (DataName != "") {
        var paramsKeyName = "full_name=";
        var paramsname = `${DataName}&`;
      } else {
        var paramsKeyName = "";
        var paramsname = "";
      }
      if (dataCorreo != "") {
        var paramsKeyCorreo = "email=";
        var paramsCorreo = `${dataCorreo}&`;
      } else {
        var paramsKeyCorreo = "";
        var paramsCorreo = "";
      }
      if (dataPhone != "") {
        var paramsKeyPhone = "phone=";
        var paramsPhone = `${dataPhone}&`;
      } else {
        var paramsKeyPhone = "";
        var paramsPhone = "";
      }
      var paramsKeyCountry = "country=";
      var parmascountry = `${selected}`;

      const response = await fetchPipl(
        paramsKeyName,
        paramsname,
        paramsKeyCorreo,
        paramsCorreo,
        paramsKeyPhone,
        paramsPhone,
        paramsKeyCountry,
        parmascountry
      );

      if (response.error === false && response.data.length > 0) {
        setHuellaItem(response.data);
        setModalHuellaLoading(false);
        setModalHuella(true);

        setBtnText("Consultar");
        funGetBalance();
        props.funLogsQuery();
      }
      if (response.error === false && response.data.length == 0) {
        setHuellaItem(response.data);
        setModalHuellaLoading(false);
        setModalHuella(true);

        setBtnText("Consultar");
        funGetBalance();
        props.funLogsQuery();
      } else if (response.message == "NOT_ENOUGH_BALANCE") {
        swal({
          title: "No tienes saldo disponible para realizar esta consulta",
          text: "¿Deseas Recargar? ",
          icon: "info",
          buttons: {
            cancel: "No",
            confirm: "Si",
          },
        }).then((willDelete) => {
          if (willDelete) {
            var itemMenu = "reload";
            dispatch({
              type: "SELECTED_ITEM_MENU",
              itemMenu,
            });
          } else {
          }
        });

        setModalHuellaLoading(false);
        setBtnText("Consultar");
      } else {
        setModalHuellaLoading(false);
        //AlertSwal('Verificar campos')
        setBtnText("Consultar");
      }
    } catch (error) {
      setModalHuellaLoading(false);
      //AlertSwal('ERROR_SERVER')

      setBtnText("Consultar");
    }
    setModalHuellaLoading(false);
    setBtnText("Consultar");
  };

  const tipoConsulta = async (x) => {
    if (x == 3) {
      var sumaHuellaLogs = 0;
      getLogsQuery.map((item, i) => {
        if (item.type_query == "Huella digital") {
          sumaHuellaLogs++;
        }
      });

      if (sumaHuellaLogs == 0) {
        AlertSwal("MENSAJE_SELECT_HUELLA");
      }
    }
    if (x == 1) {
      history.push("/consultar/la_ft");
    } else if (x == "3") {
      history.push("/consultar/huella_digital");
    } else if (x == "4") {
      history.push("/consultar/identidad");
    } else if (x == "5") {
      history.push("/consultar/validacion_IP");
    } else if (x == "6") {
      history.push("/consultar/validacion_BIN");
    } else if (x == "7") {
      history.push("/consultar/score_de_riesgo");
    } else if (x == "8") {
      history.push("/consultar/imagen_perfil");
    } else if (x == "9") {
      history.push("/consultar/informacion_laboral");
    } else if (x == "10") {
      history.push("/consultar/fraude");
    }

    setIdTypeQuery(x);
  };
  const customLabels =
    ("Custom Labels",
    {
      GB: "GB",
      US: "US",
    });

  return (
    <div className="content-jumbotron  basket animated fadeIn mt-2 mb-2">
      <Form onSubmit={(e) => fetch(e)}>
        <Row
          form
          style={{
            alignitems: "center",
          }}
        >
          <Col md={2}>
            <FormGroup>
              <Input
                type="select"
                name="type_query_id"
                defaultValue="3"
                onChange={(e) => tipoConsulta(e.target.value)}
                required
              >
                <option hidden disabled={true} value="">
                  Tipo de consulta
                </option>
                <option value="1"> LA / FT </option>
                {/*   <option value="3"> Huella digital </option> */}
                <option value="4"> Identidad </option>
                <option value="5"> Validación IP </option>
                <option value="6"> Validación BIN </option>
                <option value="7"> Score de riesgo </option>
                <option value="8"> Imagen perfil </option>
                <option value="9"> Informacion laboral </option>
                <option value="10"> Fraude </option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input
                type="text"
                name="full_name"
                placeholder="Nombre Completo"
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input
                type="text"
                name="phone"
                placeholder="Número De Celular "
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input
                type="email"
                name="correo"
                placeholder="Correo Electrónico"
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup className="imput-huella-pais">
              <ReactFlagsSelect
                placeholder="Seleccionar Pais"
                className="imput-pais"
                selected={selected}
                onSelect={(e) => setSelected(e)}
                customLabels={customLabels}
                searchable={searchable}
                defaultValue="co"
              />
            </FormGroup>
          </Col>
          <Col
            md={2}
            style={{
              marginleft: 10,
            }}
          >
            <FormGroup>
              <Button
                className="btn-green"
                disabled={getBtnText === "Consultando..."}
                block
              >
                {getBtnText}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      {HuellaItem != null && (
        <div className="d-flex" id="wrapper">
          <ModalHuella
            item={HuellaItem}
            modal={getModalHuella}
            hideModal={() => setModalHuella(false)}
          />
        </div>
      )}
      <ModalHuellaLoading modal={getModalHuellaLoading} />
    </div>
  );
}
