import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { GetFilterQuery } from "../managers/ApiManager";
import moment, { relativeTimeRounding } from 'moment';



const styles = StyleSheet.create({
    page: {
        padding: "25px"

    },
    section: {
        backgroundColor: "#d5d5d7",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        margin: "10px 20px 10px 20px",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",



    },
    header: {
        paddingLeft: "15px",
        display: "flex",
        width: "100%",
        marginBottom: "10px"
    },
    data: {
        paddingLeft: "15px",
        flexDirection: 'row',
        width: "100%"
    },
    textTitule: {
        fontSize: "12px",
        fontWeight: "medium"
    },
    BodyView: {
        margin: "0px 20px 00px 20px",
        paddingLeft: "15px",


    },
    linia: {
        borderBottom: "2px solid gray"
    },
    liniaDivisoria: {
        borderBottom: "1px solid gray",
        marginBottom: "15px",

    },
    tituloPrincipal: {
        marginTop: "5px",
        fontSize: "18px",

    },
    tituloSecundario: {
        fontSize: "16px",
        marginBottom: "5px",
        marginTop: "10px",
        color: "black"


    },
    titulo1: {
        color: "#777",
        fontSize: "11px",

        marginTop: "5px",


    },
    Text1: {
        fontSize: "14px",
        marginBottom: "5px",


    },
    CentrarText: {

        marginTop: "30%",
        marginLeft: "-25px",
        display: "flex",
        alignContent: "center",
        alignItems: "center"
    }

});

const HuellaPDF = (props) => {
    console.log("aqui esta pros:  ", props)
    const [data, setData] = useState(null);
    useEffect(() => {
        if (props.show) {
            render();
        }



    }, [props.show]);


    const render = async () => {
        let res = await GetFilterQuery(props.id)
        setData(res)
        console.log(res)

    }


    return props.show && data !== null ? (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.header}>
                        <Text >Huella Digital</Text>

                    </View>
                    <View style={styles.data}>
                        <Text style={styles.textTitule}>ID: </Text>
                        <Text style={styles.textTitule}>{data.data[0].id} </Text>
                        <Text style={styles.textTitule}> | </Text>
                        <Text style={styles.textTitule}>{data.data[0].employee} </Text>

                        <Text style={styles.textTitule}> | </Text>
                        <Text style={styles.textTitule}>{moment(data.data[0].date_created).format(('L h:mm:ss a'))}</Text>
                    </View>

                </View>
                {data.data[0].response.length > 0 &&
                    data.data[0].response.map((item, i) => {
                        return (
                            <View style={styles.BodyView}>
                                {/* {console.log(data.data[0].response)} */}
                                <Text style={styles.tituloPrincipal}>{data.data[0].response.length === 1 ? "Huella digital" : `Huella digital ${i + 1}`}</Text>
                                <View >
                                    <View style={styles.liniaDivisoria}>
                                        <Text style={styles.tituloSecundario}>Datos</Text>
                                        {item.persons[0].names[0] != undefined && <View>
                                            < Text style={styles.titulo1}> Nombre Completo</Text>

                                            <Text style={styles.Text1}>{item.persons[0].names[0].display}</Text>
                                        </View>
                                        }
                                        {item.persons[0].usernames[0] !== undefined &&
                                            item.persons[0].usernames.map((usernames, i) => {
                                                return (
                                                    <View>
                                                        <Text style={styles.titulo1}>{item.persons[0].usernames.length === 1 ? "Username" : `Username ${i + 1}`}</Text>
                                                        <Text style={styles.Text1}>{usernames.content}</Text>
                                                    </View>
                                                )
                                            })
                                        }
                                        {item.persons[0].phones[0] !== undefined &&
                                            item.persons[0].phones.map((phones, i) => {
                                                return (
                                                    <View>

                                                        <Text style={styles.titulo1}>{item.persons[0].phones.length === 1 ? "Número De Celular" : `Número De Celular ${i + 1}`}</Text>
                                                        <Text style={styles.Text1}>{phones.display_international}</Text>
                                                    </View>
                                                )
                                            })
                                        }
                                        {item.persons[0].languages[0] !== undefined &&
                                            item.persons[0].languages.map((languages, i) => {
                                                return (
                                                    <View>

                                                        <Text style={styles.titulo1}>{item.persons[0].languages.length === 1 ? "Idioma" : `Idioma ${i + 1}`}</Text>
                                                        <Text style={styles.Text1}>{languages.language}</Text>
                                                    </View>
                                                )
                                            })
                                        }
                                        {item.persons[0].gender[0] !== undefined &&
                                            <View>
                                                <Text style={styles.titulo1}>Género</Text>
                                                <Text style={styles.Text1}>{item.persons[0].gender[0].content}</Text>
                                            </View>
                                        }
                                    </View>


                                    {item.persons[0].addresses[0] !== undefined &&
                                        item.persons[0].addresses.map((addresses, i) => {
                                            return (
                                                <View style={styles.liniaDivisoria}>

                                                    <Text style={styles.tituloSecundario}>{item.persons[0].addresses.length === 1 ? "Ubicacion" : `Ubicacion ${i + 1}`}</Text>
                                                    {addresses.country !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Pais</Text>
                                                            <Text style={styles.Text1}>{addresses.country}</Text>
                                                        </View>
                                                    }
                                                    {addresses.display !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Dirección</Text>
                                                            <Text style={styles.Text1}>{addresses.display}</Text>
                                                        </View>

                                                    }

                                                </View>
                                            )
                                        })
                                    }



                                    {item.persons[0].educations[0] !== undefined &&
                                        item.persons[0].educations.map((educations, i) => {
                                            return (
                                                <View style={styles.liniaDivisoria}>

                                                    <Text style={styles.tituloSecundario}>{item.persons[0].educations.length === 1 ? "Educación" : `Educación ${i + 1}`}</Text>
                                                    {educations.school !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Universidad</Text>
                                                            <Text style={styles.Text1}>{educations.school}</Text>
                                                        </View>
                                                    }
                                                    {educations.degree !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Carrera</Text>
                                                            <Text style={styles.Text1}>{educations.degree}</Text>
                                                        </View>
                                                    }
                                                    {educations.date_range !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Inicio La Carrera</Text>
                                                            <Text style={styles.Text1}>{educations.date_range.start}</Text>
                                                        </View>
                                                    }
                                                    {educations.date_range !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Termino La Carrera</Text>
                                                            <Text style={styles.Text1}>{educations.date_range.end !== undefined ? educations.date_range.end : "Indefinido"}</Text>
                                                        </View>
                                                    }

                                                </View>
                                            )
                                        })
                                    }


                                    {item.persons[0].jobs[0] !== undefined &&
                                        item.persons[0].jobs.map((jobs, i) => {
                                            return (
                                                <View style={styles.liniaDivisoria}>

                                                    <Text style={styles.tituloSecundario}>{item.persons[0].jobs.length === 1 ? "Educación" : `Educación ${i + 1}`}</Text>
                                                    {jobs.display !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Posicion</Text>
                                                            <Text style={styles.Text1}>{jobs.display}</Text>
                                                        </View>
                                                    }
                                                    {jobs.organization !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Organizacion</Text>
                                                            <Text style={styles.Text1}>{jobs.organization}</Text>
                                                        </View>
                                                    }
                                                    {jobs.title !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>titulo</Text>
                                                            <Text style={styles.Text1}>{jobs.title}</Text>
                                                        </View>
                                                    }
                                                    {jobs.date_range !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Empezo</Text>
                                                            <Text style={styles.Text1}>{jobs.date_range.start}</Text>
                                                        </View>
                                                    }
                                                    {jobs.date_range !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Termino </Text>
                                                            <Text style={styles.Text1}>{jobs.date_range.end !== undefined ? jobs.date_range.end : "Indefinido"}</Text>
                                                        </View>
                                                    }

                                                </View>
                                            )
                                        })
                                    }
                                    {item.persons[0].urls[0] !== undefined &&
                                        item.persons[0].urls.map((urls, i) => {
                                            return (
                                                <View style={styles.liniaDivisoria}>

                                                    <Text style={styles.tituloSecundario}>{item.persons[0].urls.length === 1 ? "Redes" : `Redes ${i + 1}`}</Text>
                                                    {urls.name !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Red Social</Text>
                                                            <Text style={styles.Text1}>{urls.name}</Text>
                                                        </View>
                                                    }
                                                    {urls.domain !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Dominio</Text>
                                                            <Text style={styles.Text1}>{urls.domain}</Text>
                                                        </View>
                                                    }
                                                    {urls.url !== undefined &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Link</Text>
                                                            <Text style={styles.Text1}>{urls.url}</Text>
                                                        </View>
                                                    }


                                                </View>
                                            )
                                        })
                                    }
                                    {item.persons[0].relationships[0] !== undefined &&
                                        item.persons[0].relationships.map((relationships, i) => {
                                            return (
                                                <View style={styles.liniaDivisoria} >

                                                    <Text style={styles.tituloSecundario}>{item.persons[0].relationships.length === 1 ? "Relaciones" : `Relaciones ${i + 1}`}</Text>
                                                    {relationships.display != "" &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Nombre Completo</Text>
                                                            <Text style={styles.Text1}>{relationships.names[0].display}</Text>
                                                        </View>
                                                    }
                                                    {console.log(relationships.phones)}
                                                    {relationships.phones != "" &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Número De Celular</Text>
                                                            <Text style={styles.Text1}>{relationships.phones.number}</Text>
                                                        </View>
                                                    }
                                                    {relationships.emails != "" &&
                                                        <View>
                                                            <Text style={styles.titulo1}>Correo</Text>
                                                            <Text style={styles.Text1}>{relationships.emails.address}</Text>
                                                        </View>
                                                    }


                                                </View>
                                            )
                                        })
                                    }
                                </View>
                            </View>
                        )
                    })
                }
                {data.data[0].response.length == 0 &&
                    <View style={styles.CentrarText}><Text>No se encontraron datos</Text>
                    </View>}
            </Page>
        </Document >
    ) : ("")
}

export { HuellaPDF }


