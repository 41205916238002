import React, { useState, useEffect } from "react";
import {
  Badge,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Input,
} from "reactstrap";
import { TitleCase } from "../managers/HelperManager";

const ModalIdentidad = (props) => {
  const [antecedentes, setAntecedentes] = useState(null);
  const [cc, setCC] = useState();
  const [number, setNumber] = useState(props.item.cc);

  useEffect(() => {
    Antecedentes();
    ConvertirCC();
  }, [props.item]);

  const ConvertirCC = () => {
    var convertirletra = parseInt(number);
    var converitdo = convertirletra.toLocaleString();
    setCC(converitdo.replace(/,/g, "."));
  };

  const Antecedentes = () => {
    if (props.item.records !== undefined) {
      setAntecedentes(props.item.records);
      console.log(antecedentes);
      // "1,234,567,890"
    }
    if (props.modal == false) {
      setAntecedentes(null);
    }
  };

  return (
    <>
      <Modal isOpen={props.modal} backdrop={"static"}>
        <ModalHeader
          toggle={props.hideModal}
          style={{ backgroundColor: "rgb(213,213,215)" }}
        >
          {/* {`${props.item.name}`}julio alfredo bargas */}Identidad
          <br />
          <span style={{ color: "rgb(61,64,82)", fontSize: 15, marginLeft: 0 }}>
            CC: {cc}&nbsp;|&nbsp;{TitleCase(props.item.name)}&nbsp;
            <br />
          </span>
          <span
            className="ml-4"
            style={{ color: "rgb(61,64,82)", fontSize: 15 }}
          ></span>
          <a href="/download">
            <i className="fas fa-file-download download-button"></i>
          </a>
        </ModalHeader>
        <ModalBody
          className={"content-modalbody-query"}
          style={{ padding: 28 }}
        >
          {props.items != "" && (
            <div>
              <Row className="content-row-modalquery">
                <Row
                  className="content-row-modalquery"
                  style={{ width: "100%" }}
                >
                  {props.item.name != "" && (
                    <Col md={12}>
                      <p className="title-text-modal">Nombre Completo</p>
                      <p className="text-content-modal">
                        <b>{(props.item.name)}</b>
                      </p>
                    </Col>
                  )}
                  <Col md={12}>
                    <p className="title-text-modal">Documento</p>
                    <p className="text-content-modal">
                      <b>{cc}</b>
                    </p>
                  </Col>
                  {props.item.status == "expired" && (
                    <Col md={12}>
                      <p className="title-text-modal">Estatus de la persona</p>
                      <p className="text-content-modal">
                        <b>Documento vencido</b>
                      </p>
                    </Col>
                  )}
                  {props.item.status == "records" && (
                    <Col md={12}>
                      <p className="title-text-modal">Estatus de la persona</p>
                      <p className="text-content-modal">
                        <b>Con Antecedentes</b>
                      </p>
                    </Col>
                  )}
                  {props.item.status == "no_records" && (
                    <Col md={12}>
                      <p className="title-text-modal">Estatus de la persona</p>
                      <p className="text-content-modal">
                        <b>Sin Antecedentes</b>
                      </p>
                    </Col>
                  )}

                  {props.item.message !== "" && (
                    <Col md={12}>
                      <p className="title-text-modal">Mensaje</p>
                      <p
                        className="text-content-modal"
                        style={{ whiteSpace: "normal" }}
                      >
                        <b>{TitleCase(props.item.message)} </b>
                      </p>
                    </Col>
                  )}

                  {antecedentes !== null && (
                    <div>
                      <Row
                        className="content-row-modalquery"
                        style={{ paddingLeft: 15, paddingRight: 15 }}
                      >
                        <Col md={6}>
                          <hr
                            className="hr-modal-query"
                            style={{ width: "1000%" }}
                          />
                          <p className="titulo-modal" style={{ width: "100" }}>
                            <b>Antesedentes</b>
                          </p>
                          {antecedentes.INHABILIDADES != "" &&
                            antecedentes.INHABILIDADES.map((item, i) => {
                              return (
                                <div style={{ width: "100%" }}>
                                  <Row className="content-row-modalquery">
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        Inhabilidataciones
                                      </p>
                                      <p className="text-content-modal">
                                        <b>
                                          {TitleCase(item["Inhabilidad legal"])}
                                        </b>
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        módulo
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{TitleCase(item["Módulo"])}</b>
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        desde
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{item["Fecha de inicio"]}</b>
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        hasta
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{item["Fecha fin"]}</b>
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        SIRI
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{item["SIRI"]}</b>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          {antecedentes.Sanciones != "" &&
                            antecedentes.Sanciones.map((item, i) => {
                              return (
                                <div style={{ width: "100%" }}>
                                  <Col md={6}>
                                    <hr
                                      className="hr-modal-query"
                                      style={{
                                        paddingLeft: -15,
                                        width: "500%",
                                        paddingRight: 15,
                                      }}
                                    />
                                  </Col>
                                  <Row className="content-row-modalquery">
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        inabilidataciones
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{TitleCase(item["Suspendida"])}</b>
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        Clase
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{TitleCase(item["Clase"])}</b>
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        sancion
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{TitleCase(item["Sanción"])}</b>
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        Termino
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{TitleCase(item["Término"])}</b>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          {antecedentes.Instancias != "" &&
                            antecedentes.Instancias.map((item, i) => {
                              return (
                                <div style={{ width: "100%" }}>
                                  <Col md={6}>
                                    <hr
                                      className="hr-modal-query"
                                      style={{
                                        paddingLeft: -15,
                                        width: "500%",
                                        paddingRight: 15,
                                      }}
                                    />
                                  </Col>
                                  <Row className="content-row-modalquery">
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        Autoridad
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{TitleCase(item["Autoridad"])}</b>
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        Nombre
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{TitleCase(item["Nombre"])}</b>
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        fecha efecto Juridicos
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{item["fecha efecto Juridicos"]}</b>
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        Fecha providencia
                                      </p>
                                      <p className="text-content-modal">
                                        <b>{item["Fecha providencia"]}</b>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          {antecedentes.Delitos != "" &&
                            antecedentes.Delitos.map((item, i) => {
                              return (
                                <div style={{ width: "100%" }}>
                                  <hr
                                    className="hr-modal-query"
                                    style={{
                                      paddingLeft: -15,
                                      width: "500%",
                                      paddingRight: 15,
                                    }}
                                  />
                                  <Col sytle={{ paddingLeft: 0 }} md={6}></Col>
                                  <Row className="content-row-modalquery">
                                    <Col md={12}>
                                      <p className="text-titulo-modal-huella">
                                        Descripción del Delito
                                      </p>
                                      <p className="text-content-modal">
                                        <b>
                                          {TitleCase(
                                            item["Descripción del Delito"]
                                          )}
                                        </b>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                        </Col>
                      </Row>
                    </div>
                  )}
                </Row>
              </Row>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
const ModalIdentidadLoading = (props) => {
  return (
    <>
      <Modal isOpen={props.modal} backdrop={"static"} className="modal-loading">
        <img
          style={{ margin: "0 auto" }}
          src="/assets/loadingIcon.gif"
          alt="img loading"
          width="50"
          height="50"
        />
      </Modal>
    </>
  );
};

export { ModalIdentidadLoading, ModalIdentidad };
