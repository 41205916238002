import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "daterangepicker/daterangepicker.css";
import "moment/locale/es";
import App from "./pages/App";
import Home from "./pages/Home";
import Registro from "./pages/Register";
import * as serviceWorker from "./serviceWorker";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { RootProvider } from "./managers/store/Context";
import FunctionContext from "./managers/FuntionContext";
//imports fonts
import "./fonts/AirbnbCerealBlack.woff";
import "./fonts/AirbnbCerealBold.woff";
import "./fonts/AirbnbCerealBook.woff";
import "./fonts/AirbnbCerealExtraBold.woff";
import "./fonts/AirbnbCerealLight.woff";
import "./fonts/AirbnbCerealMedium.woff";

/*function to authenticate users*/
const Authenticated = (type, session = localStorage.getItem("loginOnce")) => {
  return type === "public" && session !== null ? (
    <Redirect to={"/Resumen"} />
  ) : type === "private" && session === null ? (
    <Redirect to={"/"} />
  ) : type === "compare" ? (
    session
  ) : null;
};


/*function logout app */
const Logout = () => {
  localStorage.clear();
  window.location.href = "/";
};

/*function render components app */
const RouteProvider = ({ component: Component, ...objectPath }) => (
  <Route
    {...objectPath}
    render={(props) => (
      <Component {...props} fakeAuth={Authenticated} logout={Logout} />
    )}
  />
);

// Config routes app
ReactDOM.render(
  <Router>
    <FunctionContext.Provider>
      <>
        <Switch>
          <RootProvider>
            {localStorage.loginOnce == undefined && (
              <Switch>
                <RouteProvider exact path="/" component={App} />
                <RouteProvider path="/registro" component={Registro} />
                <Route path="*" render={() => <Redirect to="/" />} />
              </Switch>
            )}

            {localStorage.loginOnce && (
              <RouteProvider component={Home} path="/:path?" />
            )}
          </RootProvider>
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </>
    </FunctionContext.Provider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
