import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { GetFilterQuery } from "../managers/ApiManager";
import moment, { relativeTimeRounding } from 'moment';
import { TitleCase } from "../managers/HelperManager";


const styles = StyleSheet.create({
    page: {
        padding: "25px"

    },
    section: {
        backgroundColor: "#d5d5d7",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        margin: "10px 20px 10px 20px",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",



    },
    header: {
        paddingLeft: "15px",
        display: "flex",
        width: "100%",
        marginBottom: "10px"
    },
    data: {
        paddingLeft: "15px",
        flexDirection: 'row',
        width: "100%"
    },
    textTitule: {
        fontSize: "12px",
        fontWeight: "medium"
    },
    BodyView: {
        margin: "0px 20px 00px 20px",
        paddingLeft: "15px",


    },
    linia: {
        borderBottom: "2px solid gray"
    },
    liniaDivisoria: {
        borderBottom: "1px solid gray",
        marginBottom: "15px",

    },
    tituloPrincipal: {
        marginTop: "5px",
        fontSize: "18px",

    },
    tituloSecundario: {
        fontSize: "16px",
        marginBottom: "5px",
        marginTop: "10px",
        color: "black"


    },
    titulo1: {
        color: "#777",
        fontSize: "11px",

        marginTop: "5px",


    },
    Text1: {
        fontSize: "14px",
        marginBottom: "5px",


    },
    CentrarText: {

        marginTop: "30%",
        marginLeft: "-25px",
        display: "flex",
        alignContent: "center",
        alignItems: "center"
    },
    link1: {
        color: "#007bff",
        textDecoration: "underline",
        textTransform: "lowercase",
        fontSize: "14px",
        marginBottom: "5px",
    }


});


const BinPDF = (props) => {
    console.log("aqui esta pros:  ", props)
    const [data, setData] = useState(null);
    useEffect(() => {
        if (props.show) {
            render();
        }



    }, [props.show]);


    const render = async () => {
        let res = await GetFilterQuery(props.id)
        setData(res)
        console.log(res)

    }



    return props.show && data !== null ? (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.header}>
                        <Text >Validación BIN</Text>

                    </View>
                    <View style={styles.data}>
                        <Text style={styles.textTitule}>ID: </Text>
                        <Text style={styles.textTitule}>{data.data[0].id} </Text>
                        <Text style={styles.textTitule}> | </Text>
                        <Text style={styles.textTitule}>{data.data[0].employee} </Text>

                        <Text style={styles.textTitule}> | </Text>
                        <Text style={styles.textTitule}>{moment(data.data[0].date_created).format(('L h:mm:ss a'))}</Text>
                    </View>

                </View>
                {data.data[0].response !== undefined &&
                    <View style={styles.BodyView}>
                        <Text style={styles.tituloPrincipal}>Datos</Text>

                        {data.data[0].response.bin !== "" && <View>
                            <Text style={styles.titulo1}>BIN</Text>
                            <Text style={styles.Text1}>{TitleCase(data.data[0].response.bin)}</Text>
                        </View>
                        }
                        {data.data[0].response.bank !== "" && <View>
                            <Text style={styles.titulo1}>Banco</Text>
                            <Text style={styles.Text1}>{TitleCase(data.data[0].response.bank)}</Text>
                        </View>
                        }
                        {data.data[0].response.card !== "" && <View>
                            <Text style={styles.titulo1}>Card</Text>
                            <Text style={styles.Text1}>{TitleCase(data.data[0].response.card)}</Text>
                        </View>
                        }
                        {data.data[0].response.type !== "" && <View>
                            <Text style={styles.titulo1}>Tipo</Text>
                            <Text style={styles.Text1}>{TitleCase(data.data[0].response.type)}</Text>
                        </View>
                        }
                        {data.data[0].response.level !== "" && <View>
                            <Text style={styles.titulo1}>Nivel</Text>
                            <Text style={styles.Text1}>{TitleCase(data.data[0].response.level)}</Text>
                        </View>
                        }
                        {data.data[0].response.countrycode !== "" && <View>
                            <Text style={styles.titulo1}>Pais</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.countrycode)}</Text>
                        </View>
                        }
                        {data.data[0].response.website !== "" && <View>
                            <Text style={styles.titulo1}>Website</Text>
                            <Text style={styles.link1}>{(data.data[0].response.website)}</Text>
                        </View>
                        }
                        {data.data[0].response.phone !== "" && <View>
                            <Text style={styles.titulo1}>Phone</Text>
                            <Text style={styles.Text1}>{(data.data[0].response.phone)}</Text>
                        </View>
                        }



                    </View>}
                {data.data[0].response.length == 0 &&
                    <View style={styles.CentrarText}><Text>No se encontraron datos</Text>
                    </View>}
            </Page>
        </Document >
    ) : ("")
}

export { BinPDF }


