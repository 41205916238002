import React, { useState } from 'react'
import NavComponent from './Nav'
import DropzoneComponent from './DropzoneComponent'
import { AlertSwal } from '../managers/HelperManager'
import { uploadSmsMassive } from '../managers/ApiManager'
import swal from 'sweetalert'
//send_massive_sms
const MassiveSms = (props) => {
    const [file, setFile] = useState(null)

    const handleSubmitFile = async (acceptedFiles) => {

        let resUpload = await uploadSmsMassive(acceptedFiles);
        /*
        {message: 'Problema con el sistema.', code_transaction: 'UNEXPECTED_ERROR'} 

        {
        "data": [
          {
              "data": "OK",
              "id": 20904,
              "count_failed": 2,
              "employee": "yo@orla.games",
              "date_created": "2024-04-03T16:11:07.811Z",
              "count_sucess": 0
          }
    ],
    "error": false
}
         */
            
        if (resUpload?.code_transaction) {
          swal({ title:resUpload?.code_transaction, text:resUpload.message, icon:'error' });
        }else if(!resUpload.error){
          swal({ title:'Listo', text:`Enviados: ${resUpload?.data[0]?.count_sucess}\nFallidos: ${resUpload?.data[0]?.count_failed}`, icon:'success' })
        }else{
          swal({ title:'Ups!', text:'Ha ocurrido un error inesperado en la aplicación. Por favor intente nuevamente', icon:'error' })

        }


 
    }
  return (
    <div>
        <NavComponent title={`Envio SMS`} name={props.resProfile.name} photo={props.resProfile.photo} />
        <DropzoneComponent myFile={file} setMyFile={setFile} handleSubmitReq={handleSubmitFile}/>
    </div>
  )
}

export default MassiveSms